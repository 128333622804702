import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoopBackAuth } from '../../api/services/core/index';
import { AccountApi } from '../../api/services/custom/index';
import { AccessToken, Account } from '../../api/models/index';
import { NotifierService } from 'angular-notifier';
import { TranslateService } from '@ngx-translate/core';
import 'rxjs-compat/add/operator/mergeMap';
import { Roles } from '../../comon/constants';
import { NgxSpinnerService } from 'ngx-spinner';

declare const $;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {
  account = new Account();
  rememberMe = true;
  formValidation: any;

  constructor(private accountApi: AccountApi,
              private auth: LoopBackAuth,
              private router: Router,
              private notifierService: NotifierService,
              private translateService: TranslateService,
              private spinner: NgxSpinnerService) {
    if (accountApi.getCurrentId()) {
      this.router.navigate(['/main']);
    }
  }

  ngOnInit(): void {
    this.formValidation = $('#login-form').parsley();
  }

  login() {
    if (!this.formValidation.isValid()) {
      return;
    }
    this.spinner.show().then().catch();
    this.accountApi
      .login(this.account, this.rememberMe)
      .mergeMap((accessToken: AccessToken) => {
        return this.accountApi.findById(accessToken.userId, {
          include: [
            'roles', 'voucher'
          ]
        });
      })
      .subscribe((account: Account) => {
        this.spinner.hide().then().catch();
        if (account.roles.length > 0) {
          this.auth.setUser(account);
          if (account.roles[0].name === 'SUPERADMIN') {
            this.router.navigate(['/main/user']);
          } else {
            this.router.navigate(['/main/']);
          }
          this.notifierService.notify('success', 'Login success');
        } else {
          this.notifierService.notify('warning', 'You do not have permission');
        }
      }, error => {
        this.spinner.hide().then().catch();
        this.notifierService.notify('error', error.message);
      });
  }
}
