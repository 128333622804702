import { Component, OnInit } from '@angular/core';
import { Account, Cart, City, County, Order } from '../../../api/models';
import * as moment from 'moment';
import { NotifierService } from 'angular-notifier';
import { AccountApi, CartApi, OrderApi } from '../../../api/services/custom';
import { ActivatedRoute } from '@angular/router';
import { LoopBackAuth } from '../../../api/services/core';

@Component({
  selector: 'app-order-partner',
  templateUrl: './order-partner.component.html',
  styleUrls: ['./order-partner.component.css']
})
export class OrderPartnerComponent implements OnInit {
  isLoading = false;
  assign = Object.assign;
  formValidation: any;
  currentCart = new Cart();
  accounts: Account[] = [];
  userIdFilter: number;
  filterMonth = null;
  filterYear = moment().year();
  userLogin = new Account();
  filterOrderId = null;
  carts: Cart[] = [];
  filterVoucherId = null;
  totalCommission = 0;

  // moment().set({ 'date': 1, 'hour': 0, 'minute': 0, 'second': 0 });

  constructor(private notifierService: NotifierService,
              private orderApi: OrderApi,
              private cartApi: CartApi,
              private accountApi: AccountApi,
              private auth: LoopBackAuth) {
  }

  ngOnInit() {
    this.userLogin = this.auth.getCurrentUserData();
    if (this.userLogin && this.userLogin.voucher) {
      this.filterVoucherId = this.userLogin.voucher.id;
      if (this.filterVoucherId) {
        this.findCart();
      }
    }
  }

  getRole() {
    return this.userLogin.roles[0].name === 'PARTNER';
  }

  getFilter() {
    if (this.filterMonth !== moment().month() && this.filterMonth !== null && this.filterMonth !== 'null') {
      const minDate = moment().set({ 'year': this.filterYear, 'month': this.filterMonth, 'date': 1, 'hour': 0, 'minute': 0, 'second': 0 });
      const maxDate = moment(minDate).add('1', 'months');
      return {
        include: [
          {
            relation: 'order',
            scope: {
              include: ['account', 'city']
            }
          }
        ],
        where: {
          updatedAt: { between: [minDate, maxDate] },
          voucherId: this.filterVoucherId
        },
        order: 'updatedAt DESC'
      };
    } else {
      return {
        include: [
          {
            relation: 'order',
            scope: {
              include: ['account', 'city']
            }
          }
        ],
        where: {
          voucherId: this.filterVoucherId
        },
        order: 'updatedAt DESC'
      };
    }
  }

  getPrice(cart: Cart) {
    if (cart && cart.order && cart.order.account && cart.order.account.email) {
      return cart.itemPrice * (1 - cart.itemDiscount / 100);
    } else {
      return cart.itemPrice;
    }
  }

  findCart() {
    const filter = this.getFilter();
    this.isLoading = true;
    this.carts = [];
    this.cartApi.find(filter)
      .subscribe((items: Cart[]) => {
        this.carts = items;
        this.notifierService.notify('success', 'Load orders successfully');
        this.isLoading = false;

      }, error1 => {
        this.isLoading = false;
        this.carts = [];
        this.notifierService.notify('error', error1);
      });
  }

  resetFilter() {
    this.filterMonth = null;
    this.filterYear = moment().year();
    this.filterOrderId = null;
    this.findCart();
  }

  deleteCart(cart: Cart) {
    if (this.getRole()) {
      return;
    }
    this.cartApi.deleteById(cart.id)
      .subscribe(() => {
        this.notifierService.notify('success', 'Delete product successfully');
        this.findCart();
      }, error1 => {
        this.notifierService.notify('error', error1);
      });
  }

  getOrderStatus(status: string) {
    if (status === 'RESOLVED') {
      return 'Chốt đơn';
    } else if (status === 'CANCEL') {
      return 'Hủy đơn';
    } else if (status === 'DELIVERING') {
      return 'Đang giao';
    } else if (status === 'DELIVERED') {
      return 'Đã giao';
    } else if (status === 'RETURN') {
      return 'Trả lại hàng';
    } else {
      return 'Chờ xử lý';
    }

  }
}
