import {Component, OnInit} from '@angular/core';
import {GameHelp} from '../../../api/models';
import {NotifierService} from 'angular-notifier';
import {HttpClient} from '@angular/common/http';
import {GameHelpApi} from '../../../api/services/custom';
import {LoopBackConfig} from '../../../api';

declare const $;

@Component({
  selector: 'app-help-video',
  templateUrl: './help-video.component.html',
  styleUrls: ['./help-video.component.css']
})
export class HelpVideoComponent implements OnInit {
  videos: GameHelp[] = [];
  assign = Object.assign;
  currentVideo = new GameHelp();
  isLoading = false;
  formValidation: any;
  log = '';
  videoTopicFilter = 'ALL';
  videoMapFilter = 'ALL';

  constructor(private gameHelpApi: GameHelpApi,
              private notifierService: NotifierService,
              private httpClient: HttpClient) {
  }

  ngOnInit() {
    this.findVideo();
    this.formValidation = $('#add_video').parsley();
  }

  onChange($event: any): void {
    console.log('onChange');
    // this.log += new Date() + "<br />";
  }

  getFilter() {
    let videoTopic = this.videoTopicFilter;
    let videoMap = this.videoMapFilter;
    if (videoTopic === 'ALL') {
      videoTopic = undefined;
    }
    if (videoMap === 'ALL') {
      videoMap = undefined;
    }
    let filter;
    filter = {
      'order': 'topic ASC',
      where: {
        and: [
          {
            topic: videoTopic || undefined,
          },
          {
            type: videoMap || undefined
          }
        ]
      }
    };
    return filter;
  }

  findVideo() {
    const filter = this.getFilter();
    this.isLoading = true;
    this.gameHelpApi.find(filter)
      .subscribe((videos: GameHelp[]) => {
        this.isLoading = false;
        this.videos = videos;
      }, error1 => {
        this.videos = [];
        this.isLoading = false;
      });
  }

  onThumbChange(file) {
    if (file) {
      this.uploadFile(file, 'videos', 'img');
    }
  }

  uploadFile(file, container: string, type: string) {
    const formData = new FormData();
    formData.append('file', file);
    this.httpClient
      .post(`${LoopBackConfig.getPath()}/${LoopBackConfig.getApiVersion()}/Containers/` + container + `/upload`, formData)
      .subscribe((result: any) => {
        switch (type) {
          case 'img':
            this.currentVideo.url = result.result.files.file[0].name;
            break;
          default:
            break;
        }
      }, error => {
      });
  }

  createOrEditvideos(video: GameHelp) {
    if (!video.id) {
      const createdvideo = Object.assign({}, video);
      this.formValidation.whenValidate()
        .then(event => {
          $('#formAdd').modal('hide');
          this.gameHelpApi
            .create(createdvideo)
            .subscribe(() => {
              this.findVideo();
              this.notifierService.notify('success', 'Create videos successfully');
            }, error1 => {
            });
        });
    } else {
      this.formValidation.whenValidate()
        .then(() => {
          $('#formAdd').modal('hide');
          this.gameHelpApi.patchAttributes(video.id, video)
            .subscribe(() => {
              video = this.assign({});
              this.notifierService.notify('success', 'Update videos successfully');
              this.findVideo();
            }, error1 => {
            });
        });

    }
  }

  deleteVideo(video: GameHelp) {
    this.gameHelpApi.deleteById(video.id)
      .subscribe(() => {
        this.notifierService.notify('success', 'Delete video successfully');
        this.findVideo();
      }, error1 => {
        this.notifierService.notify('error', error1);
      });
  }
  getVideo() {
    const imgPath = `${LoopBackConfig.getPath()}/${LoopBackConfig.getApiVersion()}/Containers/videos/download/`;
    return imgPath;
  }
}
