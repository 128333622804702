import { Component, OnInit } from '@angular/core';
import { AccountApi } from '../../api/services/custom';
import { Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-forgot-passwords',
  templateUrl: './forgot-passwords.component.html',
  styleUrls: ['./forgot-passwords.component.css']
})
export class ForgotPasswordsComponent implements OnInit {
  email = '';

  constructor(private accountApi: AccountApi,
              private router: Router,
              private notifierService: NotifierService) {
  }

  ngOnInit() {
  }

  forgotPassword() {
    this.accountApi.resetPassword({ email: this.email })
      .subscribe(() => {
        this.router.navigate(['/login']);
        this.notifierService.notify('success', 'Email đổi mật khẩu đã được gửi. Vui lòng kiểm tra email để hoàn tất việc đổi mật khẩu');
      }, error => {
        this.notifierService.notify('error', `${error.message}`);
      });
  }
}
