/* tslint:disable */
import {
  City,
  County,
  Account,
  Cart
} from '../index';

declare var Object: any;
export interface OrderInterface {
  "orderName"?: string;
  "voucher"?: string;
  "customerName"?: string;
  "customerPhone"?: string;
  "customerPhone2"?: string;
  "customerEmail"?: string;
  "customerAddress"?: string;
  "customerNote"?: string;
  "deliverMethod"?: string;
  "recall"?: boolean;
  "price"?: number;
  "discountPartner"?: number;
  "discountCustomer"?: number;
  "status"?: string;
  "kind"?: string;
  "commissionStatus"?: boolean;
  "commissionDate"?: Date;
  "commission"?: number;
  "deliveredAt"?: Date;
  "payMethod"?: string;
  "id"?: number;
  "cityId"?: number;
  "countyId"?: number;
  "accountId"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  city?: City;
  county?: County;
  account?: Account;
  cart?: Cart[];
}

export class Order implements OrderInterface {
  "orderName": string;
  "voucher": string;
  "customerName": string;
  "customerPhone": string;
  "customerPhone2": string;
  "customerEmail": string;
  "customerAddress": string;
  "customerNote": string;
  "deliverMethod": string;
  "recall": boolean;
  "price": number;
  "discountPartner": number;
  "discountCustomer": number;
  "status": string;
  "kind": string;
  "commissionStatus": boolean;
  "commissionDate": Date;
  "commission": number;
  "deliveredAt": Date;
  "payMethod": string;
  "id": number;
  "cityId": number;
  "countyId": number;
  "accountId": number;
  "createdAt": Date;
  "updatedAt": Date;
  city: City;
  county: County;
  account: Account;
  cart: Cart[];
  constructor(data?: OrderInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Order`.
   */
  public static getModelName() {
    return "Order";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Order for dynamic purposes.
  **/
  public static factory(data: OrderInterface): Order{
    return new Order(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Order',
      plural: 'Orders',
      path: 'Orders',
      idName: 'id',
      properties: {
        "orderName": {
          name: 'orderName',
          type: 'string'
        },
        "voucher": {
          name: 'voucher',
          type: 'string'
        },
        "customerName": {
          name: 'customerName',
          type: 'string'
        },
        "customerPhone": {
          name: 'customerPhone',
          type: 'string'
        },
        "customerPhone2": {
          name: 'customerPhone2',
          type: 'string'
        },
        "customerEmail": {
          name: 'customerEmail',
          type: 'string'
        },
        "customerAddress": {
          name: 'customerAddress',
          type: 'string'
        },
        "customerNote": {
          name: 'customerNote',
          type: 'string'
        },
        "deliverMethod": {
          name: 'deliverMethod',
          type: 'string'
        },
        "recall": {
          name: 'recall',
          type: 'boolean'
        },
        "price": {
          name: 'price',
          type: 'number'
        },
        "discountPartner": {
          name: 'discountPartner',
          type: 'number'
        },
        "discountCustomer": {
          name: 'discountCustomer',
          type: 'number'
        },
        "status": {
          name: 'status',
          type: 'string'
        },
        "kind": {
          name: 'kind',
          type: 'string'
        },
        "commissionStatus": {
          name: 'commissionStatus',
          type: 'boolean'
        },
        "commissionDate": {
          name: 'commissionDate',
          type: 'Date'
        },
        "commission": {
          name: 'commission',
          type: 'number'
        },
        "deliveredAt": {
          name: 'deliveredAt',
          type: 'Date'
        },
        "payMethod": {
          name: 'payMethod',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "cityId": {
          name: 'cityId',
          type: 'number'
        },
        "countyId": {
          name: 'countyId',
          type: 'number'
        },
        "accountId": {
          name: 'accountId',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        city: {
          name: 'city',
          type: 'City',
          model: 'City',
          relationType: 'belongsTo',
                  keyFrom: 'cityId',
          keyTo: 'id'
        },
        county: {
          name: 'county',
          type: 'County',
          model: 'County',
          relationType: 'belongsTo',
                  keyFrom: 'countyId',
          keyTo: 'id'
        },
        account: {
          name: 'account',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'accountId',
          keyTo: 'id'
        },
        cart: {
          name: 'cart',
          type: 'Cart[]',
          model: 'Cart',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'orderId'
        },
      }
    }
  }
}
