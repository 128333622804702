/* tslint:disable */

declare var Object: any;
export interface AboutInterface {
  "thumb1"?: string;
  "title1"?: string;
  "content1"?: string;
  "thumb2"?: string;
  "title2"?: string;
  "content2"?: string;
  "thumb3"?: string;
  "title3"?: string;
  "content3"?: string;
  "members"?: number;
  "courses"?: number;
  "books"?: number;
  "robots"?: number;
  "services"?: string;
  "facebook"?: string;
  "google"?: string;
  "ios"?: string;
  "twitter"?: string;
  "youtube"?: string;
  "tel"?: string;
  "mobile"?: string;
  "mail"?: string;
  "address"?: string;
  "android"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
}

export class About implements AboutInterface {
  "thumb1": string;
  "title1": string;
  "content1": string;
  "thumb2": string;
  "title2": string;
  "content2": string;
  "thumb3": string;
  "title3": string;
  "content3": string;
  "members": number;
  "courses": number;
  "books": number;
  "robots": number;
  "services": string;
  "facebook": string;
  "google": string;
  "ios": string;
  "twitter": string;
  "youtube": string;
  "tel": string;
  "mobile": string;
  "mail": string;
  "address": string;
  "android": string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  constructor(data?: AboutInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `About`.
   */
  public static getModelName() {
    return "About";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of About for dynamic purposes.
  **/
  public static factory(data: AboutInterface): About{
    return new About(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'About',
      plural: 'Abouts',
      path: 'Abouts',
      idName: 'id',
      properties: {
        "thumb1": {
          name: 'thumb1',
          type: 'string'
        },
        "title1": {
          name: 'title1',
          type: 'string'
        },
        "content1": {
          name: 'content1',
          type: 'string'
        },
        "thumb2": {
          name: 'thumb2',
          type: 'string'
        },
        "title2": {
          name: 'title2',
          type: 'string'
        },
        "content2": {
          name: 'content2',
          type: 'string'
        },
        "thumb3": {
          name: 'thumb3',
          type: 'string'
        },
        "title3": {
          name: 'title3',
          type: 'string'
        },
        "content3": {
          name: 'content3',
          type: 'string'
        },
        "members": {
          name: 'members',
          type: 'number'
        },
        "courses": {
          name: 'courses',
          type: 'number'
        },
        "books": {
          name: 'books',
          type: 'number'
        },
        "robots": {
          name: 'robots',
          type: 'number'
        },
        "services": {
          name: 'services',
          type: 'string'
        },
        "facebook": {
          name: 'facebook',
          type: 'string'
        },
        "google": {
          name: 'google',
          type: 'string'
        },
        "ios": {
          name: 'ios',
          type: 'string'
        },
        "twitter": {
          name: 'twitter',
          type: 'string'
        },
        "youtube": {
          name: 'youtube',
          type: 'string'
        },
        "tel": {
          name: 'tel',
          type: 'string'
        },
        "mobile": {
          name: 'mobile',
          type: 'string'
        },
        "mail": {
          name: 'mail',
          type: 'string'
        },
        "address": {
          name: 'address',
          type: 'string'
        },
        "android": {
          name: 'android',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
      }
    }
  }
}
