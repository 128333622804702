import { Component, OnInit } from '@angular/core';
import { Card, Course, CourseCategory } from '../../../api/models';
import { AccountApi, CardApi, CourseApi, CourseCategoryApi } from '../../../api/services/custom';
import { NotifierService } from 'angular-notifier';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
import { flatMap } from 'rxjs/operators';

declare const $;

@Component({
  selector: 'app-course-register',
  templateUrl: './course-register.component.html',
  styleUrls: ['./course-register.component.css']
})
export class CourseRegisterComponent implements OnInit {

  isLoading = false;
  assign = Object.assign;
  formValidation: any;
  courses: Course[] = [];
  coursesList: Course[] = [];
  currentCard = new Card();
  courseCategories: CourseCategory[] = [];
  courseSubCategories: CourseCategory[] = [];
  emailFilter = '';
  selectCourseCode = null;
  activeCourseID = 0;
  numOfDay = '';
  selectCourseAccountId = null;
  slectCourseActive = false;
  url = '';
  cards: Card[];

  constructor(private courseApi: CourseApi,
              private notifierService: NotifierService,
              private userApi: AccountApi,
              private httpClient: HttpClient,
              private courseCategoryApi: CourseCategoryApi,
              private cardApi: CardApi) {
  }

  ngOnInit() {
    this.formValidation = $('#editCourse').parsley();
    this.findCourseCategory('NINE');
    // this.findCard();
    this.findCourse();
  }


  createActiveCourse() {
    const cardActive = new Card();
    cardActive.userUId = this.selectCourseAccountId;
    cardActive.accountId = this.selectCourseAccountId;
    cardActive.days = parseInt(this.numOfDay, 10);
    cardActive.activedAt = moment().toDate();
    cardActive.status = true;
    cardActive.kind = 'video';
    cardActive.serial = this.activeCourseID;
    cardActive.courseId = this.activeCourseID;
    this.cardApi.create(cardActive)
      .subscribe((card: Card) => {
        if (card && card.id) {
          this.notifierService.notify('success', 'Reactive OK');
        } else {
          this.notifierService.notify('error', 'Reactive error');
        }
      }, error => {
        console.log('Reactive error', error);
        this.notifierService.notify('error', 'Reactive error', error);
      });
  }

  // findCard() {
  //   const filter = {
  //     where: {
  //       userUId: {
  //         gt: 0
  //       }
  //     }
  //   };
  //   this.cardApi.find(filter)
  //     .pipe(
  //       flatMap((cards: Card[]) => {
  //         if (cards && cards.length > 0) {
  //           this.cards = cards;
  //         }
  //         return this.courseApi.find(this.getFilter());
  //       })
  //     )
  //     .subscribe((courses: Course[]) => {
  //       this.courses = courses;
  //     }, error => {
  //       this.courses = [];
  //     });
  // }

  // findCardInCourse(course: Course) {
  //   if (course && course.accountId) {
  //     const cardFound: Card = this.cards.find((it: Card) => it.userUId === course.accountId);
  //     if (cardFound) {
  //       return cardFound.key;
  //     } else {
  //       return 0;
  //     }
  //   }
  // }

  // reActiveCourse(course: Course) {
  //   const cardActive = new Card();
  //   console.log('course input', course);
  //   cardActive.userUId = course.accountId;
  //   cardActive.accountId = course.accountId;
  //   cardActive.days = parseInt(course.time, 10);
  //   cardActive.activedAt = course.updatedAt;
  //   cardActive.status = true;
  //   cardActive.kind = 'video';
  //   const filterGetIdCourse = {
  //     where: {
  //       and: [
  //         {
  //           code: course.code,
  //         },
  //         {
  //           or: [{
  //             accountId: {
  //               lt: 1
  //             },
  //           }, {
  //             accountId: null
  //           }]
  //         }
  //       ]
  //     }
  //   };
  //   this.courseApi.findOne(filterGetIdCourse)
  //     .pipe(
  //       flatMap((res: Course) => {
  //         if (res && res.id) {
  //           cardActive.serial = res.id;
  //           cardActive.courseId = res.id;
  //           console.log('res course', res);
  //           console.log('card active course', cardActive);
  //           return this.cardApi.create(cardActive);
  //         }
  //       })
  //     )
  //     .subscribe((card: Card) => {
  //       if (card && card.id) {
  //         this.notifierService.notify('success', 'Reactive OK');
  //       } else {
  //         this.notifierService.notify('error', 'Reactive error');
  //       }
  //     }, error => {
  //       console.log('Reactive error', error);
  //       this.notifierService.notify('error', 'Reactive error', error);
  //     });
  // }

  findCourseCategory(kind: string) {
    let filter = null;
    if (kind) {
      filter = {
        include: {
          relation: 'course'
        },
        where: {
          kind: kind
        }
      };
    }
    this.courseCategoryApi.find(filter)
      .subscribe((courseCategories: CourseCategory[]) => {
        this.courseCategories = courseCategories;
      }, error1 => {
      });
  }

  joinCourse() {
    for (const category of this.courseSubCategories) {
      for (const course of category.course) {
        this.coursesList.push(course);
      }
    }
  }

  getFilter() {
    if (this.emailFilter) {
      return {
        include: [
          {
            relation: 'course',
            scope: {
              include: {
                relation: 'category'
              }
            }
          }
        ],
        where: {
          userUId: this.emailFilter,
          serial: { gt: 0 },
          courseId: { gt: 0 }
        },
        order: 'activedAt DESC'
      };
    }
    return {
      include: [
        {
          relation: 'course',
          scope: {
            include: {
              relation: 'category'
            }
          }
        }
      ],
      where: {
        userUId: { gt: 0 },
        serial: { gt: 0 },
        courseId: { gt: 0 }
      },
      order: 'activedAt DESC'
    };
  }

  // getFilter() {
  //   if (this.emailFilter) {
  //     return {
  //       'include': [
  //         {
  //           relation: 'category'
  //         }
  //       ],
  //       'where': {
  //         'accountId': this.emailFilter
  //       },
  //       order: 'accountId DESC'
  //     };
  //   }
  //   return {
  //     'include': [
  //       {
  //         relation: 'category'
  //       }
  //     ],
  //     'where': {
  //       'accountId': { gt: 0 }
  //     },
  //     order: 'accountId DESC'
  //   };
  // }

  // findCourse() {
  //   this.isLoading = true;
  //   const filter = this.getFilter();
  //   this.courseApi.find(filter)
  //     .subscribe((courses: Course[]) => {
  //       this.courses = courses;
  //       this.isLoading = false;
  //     }, error1 => {
  //       this.courses = [];
  //       this.isLoading = false;
  //     });
  // }

  resetFilter() {
    this.emailFilter = null;
    this.findCourse();
  }

  findCourse() {
    this.isLoading = true;
    const filter = this.getFilter();
    this.cardApi.find(filter)
      .subscribe((cards: Card[]) => {
        if (cards && cards.length > 0) {
          this.cards = cards;
        }
        this.isLoading = false;
      }, error1 => {
        this.cards = [];
        this.isLoading = false;
      });
  }

  deleteCourse(card: Card) {
    this.cardApi.deleteById(card.id)
      .subscribe(() => {
        this.notifierService.notify('success', 'Delete course successfully');
        this.findCourse();
      }, error1 => {
        this.notifierService.notify('error', error1);
      });
  }
}
