/* tslint:disable */

declare var Object: any;
export interface CommissionInterface {
  "min"?: number;
  "max"?: number;
  "commission"?: number;
  "avaiable"?: Date;
  "kind"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
}

export class Commission implements CommissionInterface {
  "min": number;
  "max": number;
  "commission": number;
  "avaiable": Date;
  "kind": string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  constructor(data?: CommissionInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Commission`.
   */
  public static getModelName() {
    return "Commission";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Commission for dynamic purposes.
  **/
  public static factory(data: CommissionInterface): Commission{
    return new Commission(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Commission',
      plural: 'Commissions',
      path: 'Commissions',
      idName: 'id',
      properties: {
        "min": {
          name: 'min',
          type: 'number'
        },
        "max": {
          name: 'max',
          type: 'number'
        },
        "commission": {
          name: 'commission',
          type: 'number'
        },
        "avaiable": {
          name: 'avaiable',
          type: 'Date'
        },
        "kind": {
          name: 'kind',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
      }
    }
  }
}
