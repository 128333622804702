/* tslint:disable */
import {
  Account
} from '../index';

declare var Object: any;
export interface RequestInterface {
  "message": string;
  "status": string;
  "id"?: number;
  "accountId"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  account?: Account;
}

export class Request implements RequestInterface {
  "message": string;
  "status": string;
  "id": number;
  "accountId": number;
  "createdAt": Date;
  "updatedAt": Date;
  account: Account;
  constructor(data?: RequestInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Request`.
   */
  public static getModelName() {
    return "Request";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Request for dynamic purposes.
  **/
  public static factory(data: RequestInterface): Request{
    return new Request(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Request',
      plural: 'Requests',
      path: 'Requests',
      idName: 'id',
      properties: {
        "message": {
          name: 'message',
          type: 'string'
        },
        "status": {
          name: 'status',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "accountId": {
          name: 'accountId',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        account: {
          name: 'account',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'accountId',
          keyTo: 'id'
        },
      }
    }
  }
}
