import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Account, City, County, School } from '../../../api/models';
import { AccountApi, CityApi, CountyApi, SchoolApi } from '../../../api/services/custom';
import 'rxjs-compat/add/operator/mergeMap';
import { truncate } from 'lodash';
import { LoopBackConfig } from '../../../api';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { zip } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotifierService } from 'angular-notifier';

declare const $;

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {
  accounts: Account[] = [];
  passwordConfirm = '';
  currentAccount = new Account();
  currentOldAccount = new Account();
  isOldAccount = false;


  assign = Object.assign;
  userLoading = false;
  formValidation: any;
  formValidationPartner: any;
  isEdit = false;

  truncate = truncate;
  cities: City[] = [];
  counties: County[] = [];
  schools: number[] = [1, 2, 3, 4];
  classNames: string[] = ['1', '2', '3', '4'];

  cityFilter = undefined;
  countyFilter = undefined;
  schoolFilter = undefined;
  classFilter = undefined;
  emailFilter = undefined;
  socialKind = '';
  PAGE_SIZE = environment.PAGE_SIZE;
  currentPage = 0;
  totalItems = 0;

  constructor(private accountApi: AccountApi,
              private router: Router,
              private route: ActivatedRoute,
              private httpClient: HttpClient,
              private cityApi: CityApi,
              private countyApi: CountyApi,
              private spinner: NgxSpinnerService,
              private notifierService: NotifierService) {
  }

  ngOnInit() {
    this.formValidation = $('#add_user').parsley();
    this.formValidationPartner = $('#add_partner').parsley();
    this.findCity();
    this.findAccounts();
  }

  changePage() {
    this.findAccounts(this.PAGE_SIZE * (this.currentPage - 1));
  }

  getFilter(skip: number = 0, limit: number = this.PAGE_SIZE) {
    return {
      where: {
        email: this.emailFilter ? { 'like': `%${this.emailFilter}%` } : undefined,
        class: this.classFilter ? { 'like': `%${this.classFilter}%` } : undefined,
        schoolId: this.schoolFilter || undefined,
        countyId: this.countyFilter || undefined,
        cityId: this.cityFilter || undefined,
        kind:
          {
            inq: [
              'USER',
              'ADMIN',
              'PARTNER'
            ]
          }
      },
      skip,
      limit,
      'order': 'createdAt DESC'
    };
  }

  resetFilter() {
    this.cityFilter = null;
    this.countyFilter = null;
    this.schoolFilter = null;
    this.counties = [];
    this.schools = [];
    this.findAccounts();
  }

  findAccounts(skip: number = 0, limit: number = this.PAGE_SIZE) {
    if (skip <= 0) {
      skip = 0;
      this.currentPage = 1;
    }
    this.userLoading = true;
    this.spinner.show().then().catch();
    const filter = this.getFilter(skip, limit);
    zip(
      this.accountApi.find(filter),
      this.accountApi.count(filter.where)
    ).subscribe(([user, count]: [Account[], { count: number }]) => {
      this.userLoading = false;
      this.spinner.hide().then().catch();
      this.totalItems = count.count || 0;
      this.accounts = user;
      this.accounts = user;
      this.notifierService.notify('success', 'Thành công');
    }, error1 => {
      this.spinner.hide().then().catch();
      this.userLoading = false;
      this.accounts = [];
      this.notifierService.notify('error', error1);
    });
  }

  getSchoolFilter() {
    if (this.countyFilter) {
      return {
        'where': {
          'countyId': this.countyFilter
        }
      };
    }
    if (this.cityFilter) {
      return {
        'where': {
          'cityId': this.cityFilter
        }
      };
    }
  }

  findCity() {
    this.cityApi.find()
      .subscribe((cities: City[]) => {
        this.cities = cities;
        this.findCounty();
      }, error1 => {
        this.cities = [];
        this.notifierService.notify('error', error1);
      });
  }

  findCounty() {
    if (this.cityFilter) {
      this.classFilter = null;
      const filter = {
        'where': {
          'mtpId': this.cityFilter
        }
      };
      this.countyApi.find(filter)
        .subscribe((counties: County[]) => {
          this.counties = counties;
        }, error1 => {
          this.counties = [];
          this.notifierService.notify('error', error1);
        });
    }
  }

  createOrEditUser(account: Account) {
    if (!account.id) {
      const createdUser = Object.assign({}, account);
      this.formValidation.whenValidate()
        .then(event => {
          $('#form-add').modal('hide');
          if (this.isOldAccount === true) {
            createdUser.oldPass = createdUser.password;
            createdUser.oldPassMigrate = '1';
          } else {
            createdUser.oldPass = '';
            createdUser.oldPassMigrate = '';
          }
          createdUser.socialKind = this.socialKind;
          this.isOldAccount = false;
          this.spinner.show().then().catch();
          this.accountApi
            .create(createdUser)
            .subscribe(() => {
              this.spinner.hide().then().catch();
              account = this.assign({});
              this.findAccounts();
            }, error => {
              this.spinner.hide().then().catch();
              this.notifierService.notify('error', error);
            });
        });
    } else {
      this.formValidation.whenValidate()
        .then(() => {
          $('#form-add').modal('hide');
          if (account.oldPassMigrate === '1') {
            account.oldPass = account.password;
          }
          this.spinner.show().then().catch();
          this.accountApi.patchAttributes(account.id, account)
            .subscribe(() => {
              this.spinner.hide().then().catch();
              account = this.assign({});
              this.findAccounts();
            }, error => {
              this.spinner.hide().then().catch();
              this.notifierService.notify('error', error);
            });
        });

    }
  }

  deleteUser(account: Account) {
    this.spinner.show().then().catch();
    this.accountApi
      .deleteById(account.id)
      .flatMap(() => {
        return this.accountApi.deleteById(account.id);
      })
      .subscribe(() => {
        this.spinner.hide().then().catch();
        this.findAccounts();
      }, error => {
        this.spinner.show().then().catch();
      });
  }

  onAvatarChanged(file) {
    if (file) {
      this.uploadImage(file);
    }
  }

  uploadImage(file) {
    const formData = new FormData();
    formData.append('file', file);
    this.spinner.show().then().catch();
    this.httpClient
      .post(`${LoopBackConfig.getPath()}/${LoopBackConfig.getApiVersion()}/Containers/users/upload`, formData)
      .subscribe((result: any) => {
        this.spinner.hide().then().catch();
        this.currentAccount.avatar = result.result.files.file[0].name;
      }, error => {
        this.spinner.hide().then().catch();
        this.currentAccount.avatar = undefined;
        this.notifierService.notify('error', error);
      });
  }

  createOrEditPartner(account: Account) {
    if (!account.id) {
      const createdUser = Object.assign({}, account);
      createdUser.kind = 'PARTNER';
      this.formValidationPartner.whenValidate()
        .then(event => {
          $('#form-add-partner').modal('hide');
          this.spinner.show().then().catch();
          this.accountApi
            .create(createdUser)
            .subscribe(() => {
              this.spinner.hide().then().catch();
              account = this.assign({});
              this.findAccounts();
            }, error => {
              this.spinner.hide().then().catch();
              this.notifierService.notify('error', error);
            });
        });
    } else {
      this.formValidationPartner.whenValidate()
        .then(() => {
          $('#form-add-partner').modal('hide');
          this.spinner.show().then().catch();
          this.accountApi.patchAttributes(account.id, account)
            .subscribe(() => {
              this.spinner.hide().then().catch();
              account = this.assign({});
              this.findAccounts();
            }, error => {
              this.spinner.hide().then().catch();
              this.notifierService.notify('error', error);
            });
        });
    }
  }
}
