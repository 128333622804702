import { Component, OnInit, ViewChild } from '@angular/core';
import { Image } from '../../../api/models';
import { ImageApi, LoopBackConfig } from '../../../api';
import { NotifierService } from 'angular-notifier';
import { HttpClient } from '@angular/common/http';

declare const $;

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit {
  news: Image[] = [];
  assign = Object.assign;
  currentNews = new Image();
  isLoading = false;
  formValidation: any;
  name = 'ng2-ckeditor';
  ckeConfig: any;
  mycontent: string;
  log = '';
  @ViewChild('myckeditor') ckeditor: any;

  constructor(private imageApi: ImageApi,
              private notifierService: NotifierService,
              private httpClient: HttpClient) {
  }

  ngOnInit() {
    this.findNews();
    this.formValidation = $('#add_new').parsley();
    this.ckeConfig = {
      allowedContent: false,
      forcePasteAsPlainText: true,
      enterMode: 2,
      toolbarGroups: [
        { name: 'document', groups: ['mode'] },
        { name: 'clipboard', groups: ['clipboard', 'undo'] },
        { name: 'editing', groups: ['find', 'selection', 'spellchecker'] },
        '/',
        { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
        { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi'] },
        { name: 'links' },
        { name: 'insert' },
        '/',
        { name: 'styles' },
        { name: 'colors' }]
    };
  }

  onChange($event: any): void {
    console.log('onChange');
    // this.log += new Date() + "<br />";
  }

  findNews() {
    const filter = {
      'order': ['categories ASC', 'createdAt DESC'],
      where: {
        categories:
          {
            inq: [
              'home',
              'event',
              'about'
            ]
          }
      }
    };
    this.isLoading = true;
    this.imageApi.find(filter)
      .subscribe((news: Image[]) => {
        this.isLoading = false;
        this.news = news;
      }, error1 => {
        this.news = [];
        this.isLoading = false;
      });
  }

  onThumbChange(file) {
    if (file) {
      this.uploadFile(file, 'imgs', 'img');
    }
  }

  uploadFile(file, container: string, type: string) {
    const formData = new FormData();
    formData.append('file', file);
    this.httpClient
      .post(`${LoopBackConfig.getPath()}/${LoopBackConfig.getApiVersion()}/Containers/` + container + `/upload`, formData)
      .subscribe((result: any) => {
        switch (type) {
          case 'img':
            this.currentNews.thumbnail = result.result.files.file[0].name;
            break;
          default:
            break;
        }
      }, error => {
      });
  }

  createOrEditnews(news: Image) {
    if (!news.id) {
      const creatednews = Object.assign({}, news);
      this.formValidation.whenValidate()
        .then(event => {
          $('#formAdd').modal('hide');
          // creatednews.categories = 'event';
          this.imageApi
            .create(creatednews)
            .subscribe(() => {
              this.findNews();
              this.notifierService.notify('success', 'Update cards successfully');
            }, error1 => {
            });
        });
    } else {
      this.formValidation.whenValidate()
        .then(() => {
          $('#formAdd').modal('hide');
          this.imageApi.patchAttributes(news.id, news)
            .subscribe(() => {
              news = this.assign({});
              this.findNews();
            }, error1 => {
            });
        });

    }
  }

  deleteNews(news: Image) {
    this.imageApi.deleteById(news.id)
      .subscribe(() => {
        this.notifierService.notify('success', 'Delete cards successfully');
        this.findNews();
      }, error1 => {
        this.notifierService.notify('error', error1);
      });
  }
}
