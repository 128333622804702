import { Component, OnInit } from '@angular/core';
import { Card, Course } from '../../../api/models';
import { AccountApi, CardApi, CourseApi } from '../../../api/services/custom';
import { NotifierService } from 'angular-notifier';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { zip } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';

declare const $;


@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.css']
})
export class CardComponent implements OnInit {
  cards: Card[] = [];
  currentCard = new Card();
  partners: Account[] = [];
  isLoading = false;
  assign = Object.assign;
  formValidation: any;
  numberOfCard = 1;
  userIdFilter: number;
  courses: Course[] = [];
  PAGE_SIZE = environment.PAGE_SIZE;
  currentPage: number;
  totalItems: number;
  activeFilter = undefined;
  nameFilter = undefined;

  constructor(private cardApi: CardApi,
              private courseApi: CourseApi,
              private notifierService: NotifierService,
              private accountApi: AccountApi,
              private route: ActivatedRoute,
              private router: Router,
              private spinner: NgxSpinnerService) {
  }

  ngOnInit() {
    this.route.queryParams
      .subscribe(params => {
        this.userIdFilter = +params.userOId;
      });
    this.findCard();
    this.findPartner();
    this.formValidation = $('#add_card').parsley();
    this.findCourse();
  }

  getColor(card: Card) {
    if (!card) {
      return;
    }
    return card.status ? { 'background-color': '#37b358' } : { 'background-color': '#eb6357' };
  }

  getFilter(skip: number = 0, limit: number = this.PAGE_SIZE) {
    return {
      where: {
        userOId: this.userIdFilter || undefined,
        kind: null,
        status: this.activeFilter === 'active' ? true : (this.activeFilter === 'new' ? null : undefined),
        key: this.nameFilter ? { like: `%${this.nameFilter.slice(this.nameFilter.length - 6, this.nameFilter.length)}%` } : undefined
      },
      skip,
      limit,
      include: ['userO', 'course'],
      order: 'createdAt DESC'
    };
  }

  changePage() {
    this.findCard(this.PAGE_SIZE * (this.currentPage - 1));
  }

  findCard(skip: number = 0, limit: number = this.PAGE_SIZE) {
    if (skip <= 0) {
      skip = 0;
      this.currentPage = 1;
    }
    this.isLoading = true;
    const filter = this.getFilter(skip, limit);
    this.spinner.show().then().catch();
    zip(
      this.cardApi.find(filter),
      this.cardApi.count(filter.where)
    ).subscribe(([cards, count]: [Card [], { count: number }]) => {
      this.isLoading = false;
      this.spinner.hide().then().catch();
      this.totalItems = count.count;
      this.cards = cards;
      this.notifierService.notify('success', 'Load cards successfully');
    }, error1 => {
      this.spinner.hide().then().catch();
      this.isLoading = false;
      this.notifierService.notify('error', error1);
    });
  }

  findCourse() {
    // this.isLoading = true;
    const filter = {
      'order': 'createdAt DESC',
      'where': {
        'accountId': null
      }
    };
    this.courseApi.find(filter)
      .subscribe((courses: Course[]) => {
        // this.isLoading = false;
        this.courses = courses;
      }, error1 => {
        this.courses = [];
        // this.isLoading = false;
      });
  }

  findPartner() {
    const filter = {
      where: {
        kind: 'PARTNER'
      }
    };
    this.accountApi.find(filter)
      .subscribe((partners: Account[]) => {
        this.partners = partners;
      }, error1 => {
        this.partners = [];
      });
  }

  converCardSeri(card: Card) {
    if (card && card.serial) {
      let serial = card.serial.toString();
      while (serial.length < 6) {
        serial = '0' + serial;
      }
      return serial;
    } else {
      return null;
    }
  }

  createOrEditCard(card: Card) {
    card.courseId = card.serial;
    if (card.id) {
      this.spinner.show().then().catch();
      this.cardApi.patchAttributes(card.id, card)
        .subscribe(() => {
          this.spinner.hide().then().catch();
          $('#form-add').modal('hide');
          this.findCard(this.PAGE_SIZE * (this.currentPage - 1));
          this.notifierService.notify('success', 'Update card successfully');
        }, error1 => {
          this.spinner.hide().then().catch();
          this.notifierService.notify('error', error1);
        });
    } else {
      for (let i = 0; i < this.numberOfCard; i++) {
        this.spinner.show().then().catch();
        this.cardApi.create(card)
          .subscribe(() => {
            this.spinner.hide().then().catch();
            this.notifierService.notify('success', 'Create card successfully');
            $('#form-add').modal('hide');
          }, error1 => {
            this.spinner.hide().then().catch();
            this.notifierService.notify('error', error1);
          });
      }
      this.findCard(this.PAGE_SIZE * (this.currentPage - 1));
    }
  }

  deleteCard(card: Card) {
    this.cardApi.deleteById(card.id)
      .subscribe(() => {
        this.notifierService.notify('success', 'Delete card successfully');
        this.findCard(this.PAGE_SIZE * (this.currentPage - 1));
      }, error1 => {
        this.notifierService.notify('error', error1);
      });
  }

  updateQueryParams() {
    const queryParams: Params = Object.assign({}, this.route.snapshot.queryParams);
    queryParams['usrOId'] = this.userIdFilter;
    this.router.navigate([], { queryParams: queryParams });
  }
}
