import {Component, OnInit} from '@angular/core';
import {CourseCategory} from '../../../api/models';
import {NotifierService} from 'angular-notifier';
import {CourseCategoryApi} from '../../../api/services/custom';


declare const $;

@Component({
  selector: 'app-course-category',
  templateUrl: './course-category.component.html',
  styleUrls: ['./course-category.component.css']
})

export class CourseCategoryComponent implements OnInit {

  courseCategories: CourseCategory[] = [];
  currentCourseCategory = new CourseCategory();
  isLoading = false;
  assign = Object.assign;
  formValidation: any;
  kindFilter = null;

  constructor(private notifierService: NotifierService,
              private courseCategoryApi: CourseCategoryApi) {
  }

  ngOnInit() {
    this.findCourseCategory();
    this.formValidation = $('#add-category').parsley();
  }

  findCourseCategory() {
    this.isLoading = true;
    this.courseCategoryApi.find()
      .subscribe((courseCategorys: CourseCategory[]) => {
        this.courseCategories = courseCategorys;
        this.isLoading = false;
      }, error1 => {
        this.isLoading = false;
        this.courseCategories = [];

      });
  }

  filterCategory() {
    const filter = {
      'where': {
        'kind': this.kindFilter
      }
    };
    this.isLoading = true;
    this.courseCategoryApi.find(filter)
      .subscribe((courseCategorys: CourseCategory[]) => {
        this.courseCategories = courseCategorys;
        this.isLoading = false;
      }, error1 => {
        this.isLoading = false;
        this.courseCategories = [];

      });
  }

  createOrEditCourseCategory(courseCategory: CourseCategory) {
    if (courseCategory.id) {
      this.formValidation.whenValidate()
        .then(event => {
          this.courseCategoryApi.patchAttributes(courseCategory.id, courseCategory)
            .subscribe(() => {
              $('#formAdd').modal('hide');
              this.findCourseCategory();
              this.notifierService.notify('success', 'Update CourseCategory successfully');
            }, error1 => {
              this.notifierService.notify('error', error1);
            });
        });
    } else {
      this.formValidation.whenValidate()
        .then(event => {
          this.courseCategoryApi.create(courseCategory)
            .subscribe(() => {
              $('#formAdd').modal('hide');
              this.notifierService.notify('success', 'Successfully');
              this.findCourseCategory();
            }, error1 => {
              this.notifierService.notify('error', error1);
            });
        });
    }
  }

  getCategoryName(category: CourseCategory) {
    if (category.kind === 'NINE') {
      category.category = 'KHÓA HỌC TƯƠNG TÁC TRỰC QUAN < 9 TUỔI';
    } else {
      category.category =  'KHÓA HỌC VIDEO TRỰC TUYẾN';
    }
  }

  deleteCourseCategory(courseCategory: CourseCategory) {
    this.courseCategoryApi.deleteById(courseCategory.id)
      .subscribe(() => {
        this.notifierService.notify('success', 'Delete CourseCategory successfully');
        this.findCourseCategory();
      }, error1 => {
        this.notifierService.notify('error', error1);
      });
  }
}
