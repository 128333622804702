/* tslint:disable */

declare var Object: any;
export interface PostInterface {
  "pageDes"?: string;
  "pageTitle"?: string;
  "pageKeyWords"?: string;
  "canoLink"?: string;
  "title"?: string;
  "image"?: string;
  "description"?: string;
  "cataloguage"?: string;
  "link"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
}

export class Post implements PostInterface {
  "pageDes": string;
  "pageTitle": string;
  "pageKeyWords": string;
  "canoLink": string;
  "title": string;
  "image": string;
  "description": string;
  "cataloguage": string;
  "link": string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  constructor(data?: PostInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Post`.
   */
  public static getModelName() {
    return "Post";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Post for dynamic purposes.
  **/
  public static factory(data: PostInterface): Post{
    return new Post(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Post',
      plural: 'Posts',
      path: 'Posts',
      idName: 'id',
      properties: {
        "pageDes": {
          name: 'pageDes',
          type: 'string'
        },
        "pageTitle": {
          name: 'pageTitle',
          type: 'string'
        },
        "pageKeyWords": {
          name: 'pageKeyWords',
          type: 'string'
        },
        "canoLink": {
          name: 'canoLink',
          type: 'string'
        },
        "title": {
          name: 'title',
          type: 'string'
        },
        "image": {
          name: 'image',
          type: 'string'
        },
        "description": {
          name: 'description',
          type: 'string'
        },
        "cataloguage": {
          name: 'cataloguage',
          type: 'string'
        },
        "link": {
          name: 'link',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
      }
    }
  }
}
