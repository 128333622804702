import {Component, OnInit} from '@angular/core';
import {Account, Commission, Order, Partner} from '../../../api/models';

import {AccountApi, CommissionApi, OrderApi, PartnerApi} from '../../../api/services/custom';
import {ActivatedRoute, Router} from '@angular/router';
import {NotifierService} from 'angular-notifier';
import {LoopBackAuth} from '../../../api/services/core';
import * as moment from 'moment';
import * as _ from 'lodash';

@Component({
  selector: 'app-commission-partner',
  templateUrl: './commission-partner.component.html',
  styleUrls: ['./commission-partner.component.css']
})
export class CommissionPartnerComponent implements OnInit {
  userLogin = new Account();
  assign = Object.assign;
  isLoading = false;
  commissions: Commission[] = [];
  partners: Partner[] = [];

  constructor(private accountApi: AccountApi,
              private router: Router,
              private route: ActivatedRoute,
              private notifierService: NotifierService,
              private auth: LoopBackAuth,
              private commissionApi: CommissionApi,
              private orderApi: OrderApi,
              private partnerApi: PartnerApi) {
  }

  ngOnInit() {
    this.userLogin = this.auth.getCurrentUserData();
    this.findCommission();
    this.findPayment();
  }

  findCommission() {
    this.commissionApi.find()
      .subscribe((commissions: Commission[]) => {
        this.commissions = commissions;
      }, error1 => {
        return 0;
      });
  }
  findPayment() {
    const filter = {
      where: {
        accountId: this.userLogin.id
      },
      order: 'createdAt DESC'
    };
    this.isLoading = true;
    this.partnerApi.find(filter)
      .subscribe((partners: Partner[]) => {
        this.partners = partners;
        this.notifierService.notify('success', 'Get Payments successfully');
        this.isLoading = false;
      }, error1 => {
        this.notifierService.notify('error', error1.toString());
        this.partners = [];
      });
  }
}
