import {Component, OnInit} from '@angular/core';
import {Course, Faq, Feedback, Video} from '../../../api/models';
import {CourseApi, FaqApi, FeedbackApi, VideoApi} from '../../../api/services/custom';
import {NotifierService} from 'angular-notifier';
import {LoopBackConfig} from '../../../api';
import {HttpClient} from '@angular/common/http';

declare const $;

@Component({
  selector: 'app-commnent',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.css']
})
export class CommentComponent implements OnInit {
  comments: Feedback[] = [];
  currentComment = new Feedback();
  courses: Course[] = [];
  isLoading = false;
  assign = Object.assign;
  formValidation: any;
  videos: Video[] = [];
  uploadImgFile = '';

  constructor(private feedbackApi: FeedbackApi,
              public notifierService: NotifierService,
              private courseApi: CourseApi,
              private videoApi: VideoApi,
              private httpClient: HttpClient) {
  }

  ngOnInit() {
    this.findComment();
    this.formValidation = $('#add_user').parsley();
    this.findCourse();
    this.findVideo();
  }

  findComment() {
    const filter = {
      'order': 'createdAt DESC',
    };
    this.isLoading = true;
    this.feedbackApi.find(filter)
      .subscribe((comments: Feedback []) => {
        this.comments = comments;
        this.isLoading = false;
      }, error1 => {
        this.isLoading = false;
      });
  }

  findCourse() {
    const filter = {
      'order': 'createdAt DESC',
    };
    this.courseApi.find(filter)
      .subscribe((courses: Course[]) => {
        this.courses = courses;
      }, error1 => {
        this.courses = [];
      });
  }

  findVideo() {
    const filter = {
      'order': 'createdAt DESC',
    };
    this.videoApi.find(filter)
      .subscribe((videos: Video[]) => {
        this.videos = videos;
      }, error1 => {
        this.videos = [];
      });
  }

  createOrEditComment(comment: Feedback) {

    this.formValidation.whenValidate()
      .then(() => {
        if (comment.id) {
          this.isLoading = true;
          this.feedbackApi.patchAttributes(comment.id, comment)
            .subscribe(() => {
              $('#form-add').modal('hide');
              this.notifierService.notify('success', 'Update  faq successfully');
              this.findComment();
              this.isLoading = false;
            }, error1 => {
              this.notifierService.notify('error', error1);
              this.isLoading = false;
            });
        } else {
          this.isLoading = true;
          comment.status = 'active';
          this.feedbackApi.create(comment)
            .subscribe(() => {
              this.isLoading = false;
              $('#form-add').modal('hide');
              this.notifierService.notify('success', 'Create  faq successfully');
              this.findComment();
            }, error1 => {
              this.isLoading = false;
              this.notifierService.notify('error', error1);
            });
        }
      });
  }

  deleteComment(comment: Feedback) {
    this.feedbackApi.deleteById(comment.id)
      .subscribe(() => {
        this.notifierService.notify('success', 'Delete  faq successfully');
        this.findComment();
      }, error1 => {
        this.notifierService.notify('error', error1);

      });
  }

  onThumbChange(file) {
    if (file) {
      this.uploadFile(file, 'users', 'img');
    }
  }

  uploadFile(file, container: string, type: string) {
    const formData = new FormData();
    formData.append('file', file);
    this.httpClient
      .post(`${LoopBackConfig.getPath()}/${LoopBackConfig.getApiVersion()}/Containers/` + container + `/upload`, formData)
      .subscribe((result: any) => {
        switch (type) {
          case 'img':
            this.uploadImgFile = result.result.files.file[0].name;
            this.currentComment.avatar = this.uploadImgFile;
            break;
          default:
            break;
        }
      }, error => {
      });
  }

  toggleRead(comment: Feedback) {
    if (comment.read === 'unread') {
      comment.read = 'read';
    } else {
      comment.read = 'unread';
    }
    this.feedbackApi.patchAttributes(comment.id, comment)
      .subscribe(() => {
        this.notifierService.notify('success', `Comment change to ` + comment.read);
      }, error1 => {
        this.notifierService.notify('error', error1);
      });

  }

}
