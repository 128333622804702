import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';
import { LoopBackAuth } from '../api/services/core';
import { Roles } from '../comon/constants';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(private  router: Router,
              private auth: LoopBackAuth) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    const user = this.auth.getCurrentUserData();
    if (user && user.roles) {
      return true;
    }
    this.router.navigate(['/login']);
    return false;
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const user = this.auth.getCurrentUserData();
    return this.canActivate(childRoute, state) && user.roles.some(role => Roles[role.name] === 'SUPERADMIN');
  }
}
