/* tslint:disable */
/**
* @module SDKModule
* @author Jonathan Casarrubias <t:@johncasarrubias> <gh:jonathan-casarrubias>
* @license MIT 2016 Jonathan Casarrubias
* @version 2.1.0
* @description
* The SDKModule is a generated Software Development Kit automatically built by
* the LoopBack SDK Builder open source module.
*
* The SDKModule provides Angular 2 >= RC.5 support, which means that NgModules
* can import this Software Development Kit as follows:
*
*
* APP Route Module Context
* ============================================================================
* import { NgModule }       from '@angular/core';
* import { BrowserModule }  from '@angular/platform-browser';
* // App Root 
* import { AppComponent }   from './app.component';
* // Feature Modules
* import { SDK[Browser|Node|Native]Module } from './shared/sdk/sdk.module';
* // Import Routing
* import { routing }        from './app.routing';
* @NgModule({
*  imports: [
*    BrowserModule,
*    routing,
*    SDK[Browser|Node|Native]Module.forRoot()
*  ],
*  declarations: [ AppComponent ],
*  bootstrap:    [ AppComponent ]
* })
* export class AppModule { }
*
**/
import { ErrorHandler } from './services/core/error.service';
import { LoopBackAuth } from './services/core/auth.service';
import { LoggerService } from './services/custom/logger.service';
import { SDKModels } from './services/custom/SDKModels';
import { InternalStorage, SDKStorage } from './storage/storage.swaps';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CookieBrowser } from './storage/cookie.browser';
import { StorageBrowser } from './storage/storage.browser';
import { SocketBrowser } from './sockets/socket.browser';
import { SocketDriver } from './sockets/socket.driver';
import { SocketConnection } from './sockets/socket.connections';
import { RealTime } from './services/core/real.time';
import { InstallationApi } from './services/custom/Installation';
import { UserApi } from './services/custom/User';
import { RoleApi } from './services/custom/Role';
import { AccountApi } from './services/custom/Account';
import { RequestApi } from './services/custom/Request';
import { AccountTokenApi } from './services/custom/AccountToken';
import { ContainerApi } from './services/custom/Container';
import { AskApi } from './services/custom/Ask';
import { CompanyApi } from './services/custom/Company';
import { CourseApi } from './services/custom/Course';
import { FaqApi } from './services/custom/Faq';
import { PostApi } from './services/custom/Post';
import { ProductApi } from './services/custom/Product';
import { SubmissionApi } from './services/custom/Submission';
import { CardApi } from './services/custom/Card';
import { VideoApi } from './services/custom/Video';
import { FeedbackApi } from './services/custom/Feedback';
import { AboutApi } from './services/custom/About';
import { ImageApi } from './services/custom/Image';
import { ExamApi } from './services/custom/Exam';
import { CityApi } from './services/custom/City';
import { CountyApi } from './services/custom/County';
import { WardApi } from './services/custom/Ward';
import { CompetitionApi } from './services/custom/Competition';
import { SchoolApi } from './services/custom/School';
import { OrderApi } from './services/custom/Order';
import { VoucherApi } from './services/custom/Voucher';
import { CommissionApi } from './services/custom/Commission';
import { PartnerApi } from './services/custom/Partner';
import { SubscribeApi } from './services/custom/Subscribe';
import { TitleApi } from './services/custom/Title';
import { CourseCategoryApi } from './services/custom/CourseCategory';
import { PaymentApi } from './services/custom/Payment';
import { NotificationInAppApi } from './services/custom/NotificationInApp';
import { EnglishApi } from './services/custom/English';
import { GameHelpApi } from './services/custom/GameHelp';
import { GameDataApi } from './services/custom/GameData';
import { CartApi } from './services/custom/Cart';
/**
* @module SDKBrowserModule
* @description
* This module should be imported when building a Web Application in the following scenarios:
*
*  1.- Regular web application
*  2.- Angular universal application (Browser Portion)
*  3.- Progressive applications (Angular Mobile, Ionic, WebViews, etc)
**/
@NgModule({
  imports:      [ CommonModule, HttpClientModule ],
  declarations: [ ],
  exports:      [ ],
  providers:    [
    ErrorHandler,
    SocketConnection
  ]
})
export class SDKBrowserModule {
  static forRoot(internalStorageProvider: any = {
    provide: InternalStorage,
    useClass: CookieBrowser
  }): ModuleWithProviders {
    return {
      ngModule  : SDKBrowserModule,
      providers : [
        LoopBackAuth,
        LoggerService,
        SDKModels,
        RealTime,
        InstallationApi,
        UserApi,
        RoleApi,
        AccountApi,
        RequestApi,
        AccountTokenApi,
        ContainerApi,
        AskApi,
        CompanyApi,
        CourseApi,
        FaqApi,
        PostApi,
        ProductApi,
        SubmissionApi,
        CardApi,
        VideoApi,
        FeedbackApi,
        AboutApi,
        ImageApi,
        ExamApi,
        CityApi,
        CountyApi,
        WardApi,
        CompetitionApi,
        SchoolApi,
        OrderApi,
        VoucherApi,
        CommissionApi,
        PartnerApi,
        SubscribeApi,
        TitleApi,
        CourseCategoryApi,
        PaymentApi,
        NotificationInAppApi,
        EnglishApi,
        GameHelpApi,
        GameDataApi,
        CartApi,
        internalStorageProvider,
        { provide: SDKStorage, useClass: StorageBrowser },
        { provide: SocketDriver, useClass: SocketBrowser }
      ]
    };
  }
}
/**
* Have Fun!!!
* - Jon
**/
export * from './models/index';
export * from './services/index';
export * from './lb.config';
export * from './storage/storage.swaps';
export { CookieBrowser } from './storage/cookie.browser';
export { StorageBrowser } from './storage/storage.browser';

