/* tslint:disable */

declare var Object: any;
export interface FeedbackInterface {
  "name"?: string;
  "avatar"?: string;
  "email"?: string;
  "content"?: string;
  "kind"?: string;
  "status"?: string;
  "read"?: string;
  "id"?: number;
  "courseId"?: number;
  "videoId"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
}

export class Feedback implements FeedbackInterface {
  "name": string;
  "avatar": string;
  "email": string;
  "content": string;
  "kind": string;
  "status": string;
  "read": string;
  "id": number;
  "courseId": number;
  "videoId": number;
  "createdAt": Date;
  "updatedAt": Date;
  constructor(data?: FeedbackInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Feedback`.
   */
  public static getModelName() {
    return "Feedback";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Feedback for dynamic purposes.
  **/
  public static factory(data: FeedbackInterface): Feedback{
    return new Feedback(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Feedback',
      plural: 'Feedbacks',
      path: 'Feedbacks',
      idName: 'id',
      properties: {
        "name": {
          name: 'name',
          type: 'string'
        },
        "avatar": {
          name: 'avatar',
          type: 'string'
        },
        "email": {
          name: 'email',
          type: 'string'
        },
        "content": {
          name: 'content',
          type: 'string'
        },
        "kind": {
          name: 'kind',
          type: 'string'
        },
        "status": {
          name: 'status',
          type: 'string'
        },
        "read": {
          name: 'read',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "courseId": {
          name: 'courseId',
          type: 'number'
        },
        "videoId": {
          name: 'videoId',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
      }
    }
  }
}
