import { Component, OnInit } from '@angular/core';
import { AboutApi, ImageApi } from '../../../api/services/custom';
import { About, Image } from '../../../api/models';
import { NotifierService } from 'angular-notifier';
import { LoopBackConfig } from '../../../api';
import { HttpClient } from '@angular/common/http';

declare const $;

@Component({
  selector: 'app-paper',
  templateUrl: './paper.component.html',
  styleUrls: ['./paper.component.css']
})
export class PaperComponent implements OnInit {
  mainSliders: Image[] = [];
  assign = Object.assign;
  currentSlider = new Image();
  isLoading = false;
  formValidation: any;
  constructor(private imageApi: ImageApi,
              private notifierService: NotifierService,
              private httpClient: HttpClient) { }

  ngOnInit() {
    this.findMainPaper();
    this.formValidation = $('#add_slider').parsley();
  }

  findMainPaper() {
    const filter = {
      'order': 'createdAt DESC',
      'where': {
        'categories': 'paper'
      }
    };
    this.isLoading = true;
    this.imageApi.find(filter)
      .subscribe((sliders: Image[]) => {
        this.isLoading = false;
        this.mainSliders = sliders;
      }, error1 => {
        this.mainSliders = [];
        this.isLoading = false;
      });
  }

  onThumbChange(file) {
    if (file) {
      this.uploadFile(file, 'imgs', 'img');
    }
  }

  uploadFile(file, container: string, type: string) {
    const formData = new FormData();
    formData.append('file', file);
    this.httpClient
      .post(`${LoopBackConfig.getPath()}/${LoopBackConfig.getApiVersion()}/Containers/` + container + `/upload`, formData)
      .subscribe((result: any) => {
        switch (type) {
          case 'img':
            this.currentSlider.thumbnail = result.result.files.file[0].name;
            break;
          default:
            break;
        }
      }, error => {
      });
  }

  createOrEditSlider(slide: Image) {
    if (!slide.id) {
      const createdSlide = Object.assign({}, slide);
      this.formValidation.whenValidate()
        .then(event => {
          $('#formAdd').modal('hide');
          createdSlide.categories = 'paper';
          this.imageApi
            .create(createdSlide)
            .subscribe(() => {
              this.findMainPaper();
              this.notifierService.notify('success', 'Update slider successfully');
            }, error1 => {
            });
        });
    } else {
      this.formValidation.whenValidate()
        .then(() => {
          $('#formAdd').modal('hide');
          this.imageApi.patchAttributes(slide.id, slide)
            .subscribe(() => {
              slide = this.assign({});
              this.findMainPaper();
            }, error1 => {
            });
        });

    }
  }

  deleteSlide(slide: Image) {
    this.imageApi.deleteById(slide.id)
      .subscribe(() => {
        this.notifierService.notify('success', 'Delete slider successfully');
        this.findMainPaper();
      }, error1 => {
        this.notifierService.notify('error', error1);
      });
  }
}
