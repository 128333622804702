import {Component, OnInit, ViewChild} from '@angular/core';
import {Post} from '../../../api/models';
import {PostApi} from '../../../api/services/custom';
import {NotifierService} from 'angular-notifier';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-conditions',
  templateUrl: './conditions.component.html',
  styleUrls: ['./conditions.component.css']
})
export class ConditionsComponent implements OnInit {
  posts: Post[] = [];
  isLoading = false;

  constructor(private postApi: PostApi,
              private notifierService: NotifierService,
              public sanitizer: DomSanitizer) {
  }

  ngOnInit() {
    this.findPost();
  }

  trustHTML(value: any) {
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }

  findPost() {
    const filter = {
      order: 'createdAt DESC',
      where: {
        cataloguage: 'conditions'
      },
      limit: 1
    };
    this.isLoading = true;
    this.postApi.find(filter)
      .subscribe((posts: Post[]) => {
        this.isLoading = false;
        this.posts = posts;
        this.notifierService.notify('success', 'Update posts successfully');
      }, error1 => {
        this.posts = [];
        this.isLoading = false;
        this.notifierService.notify('error', error1);
      });
  }
}
