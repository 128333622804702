import { RouterModule, Routes } from '@angular/router';
import { MainComponent } from './pages/main/main.component';
import { LoginComponent } from './auth/login/login.component';
import { SignupComponent } from './pages/signup/signup.component';
import { ResetPasswordsComponent } from './pages/reset-passwords/reset-passwords.component';
import { ForgotPasswordsComponent } from './pages/forgot-passwords/forgot-passwords.component';
import { NgModule } from '@angular/core';
import { AuthGuard } from './auth/auth.guard';
import { UserComponent } from './pages/main/user/user.component';
import { VideoComponent } from './pages/main/video/video.component';
import { FaqComponent } from './pages/main/faq/faq.component';
import { PartnerComponent } from './pages/main/partner/partner.component';
import { ExamComponent } from './pages/main/exam/exam.component';
import { CardComponent } from './pages/main/card/card.component';
import { CoursesComponent } from './pages/main/courses/courses.component';
import { OrderComponent } from './pages/main/order/order.component';
import { VoucherComponent } from './pages/main/voucher/voucher.component';
import { CommentComponent } from './pages/main/comment/comment.component';
import { CityComponent } from './pages/main/city/city.component';
import { CountyComponent } from './pages/main/county/county.component';
import { SchoolComponent } from './pages/main/school/school.component';
import { CompetitionComponent } from './pages/main/competition/competition.component';
import { HomeComponent } from './pages/main/home/home.component';
import { AboutComponent } from './pages/main/about/about.component';
import { NewsComponent } from './pages/main/news/news.component';
import { CommissionComponent } from './pages/main/commission/commission.component';
import { PaymentComponent } from './pages/main/payment/payment.component';
import { ProductComponent } from './pages/main/product/product.component';
import { EmailComponent } from './pages/main/email/email.component';
import { PaperComponent } from './pages/main/paper/paper.component';
import { CourseCategoryComponent } from './pages/main/course-category/course-category.component';
import { CourseRegisterComponent } from './pages/main/course-register/course-register.component';
import { SupportComponent } from './pages/main/support/support.component';
import { ConditionsComponent } from './pages/main/conditions/conditions.component';
import { IntrosComponent } from './pages/main/intros/intros.component';
import { OrderPartnerComponent } from './pages/main/order-partner/order-partner.component';
import { CommissionPartnerComponent } from './pages/main/commission-partner/commission-partner.component';
import { PaymentPartnerComponent } from './pages/main/payment-partner/payment-partner.component';
import { PaydetailComponent } from './pages/main/paydetail/paydetail.component';
import { EnglishComponent } from './pages/main/english/english.component';
import { GameCardComponent } from './pages/main/game-card/game-card.component';
import { HelpVideoComponent } from './pages/main/help-video/help-video.component';
import { CartComponent } from './pages/main/cart/cart.component';
import { CartPartnerComponent } from './pages/main/cart-partner/cart-partner.component';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  {
    path: 'main',
    component: MainComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        children: [
          { path: 'partner/payment', component: PaymentPartnerComponent },
          { path: 'partner/conditions', component: ConditionsComponent },
          { path: 'partner/intros', component: IntrosComponent },
          { path: 'partner/order', component: OrderPartnerComponent },
          { path: 'partner/cart', component: CartPartnerComponent },
          { path: '', redirectTo: '/main/partner/cart', pathMatch: 'full' }
        ]
      },
      {
        path: '',
        canActivateChild: [AuthGuard],
        children: [
          { path: 'user', component: UserComponent },
          { path: 'video', component: VideoComponent },
          { path: 'faq', component: FaqComponent },
          { path: 'partner/partner', component: PartnerComponent },
          { path: 'partner/payments', component: PaymentComponent },
          { path: 'partner/payments/pay-detail', component: PaydetailComponent },
          { path: 'partner/voucher', component: VoucherComponent },
          { path: 'partner/conditions', component: ConditionsComponent },
          { path: 'partner/intros', component: IntrosComponent },
          { path: 'exam', component: ExamComponent },
          { path: 'card', component: CardComponent },
          { path: 'course', component: CoursesComponent },
          { path: 'course-register', component: CourseRegisterComponent },
          { path: 'course-category', component: CourseCategoryComponent },
          { path: 'product', component: ProductComponent },
          { path: 'order', component: OrderComponent },
          { path: 'comment', component: CommentComponent },
          { path: 'city', component: CityComponent },
          { path: 'county', component: CountyComponent },
          { path: 'school', component: SchoolComponent },
          { path: 'competition', component: CompetitionComponent },
          { path: 'setting/slider', component: HomeComponent },
          { path: 'setting/paper', component: PaperComponent },
          { path: 'setting/infor', component: AboutComponent },
          { path: 'setting/commission', component: CommissionComponent },
          { path: 'news', component: NewsComponent },
          { path: 'email', component: EmailComponent },
          { path: 'support', component: SupportComponent },
          { path: 'english', component: EnglishComponent },
          { path: 'game-card', component: GameCardComponent },
          { path: 'help-video', component: HelpVideoComponent },
          { path: 'cart', component: CartComponent },
          { path: '', redirectTo: '/main/user', pathMatch: 'full' }
        ]
      }
    ]
  },
  { path: 'login', component: LoginComponent },
  { path: 'signup', component: SignupComponent },
  { path: 'reset-passwords', component: ResetPasswordsComponent },
  { path: 'forgot-passwords', component: ForgotPasswordsComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {
}
