import { Component, OnInit } from '@angular/core';
import { AccountApi } from '../../api/services/custom';
import { Router } from '@angular/router';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import 'rxjs-compat/add/observable/of';
import 'rxjs-compat/add/operator/delay';
import { NotifierService } from 'angular-notifier';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-reset-passwords',
  templateUrl: './reset-passwords.component.html',
  styleUrls: ['./reset-passwords.component.css']
})
export class ResetPasswordsComponent implements OnInit {
  accessToken = '';
  newPassword = '';
  confirmationPassword = '';

  constructor(private accountApi: AccountApi,
              private router: Router,
              private notifierService: NotifierService,
              private translateService: TranslateService) {
    this.accessToken = location.href.split('access_token=')[1];
  }

  ngOnInit() {
  }

  resetPassword() {
    if (this.newPassword !== this.confirmationPassword) {
      this.notifierService.notify('error', this.translateService.instant('resetPassword.messages.password.notmatch'));
      return;
    }

    this.accountApi
      .setPassword(this.newPassword, (headers: HttpHeaders) => {
        return headers.append('X-Access-Token', this.accessToken);
      })
      .subscribe(() => {
        Observable.of(true).delay(1000).subscribe(() => this.router.navigate(['/login']));
        this.notifierService.notify('success', this.translateService.instant('resetPassword.messages.reset.success'));
        this.router.navigate(['/login']);
      }, error => {
        this.notifierService.notify('error', error.message);
      });
  }

}
