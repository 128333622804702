import { Component, OnInit } from '@angular/core';
import { Account, Order, Partner, Payment } from '../../../api/models';
import { AccountApi, PartnerApi, PaymentApi } from '../../../api/services/custom';
import { NotifierService } from 'angular-notifier';
import * as moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import { LoopBackAuth } from '../../../api/services/core';
import * as _ from 'lodash';

declare const $;

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit {
  accounts: Account[] = [];


  assign = Object.assign;
  userLoading = false;
  formValidation: any;
  filterAccount = null;
  userLogin = new Account();
  currentUser = new Account();
  cash = 0;
  filterAccounts = new Account();


  constructor(private accountApi: AccountApi,
              private router: Router,
              private route: ActivatedRoute,
              private notifierService: NotifierService,
              private paymentApi: PaymentApi,
              private auth: LoopBackAuth) {
  }

  ngOnInit() {
    this.userLogin = this.auth.getCurrentUserData();
    this.findAccounts();
    this.formValidation = $('#add_user').parsley();
  }

  getRole() {
    return this.userLogin.roles[0].name === 'PARTNER';
  }

  getFilter() {
    if (!this.filterAccount) {
      return {
        'include': [
          {
            relation: 'payment',
            scope: {
              order: 'payedDate DESC'
            }
          },
          {
            relation: 'order',
            scope: {
              where: {
                status: 'DELIVERED'
              },
              order: 'deliveredAt DESC'
            }

          }
        ],
        where: {
          kind: 'PARTNER'
        },
        order: 'createdAt DESC'
      };
    }
    return {
      include: [
        {
          relation: 'payment',
          scope: {
            order: 'payedDate DESC'
          }
        },
        {
          relation: 'order',
          scope: {
            where: {
              status: 'DELIVERED'
            },
            order: 'deliveredAt DESC'
          }

        }
      ],
      where: {
        kind: 'PARTNER',
        id: this.filterAccount
      },
      order: 'createdAt DESC'
    };
  }

  findAccounts() {
    this.userLoading = true;
    this.accountApi
      .find(this.getFilter())
      .subscribe((users: Account[]) => {
        this.notifierService.notify('success', 'Load partner sucessfully');
        this.userLoading = false;
        this.accounts = users;
      }, error1 => {
        this.accounts = [];
        this.userLoading = false;
        this.notifierService.notify('error', error1.toString());
      });
  }


  getTotalTurnover(account: Account) {
    if (account && account.order && account.order.length > 0) {
      return _.sumBy(account.order, 'commission');
    }
    return 0;
  }

  getTotalPayed(account: Account) {
    if (account && account.payment && account.payment.length > 0) {
      return _.sumBy(account.payment, 'payed');
    }
    return 0;
  }

  getBalance(account: Account) {
    const balance = this.getTotalTurnover(account) - this.getTotalPayed(account);
    return balance || 0;
  }

  createPayment(account: Account, cash: number) {
    const payment = new Payment();
    const balance = this.getBalance(account);
    if (cash > balance) {
      alert('Số tiền nhập vào lớn hơn số dư trong tài khoản, xin vui lòng nhập lại!');
      return;
    }
    payment.cardNumber = account.cardNumber;
    payment.auth = account.authBank;
    payment.payed = cash;
    payment.balance = balance - cash;
    payment.payedDate = moment().toDate();
    payment.accountId = account.id;
    this.paymentApi.create(payment)
      .subscribe((pay: Payment) => {
        if (pay && pay.id) {
          $('#form-add').modal('hide');
          this.findAccounts();
          this.notifierService.notify('success', 'Pay successfully');
        } else {
          this.notifierService.notify('error', 'Pay error');
        }
      }, error1 => {
        this.notifierService.notify('error', error1.toString());
      });
  }
}

