import {Component, OnInit} from '@angular/core';
import {Subscribe} from '../../../api/models';
import {NotifierService} from 'angular-notifier';
import {SubscribeApi} from '../../../api/services/custom';

declare const $;

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.css']
})
export class EmailComponent implements OnInit {
  emails: Subscribe[] = [];
  currentEmail = new Subscribe();
  isLoading = false;
  assign = Object.assign;
  formValidation: any;

  constructor(private notifierService: NotifierService,
              private  emailApi: SubscribeApi) {
  }

  ngOnInit() {
    this.findSubscribe();
    this.formValidation = $('#add-email').parsley();
  }

  findSubscribe() {
    this.isLoading = true;
    this.emailApi.find({'order': 'createdAt DESC'})
      .subscribe((emails: Subscribe[]) => {
        this.emails = emails;
        this.isLoading = false;
      }, error1 => {
        this.isLoading = false;
        this.emails = [];

      });
  }


  createOrEditSubscribe(email: Subscribe) {
    if (email.id) {
      this.formValidation.whenValidate()
        .then(event => {
          this.emailApi.patchAttributes(email.id, email)
            .subscribe(() => {
              $('#formAdd').modal('hide');
              this.findSubscribe();
              this.notifierService.notify('success', 'Update Subscribe successfully');
            }, error1 => {
              this.notifierService.notify('error', error1);
            });
        });
    } else {
      this.formValidation.whenValidate()
        .then(event => {
          this.emailApi.create(email)
            .subscribe(() => {
              $('#formAdd').modal('hide');
              this.notifierService.notify('success', 'Successfully');
              this.findSubscribe();
            }, error1 => {
              this.notifierService.notify('error', error1);
            });
        });
    }
  }

  deleteSubscribe(email: Subscribe) {
    this.emailApi.deleteById(email.id)
      .subscribe(() => {
        this.notifierService.notify('success', 'Delete Subscribe successfully');
        this.findSubscribe();
      }, error1 => {
        this.notifierService.notify('error', error1);
      });
  }
}
