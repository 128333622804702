/* tslint:disable */
import {
  City,
  County,
  Ward,
  School
} from '../index';

declare var Object: any;
export interface CompetitionInterface {
  "name": string;
  "description"?: string;
  "status"?: boolean;
  "id"?: number;
  "cityId"?: number;
  "countyId"?: number;
  "wardId"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  "schoolId"?: number;
  city?: City;
  county?: County;
  ward?: Ward;
  school?: School;
}

export class Competition implements CompetitionInterface {
  "name": string;
  "description": string;
  "status": boolean;
  "id": number;
  "cityId": number;
  "countyId": number;
  "wardId": number;
  "createdAt": Date;
  "updatedAt": Date;
  "schoolId": number;
  city: City;
  county: County;
  ward: Ward;
  school: School;
  constructor(data?: CompetitionInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Competition`.
   */
  public static getModelName() {
    return "Competition";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Competition for dynamic purposes.
  **/
  public static factory(data: CompetitionInterface): Competition{
    return new Competition(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Competition',
      plural: 'Competitions',
      path: 'Competitions',
      idName: 'id',
      properties: {
        "name": {
          name: 'name',
          type: 'string'
        },
        "description": {
          name: 'description',
          type: 'string'
        },
        "status": {
          name: 'status',
          type: 'boolean'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "cityId": {
          name: 'cityId',
          type: 'number'
        },
        "countyId": {
          name: 'countyId',
          type: 'number'
        },
        "wardId": {
          name: 'wardId',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "schoolId": {
          name: 'schoolId',
          type: 'number'
        },
      },
      relations: {
        city: {
          name: 'city',
          type: 'City',
          model: 'City',
          relationType: 'belongsTo',
                  keyFrom: 'cityId',
          keyTo: 'id'
        },
        county: {
          name: 'county',
          type: 'County',
          model: 'County',
          relationType: 'belongsTo',
                  keyFrom: 'countyId',
          keyTo: 'id'
        },
        ward: {
          name: 'ward',
          type: 'Ward',
          model: 'Ward',
          relationType: 'belongsTo',
                  keyFrom: 'wardId',
          keyTo: 'id'
        },
        school: {
          name: 'school',
          type: 'School',
          model: 'School',
          relationType: 'belongsTo',
                  keyFrom: 'schoolId',
          keyTo: 'id'
        },
      }
    }
  }
}
