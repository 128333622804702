import { Component, OnInit } from '@angular/core';
import { Account, Order, Voucher } from '../../api/models';
import { AccountApi, FeedbackApi, OrderApi, VoucherApi } from '../../api/services/custom';
import { Router } from '@angular/router';
import { LoopBackAuth } from '../../api/services/core';
import { LoopBackConfig } from '../../api';
import { HttpClient } from '@angular/common/http';
import { NotifierService } from 'angular-notifier';

declare const $;

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {
  authAccount: Account;
  newPassword: '';
  confirmPassword: '';
  oldPassword: '';
  orderCount = 0;
  feeadBackCount = 0;
  voucherCode = '';

  constructor(private router: Router,
              private accountApi: AccountApi,
              private auth: LoopBackAuth,
              private httpClient: HttpClient,
              private notifierService: NotifierService,
              private orderApi: OrderApi,
              private feeadBackApi: FeedbackApi,
              private voucherApi: VoucherApi) {
  }

  ngOnInit(): void {
    $.getScript('assets/js/main.min.js', () => {
      const App = (window as any).App;
      App.init();
    });
    this.authAccount = this.auth.getCurrentUserData();
    this.getVoucher();
    this.countNewFeedBack();
    this.countNewOrder();
  }

  logOut() {
    this.accountApi
      .logout()
      .subscribe(value => {
        this.router.navigate(['/login']);
      }, error => {
        this.router.navigate(['/login']);
      });
  }

  onAvatarChanged(file) {
    if (file) {
      this.uploadImage(file);
    }
  }

  getRole() {
    return this.authAccount.roles[0].name === 'PARTNER';
  }

  getVoucher() {
    const filter = {
      where: {
        accountId: this.authAccount.id
      },
      limit: 1
    };
    this.voucherApi.find(filter)
      .subscribe((vouchers: Voucher[]) => {
        if (vouchers && vouchers.length > 0) {
          this.voucherCode = vouchers[0].code;
        }
      }, error1 => {
        this.voucherCode = null;
      });
  }

  countNewOrder() {
    const filter = {
        status: 'WAITING'
      }
    ;
    this.orderApi.count(filter)
      .subscribe((count: any) => {
        this.orderCount = count.count;
      }, error1 => {
        this.orderCount = 0;
        this.notifierService.notify('error', error1);
      });
  }

  countNewFeedBack() {
    const filter = {
      read: 'unread'
    };
    this.feeadBackApi.count(filter)
      .subscribe((count: any) => {
        this.feeadBackCount = count.count;
      }, error1 => {
        this.notifierService.notify('error', error1);
      });

  }

  uploadImage(file) {
    const formData = new FormData();
    formData.append('file', file);
    this.httpClient
      .post(`${LoopBackConfig.getPath()}/${LoopBackConfig.getApiVersion()}/Containers/users/upload`, formData)
      .subscribe((result: any) => {
        this.authAccount.avatar = result.result.files.file[0].name;
      }, error => {
        this.authAccount.avatar = undefined;
      });
  }

  updateAuthAccount() {
    this.accountApi.patchAttributes(this.authAccount.id, this.authAccount)
      .subscribe((account: Account) => {
          $('#form-add-auth').modal('hide');
          this.notifierService.notify('success', 'Thay đổi thông tin thành công');
          this.auth.setUser(account);
          window.location.reload();
        },
        error1 => {
          $('#form-add-auth').modal('hide');
        });
  }

  changePassword() {
    if (this.newPassword !== this.confirmPassword) {
      this.notifierService.notify('warning', 'Mật khẩu không khớp');
      return;
    }
    this.accountApi.changePassword(this.oldPassword, this.newPassword)
      .subscribe(() => {
        $('#form-changePassword').modal('hide');
        this.notifierService.notify('success', 'Đổi mật khẩu thành công thành công');
      }, error1 => {
        if (error1.code === 'INVALID_PASSWORD') {
          this.notifierService.notify('warning', 'Mật khẩu không đúng');
        } else {
          this.notifierService.notify('error', error1.message || error1);
        }

      });
  }
}
