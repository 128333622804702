import { Component, OnInit } from '@angular/core';
import { City, County } from '../../../api/models';
import { NotifierService } from 'angular-notifier';
import { CityApi, CountyApi } from '../../../api/services/custom';

declare const $;

@Component({
  selector: 'app-county',
  templateUrl: './county.component.html',
  styleUrls: ['./county.component.css']
})
export class CountyComponent implements OnInit {
  counties: County[] = [];
  cities: City[] = [];
  currentCounty = new County();
  isLoading = false;
  assign = Object.assign;
  formValidation: any;
  cityFilter = null;

  constructor(private notifierService: NotifierService,
              private cityApi: CityApi,
              private countyApi: CountyApi) {
  }

  ngOnInit() {
    this.findCity();
    this.findCounty();
    this.formValidation = $('#add-city').parsley();
  }

  findCity() {
    this.isLoading = true;
    this.cityApi.find()
      .subscribe((cities: City[]) => {
        this.cities = cities;
        this.isLoading = false;
      }, error1 => {
        this.isLoading = false;
        this.cities = [];

      });
  }

  getFilter() {
    if (this.cityFilter) {
      return {
        'include': {
          'relation': 'mtp'
        },
        'where': {
          'mtpId': this.cityFilter
        }
      };
    }
    return {
      'include': {
        'relation': 'mtp'
      }
    };
  }

  findCounty() {
    this.isLoading = true;
    this.countyApi.find(this.getFilter())
      .subscribe((counties: County[]) => {
        this.counties = counties;
        this.isLoading = false;
      }, error1 => {
        this.isLoading = false;
        this.counties = [];

      });
  }

  createOrEditCity(county: County) {
    if (county.id) {
      this.formValidation.whenValidate()
        .then(event => {
          county.mtpId = this.cityFilter;
          this.countyApi.patchAttributes(county.id, county)
            .subscribe(() => {
              $('#formAdd').modal('hide');
              this.cityFilter = null;
              this.findCounty();
              this.notifierService.notify('success', 'Update city successfully');
            }, error1 => {
              this.notifierService.notify('error', error1);
            });
        });
    } else {
      this.formValidation.whenValidate()
        .then(event => {
          county.mtpId = this.cityFilter;
          this.countyApi.create(county)
            .subscribe(() => {
              $('#formAdd').modal('hide');
              this.notifierService.notify('success', 'Successfully');
              this.cityFilter = null;
              this.findCounty();
            }, error1 => {
              this.notifierService.notify('error', error1);
            });
        });
    }
  }

  deleteCounty(county: County) {
    this.cityApi.deleteById(county.id)
      .subscribe(() => {
        this.notifierService.notify('success', 'Delete city successfully');
        this.findCounty();
      }, error1 => {
        this.notifierService.notify('error', error1);
      });
  }
}
