import { Component, OnInit, ViewChild } from '@angular/core';
import { Product } from '../../../api/models';
import { LoopBackConfig, ProductApi } from '../../../api';
import { NotifierService } from 'angular-notifier';
import { HttpClient } from '@angular/common/http';

declare const $;

@Component({
  selector: 'app-production',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css']
})

export class ProductComponent implements OnInit {
  isLoading = false;
  assign = Object.assign;
  formValidation: any;
  products: Product[] = [];
  currentProduct = new Product();
  uploadImgFile = '';
  uploadProjectFile = '';
  tag = '';
  name = 'ng2-ckeditor';
  ckeConfig: any;
  mycontent: string;
  log = '';
  @ViewChild('myckeditor') ckeditor: any;

  constructor(private productApi: ProductApi,
              private notifierService: NotifierService,
              private httpClient: HttpClient) {
  }

  ngOnInit() {
    this.formValidation = $('#editCourse').parsley();
    this.findProduct();
    this.ckeConfig = {
      allowedContent: false,
      forcePasteAsPlainText: true,
      enterMode: 2,
      toolbarGroups: [
        { name: 'document', groups: ['mode'] },
        { name: 'clipboard', groups: ['clipboard', 'undo'] },
        { name: 'editing', groups: ['find', 'selection', 'spellchecker'] },
        '/',
        { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
        { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi'] },
        { name: 'links' },
        { name: 'insert' },
        '/',
        { name: 'styles' },
        { name: 'colors' }]
    };
  }

  onChange($event: any): void {
    console.log('onChange');
    // this.log += new Date() + "<br />";
  }

  findProduct() {
    this.isLoading = true;
    const filter = {
      'order': 'createdAt DESC'
    };
    this.productApi.find(filter)
      .subscribe((products: Product[]) => {
        this.products = products;
        this.isLoading = false;
      }, error1 => {
        this.products = [];
        this.isLoading = false;
      });
  }

  onThumbChange(file, container: string, type: string) {
    if (file) {
      this.uploadFile(file, container, type);
    }
  }

  uploadFile(file, container: string, type: string) {
    const formData = new FormData();
    formData.append('file', file);
    this.httpClient
      .post(`${LoopBackConfig.getPath()}/${LoopBackConfig.getApiVersion()}/Containers/` + container + `/upload`, formData)
      .subscribe((result: any) => {
        switch (type) {
          case 'thumb':
            this.uploadImgFile = result.result.files.file[0].name;
            this.currentProduct.thumbnail = this.uploadImgFile;
            break;
          case 'intro1':
            this.uploadImgFile = result.result.files.file[0].name;
            this.currentProduct.introImg1 = this.uploadImgFile;
            break;
          case 'intro2':
            this.uploadImgFile = result.result.files.file[0].name;
            this.currentProduct.introImg2 = this.uploadImgFile;
            break;
          case 'intro3':
            this.uploadImgFile = result.result.files.file[0].name;
            this.currentProduct.introImg3 = this.uploadImgFile;
            break;
          case 'banner':
            this.uploadImgFile = result.result.files.file[0].name;
            this.currentProduct.mainIntroImg = this.uploadImgFile;
            break;
          default:
            break;
        }

      }, error => {
      });
  }

  createOrEditProduct(product: Product) {
    if (product.id) {
      this.formValidation.whenValidate()
        .then(event => {
          this.productApi.patchAttributes(product.id, product)
            .subscribe(() => {
              $('#formAdd').modal('hide');
              this.findProduct();
              this.notifierService.notify('success', 'Update product successfully');
            }, error1 => {
              this.notifierService.notify('error', error1);
            });
        });
    } else {
      this.formValidation.whenValidate()
        .then(event => {
          this.productApi.create(product)
            .subscribe(() => {
              $('#formAdd').modal('hide');
              this.notifierService.notify('success', 'Create product successfully');
              this.findProduct();
            }, error1 => {
              this.notifierService.notify('error', error1);
            });
        });
    }
  }

  deleteProduct(product: Product) {
    this.productApi.deleteById(product.id)
      .subscribe(() => {
        this.notifierService.notify('success', 'Delete product successfully');
        this.findProduct();
      }, error1 => {
        this.notifierService.notify('error', error1);
      });
  }
}
