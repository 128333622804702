import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap';

@Component({
  selector: 'app-page-actions',
  templateUrl: './page-actions.component.html',
  styleUrls: ['./page-actions.component.css']
})
export class PageActionsComponent implements OnInit {
  @Input() isAddVisible: boolean;
  @Input() isDeleteVisible: boolean;
  @Output() addEmitter: EventEmitter<boolean>;
  @Output() deleteEmitter: EventEmitter<any>;

  constructor(private bsModalService: BsModalService) {
    this.addEmitter = new EventEmitter();
    this.deleteEmitter = new EventEmitter();
  }

  ngOnInit() {
  }

  add() {
    this.addEmitter.emit(true);
  }

  delete() {
    this.deleteEmitter.emit(true);
  }

}
