import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { LoginComponent } from './auth/login/login.component';
import { MainComponent } from './pages/main/main.component';
import { FormsModule } from '@angular/forms';
import { SignupComponent } from './pages/signup/signup.component';
import { ResetPasswordsComponent } from './pages/reset-passwords/reset-passwords.component';
import { ForgotPasswordsComponent } from './pages/forgot-passwords/forgot-passwords.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { UserComponent } from './pages/main/user/user.component';
import { DirectivesModule } from './core/directives/directives.module';
import { ModalModule, PaginationModule } from 'ngx-bootstrap';
import { NotifierModule } from 'angular-notifier';
import { PageActionsComponent } from './layout/page-actions/page-actions.component';
import { DeleteDialogComponent } from './core/dialogs/delete-dialog/delete-dialog.component';
import { ImageService } from './core/services/image.service';
import { NgSelectModule } from '@ng-select/ng-select';
import { VideoComponent } from './pages/main/video/video.component';
import { PartnerComponent } from './pages/main/partner/partner.component';
import { FaqComponent } from './pages/main/faq/faq.component';
import { ExamComponent } from './pages/main/exam/exam.component';
import { CardComponent } from './pages/main/card/card.component';
import { CoursesComponent } from './pages/main/courses/courses.component';
import { OrderComponent } from './pages/main/order/order.component';
import { VoucherComponent } from './pages/main/voucher/voucher.component';
import { CommentComponent } from './pages/main/comment/comment.component';
import { SDKBrowserModule } from './api';
import { CityComponent } from './pages/main/city/city.component';
import { CountyComponent } from './pages/main/county/county.component';
import { SchoolComponent } from './pages/main/school/school.component';
import { CompetitionComponent } from './pages/main/competition/competition.component';
import { HomeComponent } from './pages/main/home/home.component';
import { AboutComponent } from './pages/main/about/about.component';
import { NewsComponent } from './pages/main/news/news.component';
import { CommissionComponent } from './pages/main/commission/commission.component';
import { PaymentComponent } from './pages/main/payment/payment.component';
import { ProductComponent } from './pages/main/product/product.component';
import { EmailComponent } from './pages/main/email/email.component';
import { PaperComponent } from './pages/main/paper/paper.component';
import { CKEditorModule } from 'ng2-ckeditor';
import { CourseCategoryComponent } from './pages/main/course-category/course-category.component';
import { CourseRegisterComponent } from './pages/main/course-register/course-register.component';
import { SupportComponent } from './pages/main/support/support.component';
import { ConditionsComponent } from './pages/main/conditions/conditions.component';
import { IntrosComponent } from './pages/main/intros/intros.component';
import { OrderPartnerComponent } from './pages/main/order-partner/order-partner.component';
import { CommissionPartnerComponent } from './pages/main/commission-partner/commission-partner.component';
import { PaymentPartnerComponent } from './pages/main/payment-partner/payment-partner.component';
import { PaydetailComponent } from './pages/main/paydetail/paydetail.component';
import { EnglishComponent } from './pages/main/english/english.component';
import { GameCardComponent } from './pages/main/game-card/game-card.component';
import { HelpVideoComponent } from './pages/main/help-video/help-video.component';
import { CartComponent } from './pages/main/cart/cart.component';
import { CartPartnerComponent } from './pages/main/cart-partner/cart-partner.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerModule } from 'ngx-spinner';
// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    MainComponent,
    SignupComponent,
    ResetPasswordsComponent,
    ForgotPasswordsComponent,
    UserComponent,
    PageActionsComponent,
    DeleteDialogComponent,
    VideoComponent,
    PartnerComponent,
    FaqComponent,
    ExamComponent,
    CardComponent,
    CoursesComponent,
    OrderComponent,
    VoucherComponent,
    CommentComponent,
    CityComponent,
    CountyComponent,
    SchoolComponent,
    CompetitionComponent,
    HomeComponent,
    AboutComponent,
    NewsComponent,
    CommissionComponent,
    PaymentComponent,
    ProductComponent,
    EmailComponent,
    PaperComponent,
    CourseCategoryComponent,
    CourseRegisterComponent,
    SupportComponent,
    ConditionsComponent,
    IntrosComponent,
    OrderPartnerComponent,
    CommissionPartnerComponent,
    PaymentPartnerComponent,
    PaydetailComponent,
    EnglishComponent,
    GameCardComponent,
    HelpVideoComponent,
    CartComponent,
    CartPartnerComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    SDKBrowserModule.forRoot(),
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    DirectivesModule,
    ModalModule.forRoot(),
    PaginationModule.forRoot(),
    NotifierModule.withConfig({
      position: {
        horizontal: {
          position: 'right',
          distance: 12
        },
        vertical: {
          position: 'top',
          distance: 12,
          gap: 10
        }
      },
      behaviour: {
        autoHide: 2000,
        onClick: 'hide',
        onMouseover: 'pauseAutoHide',
        showDismissButton: true,
        stacking: 4
      }
    }),
    HttpClientModule,
    NgSelectModule,
    CKEditorModule,
    NgbModule,
    NgxSpinnerModule,
  ],
  providers: [
    ImageService
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    DeleteDialogComponent]
})

export class AppModule {
}
