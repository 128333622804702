import { Component, OnInit } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { CommissionApi } from '../../../api/services/custom';
import { Commission } from '../../../api/models';

declare const $;

@Component({
  selector: 'app-commission',
  templateUrl: './commission.component.html',
  styleUrls: ['./commission.component.css']
})
export class CommissionComponent implements OnInit {
  commissions: Commission[] = [];
  currentCommission = new Commission();
  isLoading = false;
  assign = Object.assign;
  formValidation: any;
  kindFilter = null;

  constructor(private notifierService: NotifierService,
              private commissionApi: CommissionApi) {
  }

  ngOnInit() {
    this.findCommission();
    this.formValidation = $('#add-commission').parsley();
  }

  findCommission() {
    const filter = {
      'order': 'createdAt DESC',
    };
    this.isLoading = true;
    this.commissionApi.find(filter)
      .subscribe((commissions: Commission[]) => {
        this.commissions = commissions;
        this.isLoading = false;
      }, error1 => {
        this.isLoading = false;
        this.commissions = [];

      });
  }

  filterComission() {
    const filter = {
      'where': {
        'kind': this.kindFilter
      }
    };
    this.isLoading = true;
    this.commissionApi.find(filter)
      .subscribe((commissions: Commission[]) => {
        this.commissions = commissions;
        this.isLoading = false;
      }, error1 => {
        this.isLoading = false;
        this.commissions = [];

      });
  }

  createOrEditCommission(commission: Commission) {
    if (commission.id) {
      this.formValidation.whenValidate()
        .then(event => {
          this.commissionApi.patchAttributes(commission.id, commission)
            .subscribe(() => {
              $('#formAdd').modal('hide');
              this.findCommission();
              this.notifierService.notify('success', 'Update Commission successfully');
            }, error1 => {
              this.notifierService.notify('error', error1);
            });
        });
    } else {
      this.formValidation.whenValidate()
        .then(event => {
          this.commissionApi.create(commission)
            .subscribe(() => {
              $('#formAdd').modal('hide');
              this.notifierService.notify('success', 'Successfully');
              this.findCommission();
            }, error1 => {
              this.notifierService.notify('error', error1);
            });
        });
    }
  }

  deleteCommission(commission: Commission) {
    this.commissionApi.deleteById(commission.id)
      .subscribe(() => {
        this.notifierService.notify('success', 'Delete Commission successfully');
        this.findCommission();
      }, error1 => {
        this.notifierService.notify('error', error1);
      });
  }
}
