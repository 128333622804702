/* tslint:disable */
import {
  Order,
  Voucher
} from '../index';

declare var Object: any;
export interface CartInterface {
  "itemName"?: string;
  "itemId"?: number;
  "quantity"?: number;
  "status"?: boolean;
  "itemKind"?: string;
  "itemPrice"?: number;
  "itemDiscount"?: number;
  "itemCommission"?: number;
  "itemImage"?: string;
  "itemShipIn"?: number;
  "itemShipOut"?: number;
  "id"?: number;
  "orderId"?: number;
  "voucherId"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  order?: Order;
  voucher?: Voucher;
}

export class Cart implements CartInterface {
  "itemName": string;
  "itemId": number;
  "quantity": number;
  "status": boolean;
  "itemKind": string;
  "itemPrice": number;
  "itemDiscount": number;
  "itemCommission": number;
  "itemImage": string;
  "itemShipIn": number;
  "itemShipOut": number;
  "id": number;
  "orderId": number;
  "voucherId": number;
  "createdAt": Date;
  "updatedAt": Date;
  order: Order;
  voucher: Voucher;
  constructor(data?: CartInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Cart`.
   */
  public static getModelName() {
    return "Cart";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Cart for dynamic purposes.
  **/
  public static factory(data: CartInterface): Cart{
    return new Cart(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Cart',
      plural: 'Carts',
      path: 'Carts',
      idName: 'id',
      properties: {
        "itemName": {
          name: 'itemName',
          type: 'string'
        },
        "itemId": {
          name: 'itemId',
          type: 'number'
        },
        "quantity": {
          name: 'quantity',
          type: 'number'
        },
        "status": {
          name: 'status',
          type: 'boolean'
        },
        "itemKind": {
          name: 'itemKind',
          type: 'string'
        },
        "itemPrice": {
          name: 'itemPrice',
          type: 'number'
        },
        "itemDiscount": {
          name: 'itemDiscount',
          type: 'number'
        },
        "itemCommission": {
          name: 'itemCommission',
          type: 'number'
        },
        "itemImage": {
          name: 'itemImage',
          type: 'string'
        },
        "itemShipIn": {
          name: 'itemShipIn',
          type: 'number'
        },
        "itemShipOut": {
          name: 'itemShipOut',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "orderId": {
          name: 'orderId',
          type: 'number'
        },
        "voucherId": {
          name: 'voucherId',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        order: {
          name: 'order',
          type: 'Order',
          model: 'Order',
          relationType: 'belongsTo',
                  keyFrom: 'orderId',
          keyTo: 'id'
        },
        voucher: {
          name: 'voucher',
          type: 'Voucher',
          model: 'Voucher',
          relationType: 'belongsTo',
                  keyFrom: 'voucherId',
          keyTo: 'id'
        },
      }
    }
  }
}
