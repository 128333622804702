/* tslint:disable */
import {
  Account,
  Course
} from '../index';

declare var Object: any;
export interface CardInterface {
  "serial"?: number;
  "key"?: string;
  "days"?: number;
  "status"?: boolean;
  "activedAt"?: Date;
  "activeCount"?: number;
  "kind"?: string;
  "deviceIds"?: any;
  "id"?: number;
  "userOId"?: number;
  "userUId"?: number;
  "courseId"?: number;
  "accountId"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  userO?: Account;
  userU?: Account;
  course?: Course;
}

export class Card implements CardInterface {
  "serial": number;
  "key": string;
  "days": number;
  "status": boolean;
  "activedAt": Date;
  "activeCount": number;
  "kind": string;
  "deviceIds": any;
  "id": number;
  "userOId": number;
  "userUId": number;
  "courseId": number;
  "accountId": number;
  "createdAt": Date;
  "updatedAt": Date;
  userO: Account;
  userU: Account;
  course: Course;
  constructor(data?: CardInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Card`.
   */
  public static getModelName() {
    return "Card";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Card for dynamic purposes.
  **/
  public static factory(data: CardInterface): Card{
    return new Card(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Card',
      plural: 'Cards',
      path: 'Cards',
      idName: 'id',
      properties: {
        "serial": {
          name: 'serial',
          type: 'number'
        },
        "key": {
          name: 'key',
          type: 'string'
        },
        "days": {
          name: 'days',
          type: 'number'
        },
        "status": {
          name: 'status',
          type: 'boolean'
        },
        "activedAt": {
          name: 'activedAt',
          type: 'Date'
        },
        "activeCount": {
          name: 'activeCount',
          type: 'number'
        },
        "kind": {
          name: 'kind',
          type: 'string'
        },
        "deviceIds": {
          name: 'deviceIds',
          type: 'any'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "userOId": {
          name: 'userOId',
          type: 'number'
        },
        "userUId": {
          name: 'userUId',
          type: 'number'
        },
        "courseId": {
          name: 'courseId',
          type: 'number'
        },
        "accountId": {
          name: 'accountId',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        userO: {
          name: 'userO',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'userOId',
          keyTo: 'id'
        },
        userU: {
          name: 'userU',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'userUId',
          keyTo: 'id'
        },
        course: {
          name: 'course',
          type: 'Course',
          model: 'Course',
          relationType: 'belongsTo',
                  keyFrom: 'courseId',
          keyTo: 'id'
        },
      }
    }
  }
}
