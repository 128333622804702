import { Component, OnInit } from '@angular/core';
import { Course, Faq } from '../../../api/models';
import { FaqApi } from '../../../api/services/custom';
import { NotifierService } from 'angular-notifier';

declare const $;

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {
  faqs: Faq[] = [];
  currentFaqs = new Faq();
  courses: Course[] = [];
  isLoading = false;
  assign = Object.assign;
  formValidation: any;

  constructor(private faqApi: FaqApi,
              public notifierService: NotifierService) {
  }


  ngOnInit() {
    this.findFaq();
    this.formValidation = $('#add_user').parsley();

  }

  findFaq() {
    this.isLoading = true;
    this.faqApi.find({'order': 'createdAt DESC'})
      .subscribe((faqs: Faq []) => {
        this.faqs = faqs;
      }, error1 => {
        this.isLoading = false;
      });
  }

  createOrEditFaq(faq: Faq) {
    this.formValidation.whenValidate()
      .then(() => {
        if (faq.id) {
          this.faqApi.patchAttributes(faq.id, faq)
            .subscribe(() => {
              $('#form-add').modal('hide');
              this.notifierService.notify('success', 'Update  faq successfully');
              this.findFaq();
            }, error1 => {
              this.notifierService.notify('error', error1);
            });
        } else {
          this.faqApi.create(faq)
            .subscribe(() => {
              $('#form-add').modal('hide');
              this.notifierService.notify('success', 'Create  faq successfully');
              this.findFaq();
            }, error1 => {
              this.notifierService.notify('error', error1);
            });
        }
      });
  }

  deleteFaq(faq: Faq) {
    this.faqApi.deleteById(faq.id)
      .subscribe(() => {
        this.notifierService.notify('success', 'Delete  faq successfully');
        this.findFaq();
      }, error1 => {
        this.notifierService.notify('error', error1);

      });
  }
}
