/* tslint:disable */
import { Injectable } from '@angular/core';
import { Installation } from '../../models/Installation';
import { User } from '../../models/User';
import { Role } from '../../models/Role';
import { Account } from '../../models/Account';
import { Request } from '../../models/Request';
import { AccountToken } from '../../models/AccountToken';
import { Container } from '../../models/Container';
import { Ask } from '../../models/Ask';
import { Company } from '../../models/Company';
import { Course } from '../../models/Course';
import { Faq } from '../../models/Faq';
import { Post } from '../../models/Post';
import { Product } from '../../models/Product';
import { Submission } from '../../models/Submission';
import { Card } from '../../models/Card';
import { Video } from '../../models/Video';
import { Feedback } from '../../models/Feedback';
import { About } from '../../models/About';
import { Image } from '../../models/Image';
import { Exam } from '../../models/Exam';
import { City } from '../../models/City';
import { County } from '../../models/County';
import { Ward } from '../../models/Ward';
import { Competition } from '../../models/Competition';
import { School } from '../../models/School';
import { Order } from '../../models/Order';
import { Voucher } from '../../models/Voucher';
import { Commission } from '../../models/Commission';
import { Partner } from '../../models/Partner';
import { Subscribe } from '../../models/Subscribe';
import { Title } from '../../models/Title';
import { CourseCategory } from '../../models/CourseCategory';
import { Payment } from '../../models/Payment';
import { NotificationInApp } from '../../models/NotificationInApp';
import { English } from '../../models/English';
import { GameHelp } from '../../models/GameHelp';
import { GameData } from '../../models/GameData';
import { Cart } from '../../models/Cart';

export interface Models { [name: string]: any }

@Injectable()
export class SDKModels {

  private models: Models = {
    Installation: Installation,
    User: User,
    Role: Role,
    Account: Account,
    Request: Request,
    AccountToken: AccountToken,
    Container: Container,
    Ask: Ask,
    Company: Company,
    Course: Course,
    Faq: Faq,
    Post: Post,
    Product: Product,
    Submission: Submission,
    Card: Card,
    Video: Video,
    Feedback: Feedback,
    About: About,
    Image: Image,
    Exam: Exam,
    City: City,
    County: County,
    Ward: Ward,
    Competition: Competition,
    School: School,
    Order: Order,
    Voucher: Voucher,
    Commission: Commission,
    Partner: Partner,
    Subscribe: Subscribe,
    Title: Title,
    CourseCategory: CourseCategory,
    Payment: Payment,
    NotificationInApp: NotificationInApp,
    English: English,
    GameHelp: GameHelp,
    GameData: GameData,
    Cart: Cart,
    
  };

  public get(modelName: string): any {
    return this.models[modelName];
  }

  public getAll(): Models {
    return this.models;
  }

  public getModelNames(): string[] {
    return Object.keys(this.models);
  }
}
