import { Component, OnInit } from '@angular/core';
import { About } from '../../../api/models';
import { AboutApi } from '../../../api/services/custom';
import { LoopBackConfig } from '../../../api';
import { HttpClient } from '@angular/common/http';
import { NotifierService } from 'angular-notifier';

declare const $;

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {
  about = new About();
  isLoading = false;
  formValidation: any;

  constructor(private aboutApi: AboutApi,
              private httpClient: HttpClient,
              private notifierService: NotifierService) {
  }

  ngOnInit() {
    this.findAbout();
    this.formValidation = $('#add_about').parsley();
  }

  findAbout() {
    this.aboutApi.findById('1')
      .subscribe((about: About) => {
        this.about = about;
      }, error1 => {
        this.about = null;
      });
  }

  updateInformation() {
    this.formValidation.whenValidate()
      .then(event => {
        this.isLoading = true;
        this.aboutApi.patchAttributes(this.about.id, this.about)
          .subscribe((about: About) => {
            this.about = about;
            this.isLoading = false;
            this.notifierService.notify('success', 'Update successfully');
            window.location.reload();
          }, error1 => {
            this.isLoading = false;
            this.notifierService.notify('error', error1);
          });
      });
  }

  onThumbChange(file, container: string, title: string) {
    if (file) {
      this.uploadFile(file, container, title);
    }
  }

  uploadFile(file, container: string, type: string) {
    const formData = new FormData();
    formData.append('file', file);
    this.httpClient
      .post(`${LoopBackConfig.getPath()}/${LoopBackConfig.getApiVersion()}/Containers/` + container + `/upload`, formData)
      .subscribe((result: any) => {
        switch (type) {
          case 'title1':
            this.about.thumb1 = result.result.files.file[0].name;
            break;
          case 'title2':
            this.about.thumb2 = result.result.files.file[0].name;
            break;
          case 'title3':
            this.about.thumb3 = result.result.files.file[0].name;
            break;
          default:
            break;
        }

      }, error => {
      });
  }
}
