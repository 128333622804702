import { Component, OnInit } from '@angular/core';
import { Account, City, County, Order, Voucher } from '../../../api/models';
import { NotifierService } from 'angular-notifier';
import { AccountApi, CityApi, CountyApi, OrderApi, VoucherApi } from '../../../api/services/custom';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { LoopBackAuth } from '../../../api/services/core';


declare const $;

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.css']
})
export class OrderComponent implements OnInit {
  isLoading = false;
  assign = Object.assign;
  formValidation: any;
  cities: City[] = [];
  counties: County[] = [];
  filterCounties: County[] = [];
  cityFilter = null;
  countyFilter = null;
  accountFilter = null;
  orders: Order[] = [];
  currentOrder = new Order();
  accounts: Account[] = [];
  userIdFilter: number;
  filterMonth = moment().month();
  filterYear = moment().year();
  userLogin = new Account();
  filterEmail = null;
  currentVoucherFind = new Voucher();

  // moment().set({ 'date': 1, 'hour': 0, 'minute': 0, 'second': 0 });

  constructor(private notifierService: NotifierService,
              private userApi: AccountApi,
              private cityApi: CityApi,
              private countyApi: CountyApi,
              private orderApi: OrderApi,
              private accountApi: AccountApi,
              private route: ActivatedRoute,
              private auth: LoopBackAuth,
              private voucherApi: VoucherApi) {
  }

  ngOnInit() {
    this.userLogin = this.auth.getCurrentUserData();
    if (this.getRole()) {
      this.userIdFilter = this.userLogin.id;
    } else {
      this.formValidation = $('#add_user').parsley();
    }
    this.findOrder();
    this.fintCity();
    this.findPartner();
    this.route.queryParams
      .subscribe(params => {
        if (params) {
          if (this.getRole()) {
            this.userIdFilter = this.userLogin.id;
          } else {
            this.userIdFilter = +params.userOId;
          }
          this.filterMonth = params.filterMonth ? params.filterMonth : moment().month();
          this.filterYear = params.filterYear ? params.filterYear : moment().year();
          if (this.userIdFilter && this.filterMonth && this.filterYear) {
            this.showPartnerOrder();
          }
        }
      }, error1 => {
      });
  }

  getRole() {
    return this.userLogin.roles[0].name === 'PARTNER';
  }

  getFilter() {
    if (this.filterEmail) {
      this.accountFilter = null;
      this.countyFilter = null;
      this.cityFilter = null;
      return {
        'include': [
          {
            'relation': 'city'
          },
          {
            'relation': 'county'
          },
          {
            'relation': 'account',
            'scope': {
              'where': {
                and: [
                  {
                    'kind': 'PARTNER'
                  },
                  {
                    'email': { 'like': `%${this.filterEmail}%` }
                  }
                ]
              }
            }
          }
        ],
        excludeIfEmpty: [
          'account'
        ],
        'order': 'createdAt DESC'
      };
    }
    if (this.accountFilter) {
      this.countyFilter = null;
      this.cityFilter = null;
      return {
        'include': [
          {
            'relation': 'city'
          },
          {
            'relation': 'county'
          },
          {
            'relation': 'account'
          }
        ],
        'where': {
          'accountId': this.accountFilter
        },
        'order': 'createdAt DESC'
      };
    }
    if (this.countyFilter) {
      return {
        'include': [
          {
            'relation': 'city'
          },
          {
            'relation': 'county'
          },
          {
            'relation': 'account'
          }
        ],
        'where': {
          'countyId': this.countyFilter
        },
        'order': 'createdAt DESC'
      };
    }
    if (this.cityFilter) {
      return {
        'include': [
          {
            'relation': 'city'
          },
          {
            'relation': 'county'
          },
          {
            'relation': 'account'
          }
        ],
        'where': {
          'cityId': this.cityFilter
        },
        'order': 'createdAt DESC'
      };
    }
    if (this.filterMonth !== moment().month() && this.filterMonth !== null) {
      this.cityFilter = null;
      this.countyFilter = null;
      this.accountFilter = null;
      const minDate = moment().set({ 'year': this.filterYear, 'month': this.filterMonth, 'date': 1, 'hour': 0, 'minute': 0, 'second': 0 });
      const maxDate = moment(minDate).add('1', 'months');
      return {
        'include': [
          {
            'relation': 'city'
          },
          {
            'relation': 'county'
          },
          {
            'relation': 'account'
          }
        ],
        'where': this.getRole() ? {
          'createdAt': { between: [minDate, maxDate] },
          'id': this.userLogin.id
        } : {
          'createdAt': { between: [minDate, maxDate] }
        },
        'order': 'createdAt DESC'
      };
    }
    return {
      'include': [
        {
          'relation': 'city'
        },
        {
          'relation': 'county'
        },
        {
          'relation': 'account'
        }
      ],
      'order': 'createdAt DESC'
    };
  }

  getPartnerOrderFilter() {
    const minDate = moment().set({ 'year': this.filterYear, 'month': this.filterMonth, 'date': 1, 'hour': 0, 'minute': 0, 'second': 0 });
    const maxDate = moment(minDate).add('1', 'months');
    return {
      'include': [
        {
          'relation': 'city'
        },
        {
          'relation': 'county'
        },
        {
          'relation': 'account'
        }
      ],
      'where': {
        'createdAt': { between: [minDate, maxDate] },
        'accountId': this.userIdFilter
      },
      'order': 'createdAt DESC'
    };

  }

  showPartnerOrder() {
    this.isLoading = true;
    this.orderApi.find(this.getPartnerOrderFilter())
      .subscribe((orders: Order[]) => {
        this.orders = orders;
        this.notifierService.notify('success', 'Load orders successfully');
        this.isLoading = false;

      }, error1 => {
        this.isLoading = false;
        this.orders = [];
        this.notifierService.notify('error', error1);
      });
  }

  resetFilter() {
    this.filterEmail = null;
    this.cityFilter = null;
    this.countyFilter = null;
    this.filterCounties = [];
    this.filterMonth = moment().month();
    this.filterYear = moment().year();
    this.findOrder();
  }

  findOrder() {
    const filter = this.getFilter();
    this.isLoading = true;
    this.orderApi.find(filter)
      .subscribe((orders: Order[]) => {
        this.orders = orders;
        this.notifierService.notify('success', 'Load orders successfully');
        this.isLoading = false;

      }, error1 => {
        this.isLoading = false;
        this.orders = [];
        this.notifierService.notify('error', error1);
      });
  }

  findPartner() {
    const filter = {
      'where': {
        'kind': 'PARTNER'
      }
    };
    this.accountApi.find(filter)
      .subscribe((accounts: Account[]) => {
        this.accounts = accounts;
      }, error1 => {
      });
  }

  filterCounty() {
    const filter = {
      'where': {
        'mtpId': this.cityFilter
      }
    };
    this.countyApi.find(filter)
      .subscribe((counties: County[]) => {
        this.filterCounties = counties;
      }, error1 => {
        this.filterCounties = [];
      });
  }

  findCounty() {
    if (this.currentOrder.cityId) {
      const filter = {
        'where': {
          'mtpId': this.currentOrder.cityId
        }
      };
      this.countyApi.find(filter)
        .subscribe((counties: County[]) => {
          this.counties = counties;
        }, error1 => {
          this.counties = [];
        });
    }
  }

  fintCity() {
    this.cityApi.find()
      .subscribe((cities: City[]) => {
        this.cities = cities;
        this.findCounty();
      }, error1 => {
        this.cities = [];
      });
  }

  checkVoucher(code: string) {
    this.isLoading = true;
    const filter = {
      include: ['account'],
      where: {
        code: code
      }
    };
    this.voucherApi.findOne(filter)
      .subscribe((voucher: Voucher) => {
        this.isLoading = false;
        if (voucher && voucher.id && voucher.account && voucher.account.email) {
          this.currentVoucherFind = voucher;
        } else {
          this.notifierService.notify('error', 'Mã giảm giá không đúng');
        }
      }, error => {
        this.isLoading = false;
        this.currentVoucherFind = new Voucher();
        this.notifierService.notify('error', 'Mã giảm giá không đúng');
      });
  }

  createOrEditOrder(order: Order) {
    if (this.getRole()) {
      return;
    }
    if (order.id) {
      this.formValidation.whenValidate()
        .then(event => {
          if (order.status === 'DELIVERED') {
            if (!order.deliveredAt) {
              order.deliveredAt = moment().toDate();
            }
          }
          this.orderApi.patchAttributes(order.id, order)
            .subscribe(() => {
              $('#formAdd').modal('hide');
              this.findOrder();
              this.notifierService.notify('success', 'Update order successfully');
            }, error1 => {
              this.notifierService.notify('error', error1);
            });
        });
    } else {
      this.formValidation.whenValidate()
        .then(event => {
          if (order.status === 'DELIVERED') {
            if (!order.deliveredAt) {
              order.deliveredAt = moment().toDate();
            }
          }
          order.commissionStatus = false;
          this.orderApi.create(order)
            .subscribe(() => {
              this.notifierService.notify('success', 'Create order successfully');
              this.findOrder();
              $('#formAdd').modal('hide');
            }, error1 => {
              this.notifierService.notify('error', error1);
            });
        });
    }
  }


  getOrderStatus(status: string) {
    if (status === 'RESOLVED') {
      return 'Chốt đơn';
    } else if (status === 'CANCEL') {
      return 'Hủy đơn';
    } else if (status === 'DELIVERING') {
      return 'Đang giao';
    } else if (status === 'DELIVERED') {
      return 'Đã giao';
    } else if (status === 'RETURN') {
      return 'Trả lại hàng';
    } else {
      return 'Chờ xử lý';
    }

  }

  deleteOder(order: Order) {
    if (this.getRole()) {
      return;
    }
    this.orderApi.deleteById(order.id)
      .subscribe(() => {
        this.notifierService.notify('success', 'Delete order successfully');
        this.findOrder();
      }, error1 => {
        this.notifierService.notify('error', error1);
      });
  }
}
