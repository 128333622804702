export enum JobTypes {
  Offre = 'OFFRE',
  Demande = 'DEMANDE'
}

export enum MessageStatus {
  NEW = 'NEW'
}

export enum ContainerId {
  AVATAR = 'avatars',
  CATEGORY = 'categories',
  COMPTE = 'competences'
}

export enum Roles {
  SUPERADMIN = 'SUPERADMIN',
  ADMIN = 'ADMIN',
  MODERATOR = 'MODERATOR'
}
