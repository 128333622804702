import { Component, OnInit } from '@angular/core';
import { City } from '../../../api/models';
import { CityApi } from '../../../api/services/custom';
import { NotifierService } from 'angular-notifier';

declare const $;

@Component({
  selector: 'app-city',
  templateUrl: './city.component.html',
  styleUrls: ['./city.component.css']
})
export class CityComponent implements OnInit {
  cities: City[] = [];
  currentCity = new City();
  isLoading = false;
  assign = Object.assign;
  formValidation: any;

  constructor(private notifierService: NotifierService,
              private  cityApi: CityApi) {
  }

  ngOnInit() {
    this.findCity();
    this.formValidation = $('#add-city').parsley();
  }

  findCity() {
    this.isLoading = true;
    this.cityApi.find()
      .subscribe((cities: City[]) => {
        this.cities = cities;
        this.isLoading = false;
      }, error1 => {
        this.isLoading = false;
        this.cities = [];

      });
  }

  createOrEditCity(city: City) {
    if (city.id) {
      this.formValidation.whenValidate()
        .then(event => {
          this.cityApi.patchAttributes(city.id, city)
            .subscribe(() => {
              $('#formAdd').modal('hide');
              this.findCity();
              this.notifierService.notify('success', 'Update city successfully');
            }, error1 => {
              this.notifierService.notify('error', error1);
            });
        });
    } else {
      this.formValidation.whenValidate()
        .then(event => {
          this.cityApi.create(city)
            .subscribe(() => {
              $('#formAdd').modal('hide');
              this.notifierService.notify('success', 'Successfully');
              this.findCity();
            }, error1 => {
              this.notifierService.notify('error', error1);
            });
        });
    }
  }

  deleteCity(city: City) {
    this.cityApi.deleteById(city.id)
      .subscribe(() => {
        this.notifierService.notify('success', 'Delete city successfully');
        this.findCity();
      }, error1 => {
        this.notifierService.notify('error', error1);
      });
  }
}
