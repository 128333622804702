/* tslint:disable */
import {
  Account,
  Course,
  Product
} from '../index';

declare var Object: any;
export interface NotificationInAppInterface {
  "title"?: string;
  "message"?: string;
  "isRead"?: boolean;
  "id"?: number;
  "accountId"?: number;
  "courseId"?: number;
  "productId"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  account?: Account;
  course?: Course;
  product?: Product;
}

export class NotificationInApp implements NotificationInAppInterface {
  "title": string;
  "message": string;
  "isRead": boolean;
  "id": number;
  "accountId": number;
  "courseId": number;
  "productId": number;
  "createdAt": Date;
  "updatedAt": Date;
  account: Account;
  course: Course;
  product: Product;
  constructor(data?: NotificationInAppInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `NotificationInApp`.
   */
  public static getModelName() {
    return "NotificationInApp";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of NotificationInApp for dynamic purposes.
  **/
  public static factory(data: NotificationInAppInterface): NotificationInApp{
    return new NotificationInApp(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'NotificationInApp',
      plural: 'NotificationInApps',
      path: 'NotificationInApps',
      idName: 'id',
      properties: {
        "title": {
          name: 'title',
          type: 'string'
        },
        "message": {
          name: 'message',
          type: 'string'
        },
        "isRead": {
          name: 'isRead',
          type: 'boolean',
          default: false
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "accountId": {
          name: 'accountId',
          type: 'number'
        },
        "courseId": {
          name: 'courseId',
          type: 'number'
        },
        "productId": {
          name: 'productId',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        account: {
          name: 'account',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'accountId',
          keyTo: 'id'
        },
        course: {
          name: 'course',
          type: 'Course',
          model: 'Course',
          relationType: 'belongsTo',
                  keyFrom: 'courseId',
          keyTo: 'id'
        },
        product: {
          name: 'product',
          type: 'Product',
          model: 'Product',
          relationType: 'belongsTo',
                  keyFrom: 'productId',
          keyTo: 'id'
        },
      }
    }
  }
}
