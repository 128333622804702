import { Component, OnInit } from '@angular/core';
import { Account, Partner, Payment } from '../../../api/models';
import * as _ from 'lodash';
import { AccountApi, PartnerApi, PaymentApi } from '../../../api/services/custom';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { LoopBackAuth } from '../../../api/services/core';
import * as moment from 'moment';

declare const $;

@Component({
  selector: 'app-paydetail',
  templateUrl: './paydetail.component.html',
  styleUrls: ['./paydetail.component.css']
})
export class PaydetailComponent implements OnInit {
  account = new Account();
  assign = Object.assign;
  isLoading = false;
  currentPay = new Payment();
  userIdFilter = null;
  formValidation: any;
  cash = 0;

  constructor(private accountApi: AccountApi,
              private router: Router,
              private route: ActivatedRoute,
              private notifierService: NotifierService,
              private auth: LoopBackAuth,
              private paymentApi: PaymentApi) {
  }

  ngOnInit() {
    this.formValidation = $('#add_user').parsley();
    this.route.queryParams
      .subscribe(params => {
        if (params) {
          this.userIdFilter = +params.userOId;
          if (this.userIdFilter) {
            this.findAccountInfo();
          }
        }
      }, error1 => {
      });
  }

  findAccountInfo() {
    const filter = {
      'include': [
        {
          relation: 'payment',
          scope: {
            order: 'payedDate DESC'
          }
        }
      ]
    };
    this.accountApi.findById(this.userIdFilter, filter)
      .subscribe((account: Account) => {
        this.account = account;
      }, error1 => {
        this.account = null;
      });
  }

  createOrEditPayment(payment: Payment, cash: number) {
    if (payment && payment.id) {
      this.paymentApi.patchAttributes(payment.id, payment)
        .subscribe((pay: Payment) => {
          if (pay && pay.id) {
            $('#form-add').modal('hide');
            this.findAccountInfo();
            this.notifierService.notify('success', 'Pay successfully');
          } else {
            this.notifierService.notify('error', 'Pay error');
          }

        }, error1 => {
          this.notifierService.notify('error', error1.toString());
        });
    } else {
      alert('Quá trình thanh toán bị lỗi, xin vui lòng thử lại');
    }

  }
}

