/* tslint:disable */

declare var Object: any;
export interface ProductInterface {
  "pageDes"?: string;
  "pageTitle"?: string;
  "pageKeyWords"?: string;
  "canoLink"?: string;
  "name"?: string;
  "url"?: string;
  "thumbnail"?: string;
  "feature"?: string;
  "description"?: string;
  "kind"?: string;
  "price"?: number;
  "discount"?: number;
  "mainIntroImg"?: string;
  "introImg1"?: string;
  "introImg2"?: string;
  "introImg3"?: string;
  "position"?: number;
  "row"?: number;
  "commission"?: number;
  "shipIn"?: number;
  "shipOut"?: number;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
}

export class Product implements ProductInterface {
  "pageDes": string;
  "pageTitle": string;
  "pageKeyWords": string;
  "canoLink": string;
  "name": string;
  "url": string;
  "thumbnail": string;
  "feature": string;
  "description": string;
  "kind": string;
  "price": number;
  "discount": number;
  "mainIntroImg": string;
  "introImg1": string;
  "introImg2": string;
  "introImg3": string;
  "position": number;
  "row": number;
  "commission": number;
  "shipIn": number;
  "shipOut": number;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  constructor(data?: ProductInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Product`.
   */
  public static getModelName() {
    return "Product";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Product for dynamic purposes.
  **/
  public static factory(data: ProductInterface): Product{
    return new Product(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Product',
      plural: 'Products',
      path: 'Products',
      idName: 'id',
      properties: {
        "pageDes": {
          name: 'pageDes',
          type: 'string'
        },
        "pageTitle": {
          name: 'pageTitle',
          type: 'string'
        },
        "pageKeyWords": {
          name: 'pageKeyWords',
          type: 'string'
        },
        "canoLink": {
          name: 'canoLink',
          type: 'string'
        },
        "name": {
          name: 'name',
          type: 'string'
        },
        "url": {
          name: 'url',
          type: 'string'
        },
        "thumbnail": {
          name: 'thumbnail',
          type: 'string'
        },
        "feature": {
          name: 'feature',
          type: 'string'
        },
        "description": {
          name: 'description',
          type: 'string'
        },
        "kind": {
          name: 'kind',
          type: 'string'
        },
        "price": {
          name: 'price',
          type: 'number'
        },
        "discount": {
          name: 'discount',
          type: 'number'
        },
        "mainIntroImg": {
          name: 'mainIntroImg',
          type: 'string'
        },
        "introImg1": {
          name: 'introImg1',
          type: 'string'
        },
        "introImg2": {
          name: 'introImg2',
          type: 'string'
        },
        "introImg3": {
          name: 'introImg3',
          type: 'string'
        },
        "position": {
          name: 'position',
          type: 'number'
        },
        "row": {
          name: 'row',
          type: 'number'
        },
        "commission": {
          name: 'commission',
          type: 'number'
        },
        "shipIn": {
          name: 'shipIn',
          type: 'number'
        },
        "shipOut": {
          name: 'shipOut',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
      }
    }
  }
}
