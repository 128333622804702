import { Component, OnInit } from '@angular/core';
import { Account, Cart, Voucher } from '../../../api/models';
import { NotifierService } from 'angular-notifier';
import { AccountApi, CartApi, OrderApi } from '../../../api/services/custom';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { LoopBackAuth } from '../../../api/services/core';


declare const $;

@Component({
  selector: 'app-cart',
  templateUrl: './cart-partner.component.html',
  styleUrls: ['./cart-partner.component.css']
})
export class CartPartnerComponent implements OnInit {

  isLoading = false;
  assign = Object.assign;
  formValidation: any;
  currentCart = new Cart();
  accounts: Account[] = [];
  userIdFilter: number;
  filterMonth = moment().month();
  filterYear = moment().year();
  userLogin = new Account();
  filterOrderId = null;
  carts: Cart[] = [];
  filterVoucherId = null;
  totalCommission = 0;

  // moment().set({ 'date': 1, 'hour': 0, 'minute': 0, 'second': 0 });

  constructor(private notifierService: NotifierService,
              private orderApi: OrderApi,
              private cartApi: CartApi,
              private accountApi: AccountApi,
              private auth: LoopBackAuth) {
  }

  ngOnInit() {
    this.userLogin = this.auth.getCurrentUserData();
    if (this.userLogin && this.userLogin.voucher) {
      this.filterVoucherId = this.userLogin.voucher.id;
      if (this.filterVoucherId) {
        this.findCart();
      } else {
        this.notifierService.notify('error', 'Mã giảm giá chưa có hoặc chưa được cập nhập, xin vui lòng đăng nhập lại');
      }
    } else {
      this.accountApi.getVoucher(this.userLogin.id)
        .subscribe((voucher: Voucher) => {
          if (voucher && voucher.id) {
            this.filterVoucherId = voucher.id;
            this.findCart();
          } else {
            this.filterVoucherId = null;
            this.notifierService.notify('error', 'Mã giảm giá chưa có hoặc chưa được cập nhập, xin vui lòng đăng nhập lại');
          }
        }, error => {
          this.filterVoucherId = null;
          this.notifierService.notify('error', 'Mã giảm giá chưa có hoặc chưa được cập nhập, xin vui lòng đăng nhập lại');
        });
    }
  }

  getRole() {
    return this.userLogin.roles[0].name === 'PARTNER';
  }

  getFilter() {
    if (this.filterMonth !== moment().month() && this.filterMonth !== null) {
      const minDate = moment().set({ 'year': this.filterYear, 'month': this.filterMonth, 'date': 1, 'hour': 0, 'minute': 0, 'second': 0 });
      const maxDate = moment(minDate).add('1', 'months');
      return {
        include: [
          {
            relation: 'order',
            scope: {
              include: {
                relation: 'account'
              },
              where: {
                status: 'DELIVERED',
                deliveredAt: { between: [minDate, maxDate] }
              }
            }
          }
        ],
        where: {
          voucherId: this.filterVoucherId
        },
        order: 'updatedAt DESC'
      };
    } else {
      return {
        include: [
          {
            relation: 'order',
            scope: {
              include: {
                relation: 'account'
              },
              where: {
                status: 'DELIVERED'
              }
            }
          }
        ],
        where: {
          voucherId: this.filterVoucherId
        },
        order: 'updatedAt DESC'
      };
    }
  }

  getPrice(cart: Cart) {
    if (cart && cart.voucherId) {
      return cart.itemPrice * (1 - cart.itemDiscount / 100);
    } else {
      return cart.itemPrice;
    }
  }

  getDisCount(cart: Cart) {
    return (cart && cart.voucherId) ? cart.itemDiscount : 0;
  }

  getCommission(cart: Cart) {
    const total = this.getTurnOver(cart);
    return total * cart.itemCommission / 100;
  }

  getTurnOver(cart: Cart) {
    let price = 0;
    if (cart && cart.voucherId) {
      price = this.getPrice(cart);
    } else {
      return 0;
    }
    return price * cart.quantity;
  }

  getTotalCommission() {
    let total = 0;
    this.carts.forEach(it => {
      const com = this.getCommission(it);
      total += com;
    });
    return total;
  }

  findCart() {
    const filter = this.getFilter();
    this.isLoading = true;
    this.cartApi.find(filter)
      .subscribe((items: Cart[]) => {
        this.carts = items.filter((it: Cart) => it.order && it.order.status === 'DELIVERED');
        this.notifierService.notify('success', 'Load orders successfully');
        this.isLoading = false;

      }, error1 => {
        this.isLoading = false;
        this.carts = [];
        this.notifierService.notify('error', error1);
      });
  }

  resetFilter() {
    this.filterMonth = moment().month();
    this.filterYear = moment().year();
    this.filterOrderId = null;
    this.findCart();
  }

  deleteCart(cart: Cart) {
    if (this.getRole()) {
      return;
    }
    this.cartApi.deleteById(cart.id)
      .subscribe(() => {
        this.notifierService.notify('success', 'Delete product successfully');
        this.findCart();
      }, error1 => {
        this.notifierService.notify('error', error1);
      });
  }
}
