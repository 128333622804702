/* tslint:disable */
import {
  Card,
  Video,
  Feedback,
  CourseCategory
} from '../index';

declare var Object: any;
export interface CourseInterface {
  "name"?: string;
  "code"?: string;
  "description"?: string;
  "thumbnail"?: string;
  "homeImg"?: string;
  "url"?: string;
  "price"?: number;
  "discount"?: string;
  "kind"?: string;
  "teacher"?: string;
  "time"?: string;
  "minAge"?: number;
  "document"?: string;
  "dependency"?: string;
  "hashtag1"?: string;
  "hashtag2"?: string;
  "hashtag3"?: string;
  "active"?: boolean;
  "position"?: number;
  "row"?: number;
  "pageDes"?: string;
  "pageTitle"?: string;
  "pageKeyWords"?: string;
  "canoLink"?: string;
  "expireAt"?: Date;
  "commission"?: number;
  "shipIn"?: number;
  "shipOut"?: number;
  "id"?: number;
  "accountId"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  "videoId"?: number;
  "courseCategoryId"?: number;
  "categoryId"?: number;
  cards?: Card[];
  videos?: Video[];
  feedbacks?: Feedback[];
  category?: CourseCategory;
}

export class Course implements CourseInterface {
  "name": string;
  "code": string;
  "description": string;
  "thumbnail": string;
  "homeImg": string;
  "url": string;
  "price": number;
  "discount": string;
  "kind": string;
  "teacher": string;
  "time": string;
  "minAge": number;
  "document": string;
  "dependency": string;
  "hashtag1": string;
  "hashtag2": string;
  "hashtag3": string;
  "active": boolean;
  "position": number;
  "row": number;
  "pageDes": string;
  "pageTitle": string;
  "pageKeyWords": string;
  "canoLink": string;
  "expireAt": Date;
  "commission": number;
  "shipIn": number;
  "shipOut": number;
  "id": number;
  "accountId": number;
  "createdAt": Date;
  "updatedAt": Date;
  "videoId": number;
  "courseCategoryId": number;
  "categoryId": number;
  cards: Card[];
  videos: Video[];
  feedbacks: Feedback[];
  category: CourseCategory;
  constructor(data?: CourseInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Course`.
   */
  public static getModelName() {
    return "Course";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Course for dynamic purposes.
  **/
  public static factory(data: CourseInterface): Course{
    return new Course(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Course',
      plural: 'Courses',
      path: 'Courses',
      idName: 'id',
      properties: {
        "name": {
          name: 'name',
          type: 'string'
        },
        "code": {
          name: 'code',
          type: 'string'
        },
        "description": {
          name: 'description',
          type: 'string'
        },
        "thumbnail": {
          name: 'thumbnail',
          type: 'string'
        },
        "homeImg": {
          name: 'homeImg',
          type: 'string'
        },
        "url": {
          name: 'url',
          type: 'string'
        },
        "price": {
          name: 'price',
          type: 'number'
        },
        "discount": {
          name: 'discount',
          type: 'string'
        },
        "kind": {
          name: 'kind',
          type: 'string'
        },
        "teacher": {
          name: 'teacher',
          type: 'string'
        },
        "time": {
          name: 'time',
          type: 'string'
        },
        "minAge": {
          name: 'minAge',
          type: 'number'
        },
        "document": {
          name: 'document',
          type: 'string'
        },
        "dependency": {
          name: 'dependency',
          type: 'string'
        },
        "hashtag1": {
          name: 'hashtag1',
          type: 'string'
        },
        "hashtag2": {
          name: 'hashtag2',
          type: 'string'
        },
        "hashtag3": {
          name: 'hashtag3',
          type: 'string'
        },
        "active": {
          name: 'active',
          type: 'boolean'
        },
        "position": {
          name: 'position',
          type: 'number'
        },
        "row": {
          name: 'row',
          type: 'number'
        },
        "pageDes": {
          name: 'pageDes',
          type: 'string'
        },
        "pageTitle": {
          name: 'pageTitle',
          type: 'string'
        },
        "pageKeyWords": {
          name: 'pageKeyWords',
          type: 'string'
        },
        "canoLink": {
          name: 'canoLink',
          type: 'string'
        },
        "expireAt": {
          name: 'expireAt',
          type: 'Date'
        },
        "commission": {
          name: 'commission',
          type: 'number'
        },
        "shipIn": {
          name: 'shipIn',
          type: 'number'
        },
        "shipOut": {
          name: 'shipOut',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "accountId": {
          name: 'accountId',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "videoId": {
          name: 'videoId',
          type: 'number'
        },
        "courseCategoryId": {
          name: 'courseCategoryId',
          type: 'number'
        },
        "categoryId": {
          name: 'categoryId',
          type: 'number'
        },
      },
      relations: {
        cards: {
          name: 'cards',
          type: 'Card[]',
          model: 'Card',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'courseId'
        },
        videos: {
          name: 'videos',
          type: 'Video[]',
          model: 'Video',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'courseId'
        },
        feedbacks: {
          name: 'feedbacks',
          type: 'Feedback[]',
          model: 'Feedback',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'courseId'
        },
        category: {
          name: 'category',
          type: 'CourseCategory',
          model: 'CourseCategory',
          relationType: 'belongsTo',
                  keyFrom: 'categoryId',
          keyTo: 'id'
        },
      }
    }
  }
}
