import { Injectable } from '@angular/core';
import { DEFAULT_AVATAR } from '../constants';
import { LoopBackConfig } from '../../api';

@Injectable()
export class ImageService {
  constructor() {
  }

  getImageUrl(containerId: string, imageName: string): string {
    if (imageName) {
      return `${LoopBackConfig.getPath()}/${LoopBackConfig.getApiVersion()}/Containers/${containerId}/download/${imageName}`;
    } else {
      return DEFAULT_AVATAR;
    }
  }
}
