/* tslint:disable */

declare var Object: any;
export interface GameDataInterface {
  "gameAddress"?: string;
  "currentLevel"?: number;
  "id"?: number;
  "accountId"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
}

export class GameData implements GameDataInterface {
  "gameAddress": string;
  "currentLevel": number;
  "id": number;
  "accountId": number;
  "createdAt": Date;
  "updatedAt": Date;
  constructor(data?: GameDataInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `GameData`.
   */
  public static getModelName() {
    return "GameData";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of GameData for dynamic purposes.
  **/
  public static factory(data: GameDataInterface): GameData{
    return new GameData(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'GameData',
      plural: 'GameData',
      path: 'GameData',
      idName: 'id',
      properties: {
        "gameAddress": {
          name: 'gameAddress',
          type: 'string'
        },
        "currentLevel": {
          name: 'currentLevel',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "accountId": {
          name: 'accountId',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
      }
    }
  }
}
