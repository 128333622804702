/* tslint:disable */
import {
  Course,
  Feedback
} from '../index';

declare var Object: any;
export interface VideoInterface {
  "title": string;
  "description"?: string;
  "kind": string;
  "domain": string;
  "link": string;
  "lock": boolean;
  "position"?: number;
  "id"?: number;
  "courseId"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  courses?: Course[];
  comments?: Feedback[];
}

export class Video implements VideoInterface {
  "title": string;
  "description": string;
  "kind": string;
  "domain": string;
  "link": string;
  "lock": boolean;
  "position": number;
  "id": number;
  "courseId": number;
  "createdAt": Date;
  "updatedAt": Date;
  courses: Course[];
  comments: Feedback[];
  constructor(data?: VideoInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Video`.
   */
  public static getModelName() {
    return "Video";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Video for dynamic purposes.
  **/
  public static factory(data: VideoInterface): Video{
    return new Video(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Video',
      plural: 'Videos',
      path: 'Videos',
      idName: 'id',
      properties: {
        "title": {
          name: 'title',
          type: 'string'
        },
        "description": {
          name: 'description',
          type: 'string'
        },
        "kind": {
          name: 'kind',
          type: 'string'
        },
        "domain": {
          name: 'domain',
          type: 'string'
        },
        "link": {
          name: 'link',
          type: 'string'
        },
        "lock": {
          name: 'lock',
          type: 'boolean'
        },
        "position": {
          name: 'position',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "courseId": {
          name: 'courseId',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        courses: {
          name: 'courses',
          type: 'Course[]',
          model: 'Course',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'videoId'
        },
        comments: {
          name: 'comments',
          type: 'Feedback[]',
          model: 'Feedback',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'videoId'
        },
      }
    }
  }
}
