import {Component, OnInit} from '@angular/core';
import {CourseApi, VideoApi} from '../../../api/services/custom';
import {Course, Video} from '../../../api/models';
import {NotifierService} from 'angular-notifier';
import { NgxSpinnerService } from 'ngx-spinner';

declare const $;

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.css']
})
export class VideoComponent implements OnInit {
  videos: Video[] = [];
  currentVideos = new Video();
  courses: Course[] = [];
  videoLoading = false;
  assign = Object.assign;
  formValidation: any;
  courseIdFilter: any;
  videoNameFilter = '';

  constructor(private videoApi: VideoApi,
              private courseApi: CourseApi,
              private notifierService: NotifierService,
              private spinner: NgxSpinnerService) {
  }

  ngOnInit() {
    this.findVideo();
    this.findCourse();
    this.formValidation = $('#add_user').parsley();
  }

  getFilter() {
    let filter;
    if (this.courseIdFilter > 0) {
      filter = {
        where: {
          courseId: this.courseIdFilter
        },
        include:
          {
            'relation': 'courses'
          },
        order: 'createdAt DESC'

      };
    } else if (this.videoNameFilter && this.videoNameFilter.length > 0) {
      filter = {
        where: {
          'description': {'like': `%${this.videoNameFilter}%`}
        },
        include:
          {
            'relation': 'courses'
          },
        order: 'createdAt DESC'

      };
    } else {
      filter = {
        'include':
          {
            'relation': 'courses'
          },
        'order': 'createdAt DESC'

      };
    }

    return filter;
  }

  findVideo() {
    this.videoLoading = true;
    this.spinner.show().then().catch();
    this.videoApi.find(this.getFilter())
      .subscribe((videoFinded: Video []) => {
        this.spinner.hide().then().catch();
        this.videos = videoFinded;
        this.notifierService.notify('success', 'Load videos successfully');

      }, error1 => {
        this.spinner.hide().then().catch();
        this.videoLoading = false;
        this.notifierService.notify('error', error1);

      });
  }

  resetFilter() {
    this.courseIdFilter = undefined;
    this.videoNameFilter = '';
    this.findVideo();
  }

  findCourse() {
    const filter = {
      'order': 'createdAt DESC',
      'where': {
        'accountId': null
      }
    };
    this.courseApi.find(filter)
      .subscribe((courseFinded: Course[]) => {
        this.courses = courseFinded;
      }, error1 => {

      });
  }

  createOrEditVideo(video: Video) {
    if (video.id) {
      this.formValidation.whenValidate()
        .then(event => {
          this.videoApi.patchAttributes(video.id, video)
            .subscribe(() => {
              $('#form-add').modal('hide');
              this.findVideo();
              this.notifierService.notify('success', 'Update video successfully');
            }, error1 => {
              this.notifierService.notify('error', error1);
            });
        });
    } else {
      video.kind = 'Course';
      this.formValidation.whenValidate()
        .then(event => {
          this.videoApi.create(video)
            .subscribe(() => {
              this.notifierService.notify('success', 'Create video successfully');
              this.findVideo();
              $('#form-add').modal('hide');
            }, error1 => {
              this.notifierService.notify('error', error1);
            });
        });
    }
  }

  deleteVideo(video: Video) {
    this.videoApi.deleteById(video.id)
      .subscribe(() => {
        this.notifierService.notify('success', 'Delete video successfully');
        this.findVideo();
      }, error1 => {
        this.notifierService.notify('error', error1);
      });
  }
}
