import { Component, OnInit } from '@angular/core';
import { Account, Cart, Commission, Faq, Order, Partner } from '../../../api/models';
import { AccountApi, CommissionApi, OrderApi, PartnerApi } from '../../../api/services/custom';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import * as moment from 'moment';
import { LoopBackAuth } from '../../../api/services/core';
import { LoopBackConfig } from '../../../api';
import { HttpClient } from '@angular/common/http';
import * as _ from 'lodash';
import { flatMap } from 'rxjs/operators';
import { of } from 'rxjs';

declare const $;

@Component({
  selector: 'app-partner',
  templateUrl: './partner.component.html',
  styleUrls: ['./partner.component.css']
})
export class PartnerComponent implements OnInit {
  accounts: Account[] = [];
  currentAccount = new Account();
  userLogin = new Account();


  assign = Object.assign;
  userLoading = false;
  formValidation: any;
  isEdit = false;

  commissions: Commission[] = [];
  filterMonth = moment().month();
  filterYear = moment().year();
  filterAccount = null;
  filterCommission = 0;
  filterEmail = '';

  constructor(private accountApi: AccountApi,
              private router: Router,
              private route: ActivatedRoute,
              private notifierService: NotifierService,
              private commissionApi: CommissionApi,
              private orderApi: OrderApi,
              private partnerApi: PartnerApi,
              private auth: LoopBackAuth,
              private httpClient: HttpClient) {


  }

  ngOnInit() {
    this.userLogin = this.auth.getCurrentUserData();
    if (this.getRole()) {
      this.filterAccount = this.userLogin.id;
    } else {
      this.formValidation = $('#add_user').parsley();
    }
    this.findAccounts();
  }

  getMonth(month: number) {
    if (month) {
      return month + 1;
    }
  }

  resetFilter() {
    this.filterMonth = moment().month();
    this.filterYear = moment().year();
    if (this.getRole()) {
      this.filterAccount = this.userLogin.id;
    } else {
      this.filterAccount = null;
    }
    this.filterEmail = null;
    this.filterCommission = null;
    this.findAccounts();
  }

  findCommission() {
    this.commissionApi.find()
      .subscribe((commissions: Commission[]) => {
        this.commissions = commissions;
      }, error1 => {
        return 0;
      });
  }

  getRole() {
    return this.userLogin.roles[0].name === 'PARTNER';
  }

  getFilter() {
    const minDate = moment().set({ 'year': this.filterYear, 'month': this.filterMonth, 'date': 1, 'hour': 0, 'minute': 0, 'second': 0 });
    const maxDate = moment(minDate).add('1', 'months');
    if (this.filterEmail) {
      return {
        'include': [
          {
            'relation': 'voucher'
          },
          {
            'relation': 'order',
            'scope': {
              'include': 'cart',
              'where': {
                'deliveredAt': { between: [minDate, maxDate] },
                'status': 'DELIVERED',
                'commissionStatus': undefined
              },
              'order': 'deliveredAt DESC'
            }
          },
          {
            'relation': 'partner',
            'scope': {
              'where': {
                'updateAt': { between: [minDate, maxDate] }
              },
              'order': 'commissionDate DESC'
            }
          }
        ],
        'where': {
          'kind': 'PARTNER',
          'email': { 'like': `%${this.filterEmail}%` }
        },
        'order': 'createdAt DESC'
      };
    }
    return {
      'include': [
        {
          'relation': 'voucher'
        },
        {
          'relation': 'order',
          'scope': {
            'include': 'cart',
            'where': {
              'deliveredAt': { between: [minDate, maxDate] },
              'status': 'DELIVERED',
              'commissionStatus': undefined
            },
            'order': 'deliveredAt DESC'
          }
        },
        {
          'relation': 'partner',
          'scope': {
            'where': {
              'updateAt': { between: [minDate, maxDate] }
            },
            'order': 'commissionDate DESC'
          }
        }
      ],
      'where': {
        'kind': 'PARTNER'
      },
      'order': 'createdAt DESC'
    };
  }

  findAccounts() {
    this.userLoading = true;
    const filter = this.getFilter();
    this.accountApi
      .find(filter)
      .subscribe((users: Account[]) => {
        this.notifierService.notify('success', 'Load partner sucessfully');
        this.userLoading = false;
        this.accounts = users.filter((it: Account) => it.order && it.order.length > 0);
      }, error1 => {
        this.accounts = [];
        this.userLoading = false;
        this.notifierService.notify('error', error1.toString());
      });
  }

  async createOrEditPayment(account: Account) {
    if (this.getRole()) {
      return;
    }
    const data = Object.assign({}, account);
    if (account && account.partner && account.partner.length > 0) {
      const partner = account.partner[0];
      this.userLoading = true;
      partner.turnover = this.getTurnOvert(data);
      partner.commission = this.getCommission(data);
      partner.status = false;
      partner.accountId = data.id;
      partner.commissionDate = moment().set({ 'year': this.filterYear, 'month': this.filterMonth }).toDate();
      partner.numOfOrders = data.order.length;
      this.partnerApi.patchAttributes(partner.id, partner)
        .subscribe((res: Partner) => {
          this.notifierService.notify('success', 'Update commission successfully');
          this.userLoading = false;
        }, e => {
          this.notifierService.notify('error', e.toString());
          this.userLoading = false;
        });
    } else {
      const partner = new Partner();
      this.userLoading = true;
      partner.turnover = this.getTurnOvert(data);
      partner.commission = this.getCommission(data);
      partner.status = false;
      partner.accountId = data.id;
      partner.commissionDate = moment().set({ 'year': this.filterYear, 'month': this.filterMonth }).toDate();
      partner.numOfOrders = data.order.length;
      this.partnerApi.create(partner)
        .subscribe((res: Partner) => {
          this.notifierService.notify('success', 'Create commission successfully');
          this.userLoading = false;
        }, e => {
          this.notifierService.notify('error', e.toString());
          this.userLoading = false;
        });
    }

  }

  async processCommission(accounts: Account[]) {
    for (const acc of accounts) {
      await this.createOrEditPayment(acc);
    }
  }

  updateOderSatus(account: Account) {
    if (this.getRole()) {
      return;
    }
    for (const order of account.order) {
      order.commissionStatus = true;
      order.commissionDate = moment().toDate();
      this.orderApi.patchAttributes(order.id, order)
        .subscribe(() => {
        }, error1 => {
        });
    }
  }

  getCommission(account: Account) {
    let commission = 0;
    if (account && account.order) {
      account.order.forEach((order: Order) => {
        if (order && order.cart) {
          order.cart.forEach((item: Cart) => {
            commission += this.getPrice(item);
          });
        }
      });
    }
    return commission;
  }

  getPrice(cart: Cart) {
    let priceCom = 0;
    if (cart && cart.voucherId) {
      const price = cart.quantity * cart.itemPrice * (1 - cart.itemDiscount / 100);
      priceCom = price * (cart.itemCommission / 100);
    } else {
      priceCom = 0;
    }
    return priceCom;
  }

  getTurnOvert(account: Account) {
    let turnover = 0;
    if (account && account.order && account.order.length > 0) {
      for (const order of account.order) {
        turnover += order.price;
      }
    }
    return turnover;
  }
}
