/* tslint:disable */
import {
  Account
} from '../index';

declare var Object: any;
export interface PartnerInterface {
  "balance"?: number;
  "turnover"?: number;
  "commission"?: number;
  "payed"?: number;
  "status"?: boolean;
  "payedDate"?: Date;
  "commissionDate"?: Date;
  "numOfOrders"?: number;
  "id"?: number;
  "accountId"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  account?: Account;
}

export class Partner implements PartnerInterface {
  "balance": number;
  "turnover": number;
  "commission": number;
  "payed": number;
  "status": boolean;
  "payedDate": Date;
  "commissionDate": Date;
  "numOfOrders": number;
  "id": number;
  "accountId": number;
  "createdAt": Date;
  "updatedAt": Date;
  account: Account;
  constructor(data?: PartnerInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Partner`.
   */
  public static getModelName() {
    return "Partner";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Partner for dynamic purposes.
  **/
  public static factory(data: PartnerInterface): Partner{
    return new Partner(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Partner',
      plural: 'Partners',
      path: 'Partners',
      idName: 'id',
      properties: {
        "balance": {
          name: 'balance',
          type: 'number'
        },
        "turnover": {
          name: 'turnover',
          type: 'number'
        },
        "commission": {
          name: 'commission',
          type: 'number'
        },
        "payed": {
          name: 'payed',
          type: 'number'
        },
        "status": {
          name: 'status',
          type: 'boolean'
        },
        "payedDate": {
          name: 'payedDate',
          type: 'Date'
        },
        "commissionDate": {
          name: 'commissionDate',
          type: 'Date'
        },
        "numOfOrders": {
          name: 'numOfOrders',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "accountId": {
          name: 'accountId',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        account: {
          name: 'account',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'accountId',
          keyTo: 'id'
        },
      }
    }
  }
}
