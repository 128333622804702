import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Account } from '../../api/models';
import { AccountApi } from '../../api/services/custom';
import { NotifierService } from 'angular-notifier';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {
  account = new Account();
  passwordConfirm = '';

  constructor(private accountApi: AccountApi,
              private router: Router,
              private notifierService: NotifierService,
              private translateService: TranslateService) {
    if (accountApi.getCurrentId()) {
      this.router.navigate(['/main']);
    }
  }

  ngOnInit() {
  }

  signUp() {
    if (this.passwordConfirm !== this.account.password) {
      this.notifierService.notify('error', 'Passwords not matched !');
      return;
    }
    this.accountApi
      .create(this.account)
      .subscribe(account => {
        this.router.navigate(['/main']);
        this.notifierService.notify('success', this.translateService.instant('reset.messages.reset.success'));
      }, error => {
        this.notifierService.notify('error', error.message);
      });
  }
}
