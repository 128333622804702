import { NgModule } from '@angular/core';
import { ImageDirective } from './image.directive';

@NgModule({
  declarations: [
    ImageDirective
  ],
  imports: [],
  exports: [ImageDirective]
})
export class DirectivesModule {

}
