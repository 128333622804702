import { Component, OnInit } from '@angular/core';
import { Competition, Exam } from '../../../api/models';
import { NotifierService } from 'angular-notifier';
import { CompetitionApi, ExamApi } from '../../../api/services/custom';

declare const $;

@Component({
  selector: 'app-competition',
  templateUrl: './competition.component.html',
  styleUrls: ['./competition.component.css']
})
export class CompetitionComponent implements OnInit {
  competitions: Competition[] = [];
  currentCompetition = new Competition();
  isLoading = false;
  assign = Object.assign;
  formValidation: any;
  exams: Exam[] = [];

  constructor(private notifierService: NotifierService,
              private  competitionApi: CompetitionApi,
              private examApi: ExamApi) {
  }

  ngOnInit() {
    this.findCompetition();
    this.formValidation = $('#add-competition').parsley();
  }

  findCompetition() {
    this.isLoading = true;
    this.competitionApi.find()
      .subscribe((competitions: Competition[]) => {
        this.competitions = competitions;
        this.isLoading = false;
      }, error1 => {
        this.isLoading = false;
        this.competitions = [];

      });
  }


  createOrEditCompetition(competition: Competition) {
    if (competition.id) {
      this.formValidation.whenValidate()
        .then(event => {
          this.competitionApi.patchAttributes(competition.id, competition)
            .subscribe(() => {
              $('#formAdd').modal('hide');
              this.findCompetition();
              this.notifierService.notify('success', 'Update Competition successfully');
            }, error1 => {
              this.notifierService.notify('error', error1);
            });
        });
    } else {
      this.formValidation.whenValidate()
        .then(event => {
          this.competitionApi.create(competition)
            .subscribe(() => {
              $('#formAdd').modal('hide');
              this.notifierService.notify('success', 'Successfully');
              this.findCompetition();
            }, error1 => {
              this.notifierService.notify('error', error1);
            });
        });
    }
  }

  deleteCompetition(competition: Competition) {
    this.competitionApi.deleteById(competition.id)
      .subscribe(() => {
        this.notifierService.notify('success', 'Delete Competition successfully');
        this.findCompetition();
      }, error1 => {
        this.notifierService.notify('error', error1);
      });
  }
}
