import { Component, OnInit } from '@angular/core';
import { Account, Partner, Payment } from '../../../api/models';
import { AccountApi, PartnerApi, PaymentApi } from '../../../api/services/custom';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { LoopBackAuth } from '../../../api/services/core';
import * as moment from 'moment';
import * as _ from 'lodash';

declare const $;

@Component({
  selector: 'app-payment-partner',
  templateUrl: './payment-partner.component.html',
  styleUrls: ['./payment-partner.component.css']
})
export class PaymentPartnerComponent implements OnInit {
  assign = Object.assign;
  isLoading = false;
  formValidation: any;
  userLogin = new Account();
  account = new Account();
  payments: Payment[] = [];


  constructor(private accountApi: AccountApi,
              private router: Router,
              private route: ActivatedRoute,
              private notifierService: NotifierService,
              private paymentApi: PaymentApi,
              private auth: LoopBackAuth) {
  }

  ngOnInit() {
    this.userLogin = this.auth.getCurrentUserData();
    if (this.userLogin && this.userLogin.id) {
      this.isLoading = true;
      this.accountApi.findById(this.userLogin.id)
        .subscribe((account: Account) => {
          this.isLoading = false;
          if (!account) {
            this.notifierService.notify('error', 'Không tìm thất thông tin tài khoản');
          } else if (account && account.status === 'DEACTIVE') {
            this.notifierService.notify('error', 'Tài khoản của bạn đã bị khoá');
          } else {
            this.findAccounts();
          }
        }, error => {
          this.isLoading = false;
          this.notifierService.notify('error', 'Không tìm thất thông tin tài khoản, xin vui lòng đăng nhập lại');
        });
    } else {
      this.notifierService.notify('error', 'Không tìm thất thông tin tài khoản, xin vui lòng đăng nhập lại');
    }
    this.formValidation = $('#add_user').parsley();
  }

  getRole() {
    return this.userLogin.roles[0].name === 'PARTNER';
  }

  getFilter() {
    return {
      include: [
        {
          relation: 'payment',
          scope: {
            order: 'payedDate DESC'
          }
        },
        {
          relation: 'order',
          scope: {
            where: {
              status: 'DELIVERED'
            },
            order: 'deliveredAt DESC'
          }
        }
      ]
    };
  }

  findAccounts() {
    this.isLoading = true;
    this.accountApi
      .findById(this.userLogin.id, this.getFilter())
      .subscribe((user: Account) => {
        this.isLoading = false;
        if (user && user.payment && user.payment.length > 0) {
          this.notifierService.notify('success', 'Load partner sucessfully');
          this.account = user;
          this.payments = user.payment;
        } else {
          this.account = new Account();
          this.payments = [];
        }
        this.isLoading = false;
      }, error1 => {
        this.payments = [];
        this.isLoading = false;
        this.notifierService.notify('error', error1.toString());
      });
  }
}
