/* tslint:disable */
import {
  Account
} from '../index';

declare var Object: any;
export interface PaymentInterface {
  "turnover"?: number;
  "payed"?: number;
  "balance"?: number;
  "payedDate"?: Date;
  "cardNumber"?: string;
  "auth"?: string;
  "id"?: number;
  "accountId"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  account?: Account;
}

export class Payment implements PaymentInterface {
  "turnover": number;
  "payed": number;
  "balance": number;
  "payedDate": Date;
  "cardNumber": string;
  "auth": string;
  "id": number;
  "accountId": number;
  "createdAt": Date;
  "updatedAt": Date;
  account: Account;
  constructor(data?: PaymentInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Payment`.
   */
  public static getModelName() {
    return "Payment";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Payment for dynamic purposes.
  **/
  public static factory(data: PaymentInterface): Payment{
    return new Payment(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Payment',
      plural: 'Payments',
      path: 'Payments',
      idName: 'id',
      properties: {
        "turnover": {
          name: 'turnover',
          type: 'number'
        },
        "payed": {
          name: 'payed',
          type: 'number'
        },
        "balance": {
          name: 'balance',
          type: 'number'
        },
        "payedDate": {
          name: 'payedDate',
          type: 'Date'
        },
        "cardNumber": {
          name: 'cardNumber',
          type: 'string'
        },
        "auth": {
          name: 'auth',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "accountId": {
          name: 'accountId',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        account: {
          name: 'account',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'accountId',
          keyTo: 'id'
        },
      }
    }
  }
}
