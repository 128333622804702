import { Component, OnInit } from '@angular/core';
import { Account, Cart, City, County, Course, Order, Product, Voucher } from '../../../api/models';
import { NotifierService } from 'angular-notifier';
import { AccountApi, CartApi, CityApi, CountyApi, CourseApi, OrderApi, ProductApi, VoucherApi } from '../../../api/services/custom';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { LoopBackAuth } from '../../../api/services/core';
import { flatMap } from 'rxjs/operators';
import { of } from 'rxjs';


declare const $;

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css']
})
export class CartComponent implements OnInit {

  isLoading = false;
  assign = Object.assign;
  formValidation: any;
  currentCart = new Cart();
  accounts: Account[] = [];
  userIdFilter: number;
  filterMonth = moment().month();
  filterYear = moment().year();
  userLogin = new Account();
  filterOrderId = null;
  carts: Cart[] = [];
  currentVoucherFind = new Voucher();
  order = new Order();
  isCheck = false;
  isCreateNewCart = false;
  productId = 0;

  // moment().set({ 'date': 1, 'hour': 0, 'minute': 0, 'second': 0 });

  constructor(private notifierService: NotifierService,
              private orderApi: OrderApi,
              private cartApi: CartApi,
              private accountApi: AccountApi,
              private route: ActivatedRoute,
              private auth: LoopBackAuth,
              private courseApi: CourseApi,
              private productApi: ProductApi) {
  }

  ngOnInit() {
    this.userLogin = this.auth.getCurrentUserData();
    if (this.getRole()) {
      this.userIdFilter = this.userLogin.id;
    } else {
      this.formValidation = $('#add').parsley();
    }
    this.route.queryParams
      .subscribe(params => {
        if (params) {
          // if (this.getRole()) {
          //   this.userIdFilter = this.userLogin.id;
          // } else {
          //   this.userIdFilter = +params.userOId;
          // }
          this.filterOrderId = params.orderId;
          this.filterMonth = params.filterMonth ? params.filterMonth : moment().month();
          this.filterYear = params.filterYear ? params.filterYear : moment().year();
        }
        this.findCart();
      }, error1 => {
      });
  }

  getRole() {
    return this.userLogin.roles[0].name === 'PARTNER';
  }

  getFilter() {
    if (this.filterOrderId) {
      return {
        include: [
          {
            relation: 'order',
            scope: {
              include: {
                relation: 'account'
              }
            }
          }
        ],
        where: {
          orderId: this.filterOrderId
        },
        order: 'createdAt DESC'
      };
    }
    if (this.filterMonth !== moment().month() && this.filterMonth !== null) {
      const minDate = moment().set({ 'year': this.filterYear, 'month': this.filterMonth, 'date': 1, 'hour': 0, 'minute': 0, 'second': 0 });
      const maxDate = moment(minDate).add('1', 'months');
      return {
        include: [
          {
            relation: 'order',
            scope: {
              include: {
                relation: 'account'
              }
            }
          }
        ],
        where: {
          createdAt: { between: [minDate, maxDate] }
        },
        order: 'createdAt DESC'
      };
    }
    return {
      include: [
        {
          relation: 'order',
          scope: {
            include: {
              relation: 'account'
            }
          }
        }
      ],
      order: 'createdAt DESC'
    };
  }

  getDisCount(cart: Cart) {
    return (cart && cart.voucherId) ? cart.itemDiscount : 0;
  }

  getOrderStatus(status: string) {
    if (status === 'RESOLVED') {
      return 'Chốt đơn';
    } else if (status === 'CANCEL') {
      return 'Hủy đơn';
    } else if (status === 'DELIVERING') {
      return 'Đang giao';
    } else if (status === 'DELIVERED') {
      return 'Đã giao';
    } else if (status === 'RETURN') {
      return 'Trả lại hàng';
    } else {
      return 'Chờ xử lý';
    }

  }

  findCart() {
    const filter = this.getFilter();
    this.isLoading = true;
    this.cartApi.find(filter)
      .subscribe((items: Cart[]) => {
        this.carts = items;
        this.notifierService.notify('success', 'Load orders successfully');
        this.isLoading = false;

      }, error1 => {
        this.isLoading = false;
        this.carts = [];
        this.notifierService.notify('error', error1);
      });
  }

  resetFilter() {
    this.filterMonth = moment().month();
    this.filterYear = moment().year();
    this.filterOrderId = null;
    this.findCart();
  }

  deleteCart(cart: Cart) {
    if (this.getRole()) {
      return;
    }
    const filter = {
      include: ['cart', 'account']
    };
    this.cartApi.deleteById(cart.id)
      .pipe(
        flatMap(() => {
          return this.orderApi.findById(cart.orderId, filter);
        }),
        flatMap((order: Order) => {
          if (order && order.id) {
            order.price = this.getTotalPrice(order.cart);
            order.commission = this.getTotalCommission(order.cart);
            return this.orderApi.patchAttributes(order.id, order);
          } else {
            this.notifierService.notify('error', 'Update cart error');
            return of(null);
          }
        })
      )
      .subscribe((item: Order) => {
        if (item && item.id) {
          this.findCart();
          this.notifierService.notify('success', 'Update cart successfully');
        } else {
          this.order = new Order();
          this.notifierService.notify('error', 'Update cart error');
        }
      }, error1 => {
        this.order = new Order();
        this.notifierService.notify('error', error1);
      });
  }

  getOrder(id: number) {
    if (!id) {
      return;
    }
    this.isCheck = true;
    this.isLoading = true;
    const filter = {
      include: [{
        relation: 'cart'
      },
        {
          relation: 'account',
          scope: {
            include: ['voucher']
          }
        }
      ]
    };
    this.orderApi.findById(id, filter).subscribe((order: Order) => {
      this.isLoading = false;
      this.isCheck = false;
      if (order && order.id) {
        this.order = order;
        if (order.account && order.account.voucher.id) {
          this.currentVoucherFind = order.account.voucher;
        } else {
          this.currentVoucherFind = new Voucher();
        }
        this.notifierService.notify('success', 'Đọc thông tin đơn hàng thành công');
      } else {
        this.notifierService.notify('error', 'Mã đơn hàng không đúng');
      }
    }, error => {
      this.isCheck = false;
      this.isLoading = false;
      this.order = new Order();
      this.currentVoucherFind = new Voucher();
      this.notifierService.notify('error', 'Mã đơn hàng không đúng');
    });
  }

  processCart(cart: Cart) {
    if (cart && cart.id) {
      this.creatOrEditCart(cart);
    } else {
      if (cart.itemKind === 'THE') {
        this.courseApi.findById(this.productId)
          .subscribe((course: Course) => {
            if (course && course.id) {
              if (course.price <= 0) {
                this.notifierService.notify('success', 'Sản phẩm miễn phí hoặc không có giá');
              } else {
                cart.itemName = course.name;
                cart.itemImage = course.thumbnail;
                cart.itemDiscount = parseInt(course.discount, 10);
                cart.itemCommission = course.commission;
                cart.itemShipOut = course.shipOut;
                cart.itemShipIn = course.shipIn;
                cart.itemPrice = course.price;
                this.creatOrEditCart(cart);
              }
            } else {
              this.notifierService.notify('error', 'Không tìm thấy thông tin sản phẩm');
            }
          }, error => {
            this.notifierService.notify('error', 'Không tìm thấy thông tin sản phẩm');
          });
      } else {
        this.productApi.findById(this.productId)
          .subscribe((product: Product) => {
            if (product && product.id) {
              if (product.price <= 0) {
                this.notifierService.notify('success', 'Sản phẩm miễn phí hoặc không có giá');
              } else {
                cart.itemName = product.name;
                cart.itemImage = product.thumbnail;
                cart.itemDiscount = product.discount;
                cart.itemCommission = product.commission;
                cart.itemShipOut = product.shipOut;
                cart.itemShipIn = product.shipIn;
                cart.itemPrice = product.price;
                this.creatOrEditCart(cart);
              }

            } else {
              this.notifierService.notify('error', 'Không tìm thấy thông tin sản phẩm');
            }
          }, error => {
            this.notifierService.notify('error', 'Không tìm thấy thông tin sản phẩm');
          });
      }
    }
  }

  creatOrEditCart(cart: Cart) {
    const filter = {
      include: ['cart', 'account']
    };
    this.formValidation.whenValidate()
      .then(event => {
        this.isCreateNewCart = false;
        this.orderApi.findById(cart.orderId, filter).pipe(
          flatMap((orderRes: Order) => {
            if (orderRes && orderRes.id) {
              if (cart && cart.id) {
                this.isCreateNewCart = false;
                return this.cartApi.patchAttributes(cart.id, cart);
              } else {
                this.isCreateNewCart = true;
                if (this.currentVoucherFind && this.currentVoucherFind.id) {
                  cart.voucherId = this.currentVoucherFind.id;
                }
                return this.cartApi.create(cart);
              }
            } else {
              this.notifierService.notify('error', 'Can not found order');
              return of(null);
            }
          }),
          flatMap((item: Cart) => {
            if (item && item.id) {
              return this.orderApi.findById(item.orderId, filter);
            } else {
              this.notifierService.notify('error', 'Can not update Cart');
              return of(null);
            }
          }),
          flatMap((item: Order) => {
            if (item && item.cart) {
              item.price = this.getTotalPrice(item.cart);
              item.commission = this.getTotalCommission(item.cart);
              return this.orderApi.patchAttributes(item.id, item);
            } else {
              this.notifierService.notify('error', 'Update cart error');
              return of(null);
            }
          })
        ).subscribe((item: Order) => {
          this.isLoading = false;
          if (item && item.id) {
            $('#formAdd').modal('hide');
            this.findCart();
            this.notifierService.notify('success', 'Update cart successfully');
          } else {
            this.order = new Order();
            this.notifierService.notify('error', 'Update cart error');
          }
        }, error => {
          this.order = new Order();
          this.isLoading = false;
          this.notifierService.notify('error', error);
        });
      });
  }

  getTotalPrice(items: Cart[]) {
    let price = 0;
    for (const item of items) {
      if (item && item.voucherId) {
        price += (item.quantity * (item.itemPrice * (1 - item.itemDiscount / 100)));
      } else {
        price += (item.quantity * item.itemPrice);
      }
    }
    return price;
  }

  getPrice(cart: Cart) {
    let price = 0;
    if (cart && cart.voucherId) {
      price = cart.quantity * cart.itemPrice * (1 - cart.itemDiscount / 100);
    } else {
      price = cart.quantity * cart.itemPrice;
    }
    return price;
  }

  getCommission(cart: Cart) {
    let commission = 0;
    if (cart && cart.voucherId) {
      const price = cart.quantity * cart.itemPrice * (1 - cart.itemDiscount / 100);
      commission = price * (cart.itemCommission / 100);
    } else {
      commission = 0;
    }
    return commission || 0;
  }

  getTotalCommission(carts: Cart[]) {
    let com = 0;
    for (const item of carts) {
      com += this.getCommission(item);
    }
    return com;
  }
}
