/* tslint:disable */
import {
  City,
  County
} from '../index';

declare var Object: any;
export interface SchoolInterface {
  "name": string;
  "id"?: number;
  "cityId"?: number;
  "countyId"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  city?: City;
  county?: County;
}

export class School implements SchoolInterface {
  "name": string;
  "id": number;
  "cityId": number;
  "countyId": number;
  "createdAt": Date;
  "updatedAt": Date;
  city: City;
  county: County;
  constructor(data?: SchoolInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `School`.
   */
  public static getModelName() {
    return "School";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of School for dynamic purposes.
  **/
  public static factory(data: SchoolInterface): School{
    return new School(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'School',
      plural: 'Schools',
      path: 'Schools',
      idName: 'id',
      properties: {
        "name": {
          name: 'name',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "cityId": {
          name: 'cityId',
          type: 'number'
        },
        "countyId": {
          name: 'countyId',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        city: {
          name: 'city',
          type: 'City',
          model: 'City',
          relationType: 'belongsTo',
                  keyFrom: 'cityId',
          keyTo: 'id'
        },
        county: {
          name: 'county',
          type: 'County',
          model: 'County',
          relationType: 'belongsTo',
                  keyFrom: 'countyId',
          keyTo: 'id'
        },
      }
    }
  }
}
