import {Component, OnInit, ViewChild} from '@angular/core';
import {Image, Post} from '../../../api/models';
import {PostApi} from '../../../api/services/custom';
import {NotifierService} from 'angular-notifier';
import {HttpClient} from '@angular/common/http';
import {LoopBackConfig} from '../../../api';

declare const $;

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.css']
})
export class SupportComponent implements OnInit {
  posts: Post[] = [];
  assign = Object.assign;
  currentPost = new Post();
  isLoading = false;
  formValidation: any;
  name = 'ng2-ckeditor';
  ckeConfig: any;
  mycontent: string;
  log = '';
  @ViewChild('myckeditor') ckeditor: any;
  constructor(private postApi: PostApi,
              private notifierService: NotifierService,
              private httpClient: HttpClient) { }

  ngOnInit() {
    this.findPost();
    this.formValidation = $('#add_new').parsley();
    this.ckeConfig = {
      allowedContent: false,
      forcePasteAsPlainText: true,
      enterMode: 2,
      toolbarGroups: [
        { name: 'document', groups: ['mode'] },
        { name: 'clipboard', groups: ['clipboard', 'undo'] },
        { name: 'editing', groups: ['find', 'selection', 'spellchecker'] },
        '/',
        { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
        { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi'] },
        { name: 'links' },
        { name: 'insert' },
        '/',
        { name: 'styles' },
        { name: 'colors' }]
    };
  }
  onChange($event: any): void {
    console.log('onChange');
    // this.log += new Date() + "<br />";
  }
  findPost() {
    const filter = {
      'order': 'createdAt DESC',
    };
    this.isLoading = true;
    this.postApi.find(filter)
      .subscribe((posts: Post[]) => {
        this.isLoading = false;
        this.posts = posts;
      }, error1 => {
        this.posts = [];
        this.isLoading = false;
      });
  }

  onThumbChange(file) {
    if (file) {
      this.uploadFile(file, 'posts', 'img');
    }
  }

  uploadFile(file, container: string, type: string) {
    const formData = new FormData();
    formData.append('file', file);
    this.httpClient
      .post(`${LoopBackConfig.getPath()}/${LoopBackConfig.getApiVersion()}/Containers/` + container + `/upload`, formData)
      .subscribe((result: any) => {
        switch (type) {
          case 'img':
            this.currentPost.image = result.result.files.file[0].name;
            break;
          default:
            break;
        }
      }, error => {
      });
  }

  createOrEditposts(posts: Post) {
    if (!posts.id) {
      const createdposts = Object.assign({}, posts);
      this.formValidation.whenValidate()
        .then(event => {
          $('#formAdd').modal('hide');
          this.postApi
            .create(createdposts)
            .subscribe(() => {
              this.findPost();
              this.notifierService.notify('success', 'Create posts successfully');
            }, error1 => {
              this.notifierService.notify('error', error1);
            });
        });
    } else {
      this.formValidation.whenValidate()
        .then(() => {
          $('#formAdd').modal('hide');
          this.postApi.patchAttributes(posts.id, posts)
            .subscribe(() => {
              posts = this.assign({});
              this.findPost();
              this.notifierService.notify('success', 'Update posts successfully');
            }, error1 => {
              this.notifierService.notify('error', error1);
            });
        });

    }
  }

  deletePost(posts: Post) {
    this.postApi.deleteById(posts.id)
      .subscribe(() => {
        this.notifierService.notify('success', 'Delete posts successfully');
        this.findPost();
      }, error1 => {
        this.notifierService.notify('error', error1);
      });
  }
}
