/* tslint:disable */

declare var Object: any;
export interface GameHelpInterface {
  "name"?: string;
  "topic"?: string;
  "type"?: string;
  "level"?: number;
  "url"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
}

export class GameHelp implements GameHelpInterface {
  "name": string;
  "topic": string;
  "type": string;
  "level": number;
  "url": string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  constructor(data?: GameHelpInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `GameHelp`.
   */
  public static getModelName() {
    return "GameHelp";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of GameHelp for dynamic purposes.
  **/
  public static factory(data: GameHelpInterface): GameHelp{
    return new GameHelp(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'GameHelp',
      plural: 'GameHelps',
      path: 'GameHelps',
      idName: 'id',
      properties: {
        "name": {
          name: 'name',
          type: 'string'
        },
        "topic": {
          name: 'topic',
          type: 'string'
        },
        "type": {
          name: 'type',
          type: 'string'
        },
        "level": {
          name: 'level',
          type: 'number'
        },
        "url": {
          name: 'url',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
      }
    }
  }
}
