/* tslint:disable */
import {
  Role,
  AccountToken,
  Course,
  Card,
  City,
  County,
  School,
  Order,
  Voucher,
  Partner,
  Payment,
  GameData
} from '../index';

declare var Object: any;
export interface AccountInterface {
  "nom"?: string;
  "prenom"?: string;
  "email"?: string;
  "birthday"?: Date;
  "avatar"?: string;
  "class"?: string;
  "phoneNumber"?: string;
  "status"?: string;
  "address"?: string;
  "description"?: string;
  "web"?: string;
  "facebookAccount"?: string;
  "kind"?: string;
  "code"?: string;
  "like"?: any;
  "cardNumber"?: string;
  "bank"?: string;
  "subBank"?: string;
  "authBank"?: string;
  "socialKind"?: string;
  "validatePass"?: string;
  "oldPass"?: string;
  "oldPassMigrate"?: string;
  "realm"?: string;
  "username"?: string;
  "emailVerified"?: boolean;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  "cityId"?: number;
  "countyId"?: number;
  "schoolId"?: number;
  "password"?: string;
  roles?: Role[];
  accessTokens?: AccountToken[];
  courses?: Course[];
  cards?: Card[];
  city?: City;
  county?: County;
  school?: School;
  order?: Order[];
  voucher?: Voucher;
  partner?: Partner[];
  payment?: Payment[];
  gameData?: GameData[];
}

export class Account implements AccountInterface {
  "nom": string;
  "prenom": string;
  "email": string;
  "birthday": Date;
  "avatar": string;
  "class": string;
  "phoneNumber": string;
  "status": string;
  "address": string;
  "description": string;
  "web": string;
  "facebookAccount": string;
  "kind": string;
  "code": string;
  "like": any;
  "cardNumber": string;
  "bank": string;
  "subBank": string;
  "authBank": string;
  "socialKind": string;
  "validatePass": string;
  "oldPass": string;
  "oldPassMigrate": string;
  "realm": string;
  "username": string;
  "emailVerified": boolean;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  "cityId": number;
  "countyId": number;
  "schoolId": number;
  "password": string;
  roles: Role[];
  accessTokens: AccountToken[];
  courses: Course[];
  cards: Card[];
  city: City;
  county: County;
  school: School;
  order: Order[];
  voucher: Voucher;
  partner: Partner[];
  payment: Payment[];
  gameData: GameData[];
  constructor(data?: AccountInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Account`.
   */
  public static getModelName() {
    return "Account";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Account for dynamic purposes.
  **/
  public static factory(data: AccountInterface): Account{
    return new Account(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Account',
      plural: 'Accounts',
      path: 'Accounts',
      idName: 'id',
      properties: {
        "nom": {
          name: 'nom',
          type: 'string'
        },
        "prenom": {
          name: 'prenom',
          type: 'string'
        },
        "email": {
          name: 'email',
          type: 'string'
        },
        "birthday": {
          name: 'birthday',
          type: 'Date'
        },
        "avatar": {
          name: 'avatar',
          type: 'string'
        },
        "class": {
          name: 'class',
          type: 'string'
        },
        "phoneNumber": {
          name: 'phoneNumber',
          type: 'string'
        },
        "status": {
          name: 'status',
          type: 'string'
        },
        "address": {
          name: 'address',
          type: 'string'
        },
        "description": {
          name: 'description',
          type: 'string'
        },
        "web": {
          name: 'web',
          type: 'string'
        },
        "facebookAccount": {
          name: 'facebookAccount',
          type: 'string'
        },
        "kind": {
          name: 'kind',
          type: 'string'
        },
        "code": {
          name: 'code',
          type: 'string'
        },
        "like": {
          name: 'like',
          type: 'any'
        },
        "cardNumber": {
          name: 'cardNumber',
          type: 'string'
        },
        "bank": {
          name: 'bank',
          type: 'string'
        },
        "subBank": {
          name: 'subBank',
          type: 'string'
        },
        "authBank": {
          name: 'authBank',
          type: 'string'
        },
        "socialKind": {
          name: 'socialKind',
          type: 'string'
        },
        "validatePass": {
          name: 'validatePass',
          type: 'string'
        },
        "oldPass": {
          name: 'oldPass',
          type: 'string'
        },
        "oldPassMigrate": {
          name: 'oldPassMigrate',
          type: 'string'
        },
        "realm": {
          name: 'realm',
          type: 'string'
        },
        "username": {
          name: 'username',
          type: 'string'
        },
        "emailVerified": {
          name: 'emailVerified',
          type: 'boolean'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "cityId": {
          name: 'cityId',
          type: 'number'
        },
        "countyId": {
          name: 'countyId',
          type: 'number'
        },
        "schoolId": {
          name: 'schoolId',
          type: 'number'
        },
        "password": {
          name: 'password',
          type: 'string'
        },
      },
      relations: {
        roles: {
          name: 'roles',
          type: 'Role[]',
          model: 'Role',
          relationType: 'hasMany',
          modelThrough: 'RoleMapping',
          keyThrough: 'roleId',
          keyFrom: 'id',
          keyTo: 'principalId'
        },
        accessTokens: {
          name: 'accessTokens',
          type: 'AccountToken[]',
          model: 'AccountToken',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'userId'
        },
        courses: {
          name: 'courses',
          type: 'Course[]',
          model: 'Course',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'accountId'
        },
        cards: {
          name: 'cards',
          type: 'Card[]',
          model: 'Card',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'accountId'
        },
        city: {
          name: 'city',
          type: 'City',
          model: 'City',
          relationType: 'belongsTo',
                  keyFrom: 'cityId',
          keyTo: 'id'
        },
        county: {
          name: 'county',
          type: 'County',
          model: 'County',
          relationType: 'belongsTo',
                  keyFrom: 'countyId',
          keyTo: 'id'
        },
        school: {
          name: 'school',
          type: 'School',
          model: 'School',
          relationType: 'belongsTo',
                  keyFrom: 'schoolId',
          keyTo: 'id'
        },
        order: {
          name: 'order',
          type: 'Order[]',
          model: 'Order',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'accountId'
        },
        voucher: {
          name: 'voucher',
          type: 'Voucher',
          model: 'Voucher',
          relationType: 'hasOne',
                  keyFrom: 'id',
          keyTo: 'accountId'
        },
        partner: {
          name: 'partner',
          type: 'Partner[]',
          model: 'Partner',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'accountId'
        },
        payment: {
          name: 'payment',
          type: 'Payment[]',
          model: 'Payment',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'accountId'
        },
        gameData: {
          name: 'gameData',
          type: 'GameData[]',
          model: 'GameData',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'accountId'
        },
      }
    }
  }
}
