import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DEFAULT_AVATAR, VIETSTEM } from '../constants';
import { LoopBackConfig } from '../../api';

@Directive({
  selector: '[appImage]'
})
export class ImageDirective implements OnChanges {
  @Input('appImage') avatar: string;
  @Input() container: string;

  constructor(private el: ElementRef) {
    this.changeImage();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.avatar) {
      this.changeImage();
    }
  }

  changeImage() {
    switch (this.el.nativeElement.tagName) {
      case 'IMG':
        this.el.nativeElement.src = this.getImageUrl(this.avatar);
        break;
      case 'DIV':
        this.el.nativeElement.style.background = `url(${this.getImageUrl(this.avatar)}) transparent`;
        break;
    }
  }

  getImageUrl(avatar) {
    if (avatar) {
      return `${LoopBackConfig.getPath()}/${LoopBackConfig.getApiVersion()}/Containers/${this.container}/download/${avatar}`;
    } else if (this.container === 'avatars') {
      return DEFAULT_AVATAR;
    } else {
      return VIETSTEM;
    }
  }

  setDefaultAvatar(image: any) {
    image.src = DEFAULT_AVATAR;
  }

}
