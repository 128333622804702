/* tslint:disable */
import {
  City
} from '../index';

declare var Object: any;
export interface CountyInterface {
  "maqh"?: string;
  "name"?: string;
  "type"?: string;
  "id"?: number;
  "mtpId"?: number;
  "cityId"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  mtp?: City;
}

export class County implements CountyInterface {
  "maqh": string;
  "name": string;
  "type": string;
  "id": number;
  "mtpId": number;
  "cityId": number;
  "createdAt": Date;
  "updatedAt": Date;
  mtp: City;
  constructor(data?: CountyInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `County`.
   */
  public static getModelName() {
    return "County";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of County for dynamic purposes.
  **/
  public static factory(data: CountyInterface): County{
    return new County(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'County',
      plural: 'Counties',
      path: 'Counties',
      idName: 'id',
      properties: {
        "maqh": {
          name: 'maqh',
          type: 'string'
        },
        "name": {
          name: 'name',
          type: 'string'
        },
        "type": {
          name: 'type',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "mtpId": {
          name: 'mtpId',
          type: 'number'
        },
        "cityId": {
          name: 'cityId',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        mtp: {
          name: 'mtp',
          type: 'City',
          model: 'City',
          relationType: 'belongsTo',
                  keyFrom: 'mtpId',
          keyTo: 'id'
        },
      }
    }
  }
}
