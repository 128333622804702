import { Component, OnInit } from '@angular/core';
import { Account, Voucher } from '../../../api/models';
import { AccountApi, VoucherApi } from '../../../api/services/custom';
import { ActivatedRoute, Router } from '@angular/router';
import { truncate } from 'lodash';
import { NotifierService } from 'angular-notifier';
import { LoopBackAuth } from '../../../api/services/core';

declare const $;

@Component({
  selector: 'app-voucher',
  templateUrl: './voucher.component.html',
  styleUrls: ['./voucher.component.css']
})
export class VoucherComponent implements OnInit {
  vouchers: Voucher[] = [];
  currentVoucher = new Voucher();
  userLoading = false;
  formValidation: any;
  assign = Object.assign;
  isEdit = false;
  accounts: Account[] = [];
  isExistVoucher = false;
  userLogin = new Account();

  constructor(private voucherApi: VoucherApi,
              private accountApi: AccountApi,
              private router: Router,
              private route: ActivatedRoute,
              private notifierService: NotifierService,
              private auth: LoopBackAuth) {
  }

  ngOnInit() {
    this.userLogin = this.auth.getCurrentUserData();
    if (this.getRole()) {
    } else {
      this.formValidation = $('#add_user').parsley();
      this.findAccount();
    }
    this.findVoucher();
  }

  getRole() {
    return this.userLogin.roles[0].name === 'PARTNER';
  }

  getFilter() {
    if (this.getRole()) {
      return {
        'include': {
          'relation': 'account'
        },
        'where': {
          'id': this.userLogin.id
        },
        'order': 'createdAt DESC'
      };
    }
    return {
      'include': {
        'relation': 'account'
      },
      'order': 'createdAt DESC'
    };
  }

  findVoucher() {
    this.userLoading = true;
    this.voucherApi
      .find(this.getFilter())
      .subscribe((vouchers: Voucher[]) => {
        this.notifierService.notify('success', 'Load voucher sucessfully');
        this.userLoading = false;
        this.vouchers = vouchers;
      }, error1 => {
        this.vouchers = [];
        this.userLoading = false;
        this.notifierService.notify('error', error1.toString());
      });
  }

  findAccount() {
    const filter = {
      'where': {
        'kind': 'PARTNER'
      },
      'order': 'createdAt DESC'
    };
    this.userLoading = true;
    this.accountApi.find(filter)
      .subscribe((accounts: Account[]) => {
        this.accounts = accounts;
        this.notifierService.notify('success', 'Load partner sucessfully');
        this.userLoading = false;
      }, error1 => {
        this.accounts = [];
        this.userLoading = false;
        this.notifierService.notify('error', error1.toString());
      });
  }

  createOrEditUser(voucher: Voucher) {
    if (this.getRole()) {
      return;
    }
    if (this.isExistVoucher === true) {
      alert('Mã giảm giá đã tồn tại, xin vui lòng chọn mã khác !');
      return;
    }
    voucher.code = voucher.code.toUpperCase();
    if (!voucher.id) {
      const createVoucher = Object.assign({}, voucher);
      this.formValidation.whenValidate()
        .then(event => {
          $('#form-add').modal('hide');
          this.voucherApi
            .create(createVoucher)
            .subscribe(() => {
              this.notifierService.notify('success', 'Create partner successfully');
              this.findVoucher();
            }, e => {
              this.notifierService.notify('error', e.toString());
            });
        });
    } else {
      this.formValidation.whenValidate()
        .then(() => {
          $('#form-add').modal('hide');
          this.voucherApi.patchAttributes(voucher.id, voucher)
            .subscribe(() => {
              this.notifierService.notify('success', 'Update partner successfully');
              this.findVoucher();
            }, error1 => {
              this.notifierService.notify('error', error1.toString());
            });
        });

    }
  }

  isExist(code: string) {
    if (this.getRole()) {
      return;
    }
    if (code.length < 5) {
      return;
    }
    code = code.toUpperCase();
    const filter = {
      'where': {
        'code': code,
      }
    };
    this.voucherApi.findOne(filter)
      .subscribe((voucher: Voucher) => {
        this.isExistVoucher = true;
      }, error1 => {
        this.isExistVoucher = false;
      });
  }

  deleteVoucher(voucher: Voucher) {
    if (this.getRole()) {
      return;
    }
    this.voucherApi
      .deleteById(voucher.id)
      .flatMap(() => {
        return this.voucherApi.deleteById(voucher.id);
      })
      .subscribe(() => {
        this.findVoucher();
      });
  }
}
