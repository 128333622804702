import {Component, OnInit} from '@angular/core';
import {English} from '../../../api/models';
import {EnglishApi, LoopBackConfig} from '../../../api';
import {NotifierService} from 'angular-notifier';
import {HttpClient} from '@angular/common/http';

declare const $;

@Component({
  selector: 'app-english',
  templateUrl: './english.component.html',
  styleUrls: ['./english.component.css']
})
export class EnglishComponent implements OnInit {
  cards: English[] = [];
  assign = Object.assign;
  currentCard = new English();
  isLoading = false;
  formValidation: any;
  log = '';
  cardTopicFilter = 'ALL';
  cardMapFilter = 'ALL';

  constructor(private englishApi: EnglishApi,
              private notifierService: NotifierService,
              private httpClient: HttpClient) {
  }

  ngOnInit() {
    this.findCards();
    this.formValidation = $('#add_card').parsley();
  }

  onChange($event: any): void {
    console.log('onChange');
    // this.log += new Date() + "<br />";
  }

  getFilter() {
    let cardTopic = this.cardTopicFilter;
    let cardMap = this.cardMapFilter;
    if (cardTopic === 'ALL') {
      cardTopic = undefined;
    }
    if (cardMap === 'ALL') {
      cardMap = undefined;
    }
    let filter;
    filter = {
      'order': 'topic ASC',
      where: {
        and: [
          {
            topic: cardTopic || undefined,
          },
          {
            map: cardMap || undefined
          }
        ]
      }
    };
    return filter;
  }

  findCards() {
    const filter = this.getFilter();
    this.isLoading = true;
    this.englishApi.find(filter)
      .subscribe((cards: English[]) => {
        this.isLoading = false;
        this.cards = cards;
      }, error1 => {
        this.cards = [];
        this.isLoading = false;
      });
  }

  onThumbChange(file) {
    if (file) {
      this.uploadFile(file, 'english', 'img');
    }
  }

  uploadFile(file, container: string, type: string) {
    const formData = new FormData();
    formData.append('file', file);
    this.httpClient
      .post(`${LoopBackConfig.getPath()}/${LoopBackConfig.getApiVersion()}/Containers/` + container + `/upload`, formData)
      .subscribe((result: any) => {
        switch (type) {
          case 'img':
            this.currentCard.path = result.result.files.file[0].name;
            break;
          default:
            break;
        }
      }, error => {
      });
  }

  createOrEditcards(card: English) {
    if (!card.id) {
      const createdcard = Object.assign({}, card);
      this.formValidation.whenValidate()
        .then(event => {
          $('#formAdd').modal('hide');
          this.englishApi
            .create(createdcard)
            .subscribe(() => {
              this.findCards();
              this.notifierService.notify('success', 'Create cards successfully');
            }, error1 => {
            });
        });
    } else {
      this.formValidation.whenValidate()
        .then(() => {
          $('#formAdd').modal('hide');
          this.englishApi.patchAttributes(card.id, card)
            .subscribe(() => {
              card = this.assign({});
              this.notifierService.notify('success', 'Update cards successfully');
              this.findCards();
            }, error1 => {
            });
        });

    }
  }

  deleteNews(card: English) {
    this.englishApi.deleteById(card.id)
      .subscribe(() => {
        this.notifierService.notify('success', 'Delete card successfully');
        this.findCards();
      }, error1 => {
        this.notifierService.notify('error', error1);
      });
  }
}
