/* tslint:disable */
import {
  County
} from '../index';

declare var Object: any;
export interface WardInterface {
  "xaid": string;
  "name": string;
  "type": string;
  "id"?: number;
  "mqhId"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  mqh?: County;
}

export class Ward implements WardInterface {
  "xaid": string;
  "name": string;
  "type": string;
  "id": number;
  "mqhId": number;
  "createdAt": Date;
  "updatedAt": Date;
  mqh: County;
  constructor(data?: WardInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Ward`.
   */
  public static getModelName() {
    return "Ward";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Ward for dynamic purposes.
  **/
  public static factory(data: WardInterface): Ward{
    return new Ward(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Ward',
      plural: 'Wards',
      path: 'Wards',
      idName: 'id',
      properties: {
        "xaid": {
          name: 'xaid',
          type: 'string'
        },
        "name": {
          name: 'name',
          type: 'string'
        },
        "type": {
          name: 'type',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "mqhId": {
          name: 'mqhId',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        mqh: {
          name: 'mqh',
          type: 'County',
          model: 'County',
          relationType: 'belongsTo',
                  keyFrom: 'mqhId',
          keyTo: 'id'
        },
      }
    }
  }
}
