import { Component, OnInit } from '@angular/core';
import { Card, Course } from '../../../api/models';
import { AccountApi, CardApi, CourseApi } from '../../../api/services/custom';
import { NotifierService } from 'angular-notifier';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { zip } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';

declare const $;

@Component({
  selector: 'app-game-card',
  templateUrl: './game-card.component.html',
  styleUrls: ['./game-card.component.css']
})
export class GameCardComponent implements OnInit {
  cards: Card[] = [];
  currentCard = new Card();
  partners: Account[] = [];
  isLoading = false;
  assign = Object.assign;
  formValidation: any;
  numberOfCard = 1;
  dayOfCard = 365;
  userIdFilter: number;
  courses: Course[] = [];
  cardTopicFilter = '';
  PAGE_SIZE = environment.PAGE_SIZE;
  currentPage: number;
  totalItems: number;
  activeFilter = undefined;
  nameFilter = undefined;

  constructor(private cardApi: CardApi,
              private courseApi: CourseApi,
              private notifierService: NotifierService,
              private accountApi: AccountApi,
              private route: ActivatedRoute,
              private router: Router,
              private spinner: NgxSpinnerService) {
  }

  ngOnInit() {
    this.route.queryParams
      .subscribe(params => {
        this.userIdFilter = +params.userOId;
      });
    this.findCard();
    this.formValidation = $('#add_card').parsley();
  }

  changePage() {
    this.findCard(this.PAGE_SIZE * (this.currentPage - 1));
  }

  getColor(card: Card) {
    if (!card) {
      return;
    }
    return card.userUId ? { 'background-color': '#37b358' } : { 'background-color': '#eb6357' };
  }

  getFilter(skip: number = 0, limit: number = this.PAGE_SIZE) {
    if (skip < 0) {
      skip = 0;
      this.currentPage = 1;
    }
    return {
      where: {
        kind: 'game',
        userUId: this.activeFilter === 'active' ? { gt: 0 } : (this.activeFilter === 'new' ? null : undefined),
        key: this.nameFilter ? { like: `%${this.nameFilter.slice(this.nameFilter.length - 6, this.nameFilter.length)}%` } : undefined
      },
      skip,
      limit,
      include: ['userU'],
      order: 'id DESC'
    };
  }

  findCard(skip: number = 0, limit: number = this.PAGE_SIZE) {
    if (skip <= 0) {
      skip = 0;
      this.currentPage = 1;
    }
    this.isLoading = true;
    this.spinner.show().then().catch();
    const filter = this.getFilter(skip, limit);
    zip(
      this.cardApi.find(filter),
      this.cardApi.count(filter.where)
    ).subscribe(([cards, count]: [Card [], { count: number }]) => {
      this.isLoading = false;
      this.spinner.hide().then().catch();
      this.cards = cards;
      this.totalItems = count.count;
      this.notifierService.notify('success', 'Load cards successfully');
    }, error1 => {
      this.spinner.hide().then().catch();
      this.isLoading = false;
      this.notifierService.notify('error', error1);
    });
  }

  createOrEditCard(card: Card) {
    card.kind = 'game';
    card.activeCount = 0;
    card.days = this.dayOfCard;
    if (card.id) {
      this.spinner.show().then().catch();
      this.cardApi.patchAttributes(card.id, card)
        .subscribe(() => {
          this.spinner.hide().then().catch();
          $('#form-add').modal('hide');
          this.findCard(this.PAGE_SIZE * (this.currentPage - 1));
          this.notifierService.notify('success', 'Update card successfully');
        }, error1 => {
          this.spinner.hide().then().catch();
          this.notifierService.notify('error', error1);
        });
    } else {
      for (let i = 0; i < this.numberOfCard; i++) {
        this.spinner.show().then().catch();
        this.cardApi.create(card)
          .subscribe(() => {
            this.spinner.hide().then().catch();
            this.notifierService.notify('success', 'Create card successfully');
            $('#form-add').modal('hide');
          }, error1 => {
            this.spinner.hide().then().catch();
            this.notifierService.notify('error', error1);
          });
      }
      this.findCard(this.PAGE_SIZE * (this.currentPage - 1));
    }
  }

  deleteCard(card: Card) {
    this.spinner.show().then().catch();
    this.cardApi.deleteById(card.id)
      .subscribe(() => {
        this.spinner.hide().then().catch();
        this.notifierService.notify('success', 'Delete card successfully');
        this.findCard(this.PAGE_SIZE * (this.currentPage - 1));
      }, error1 => {
        this.spinner.hide().then().catch();
        this.notifierService.notify('error', error1);
      });
  }
}
