import { Component, OnInit } from '@angular/core';
import { City, County, School } from '../../../api/models';
import { NotifierService } from 'angular-notifier';
import { CityApi, CountyApi, SchoolApi } from '../../../api/services/custom';

declare const $;

@Component({
  selector: 'app-school',
  templateUrl: './school.component.html',
  styleUrls: ['./school.component.css']
})
export class SchoolComponent implements OnInit {
  schools: School[] = [];
  counties: County[] = [];
  cities: City[] = [];
  currentSchool = new School();
  isLoading = false;
  assign = Object.assign;
  formValidation: any;
  cityFilter = null;
  countyFilter = null;

  constructor(private notifierService: NotifierService,
              private cityApi: CityApi,
              private countyApi: CountyApi,
              private schoolApi: SchoolApi) {
  }

  ngOnInit() {
    this.findCity();
    this.findSchool();
    this.formValidation = $('#add-city').parsley();
  }

  findCity() {
    this.isLoading = true;
    this.cityApi.find()
      .subscribe((cities: City[]) => {
        this.cities = cities;
        this.isLoading = false;
      }, error1 => {
        this.isLoading = false;
        this.cities = [];

      });
  }

  getFilter() {
    if (this.countyFilter) {
      return {
        'include': [
          {
            'relation': 'city'
          },
          {
            'relation': 'county'
          }
        ],
        'where': {
          'countyId': this.countyFilter
        }
      };
    }
    if (this.cityFilter) {
      return {
        'include': [
          {
            'relation': 'city'
          },
          {
            'relation': 'county'
          }
        ],
        'where': {
          'cityId': this.cityFilter
        }
      };
    }
    return {
      'include': [
        {
          'relation': 'city'
        },
        {
          'relation': 'county'
        }
      ]
    };
  }

  resetFilter() {
    this.cityFilter = null;
    this.countyFilter = null;
    this.counties = [];
    this.findSchool();
  }

  findSchool() {
    this.schoolApi.find(this.getFilter())
      .subscribe((school: School[]) => {
        this.schools = school;
      }, error1 => {
        this.schools = [];
      });
  }

  findCounty() {
    const filter = {
      'where': {
        'mtpId': this.cityFilter
      }
    };
    if (this.cityFilter) {
      this.countyApi.find(filter)
        .subscribe((counties: County[]) => {
          this.counties = counties;
          this.findSchool();
        }, error1 => {
          this.counties = [];
        });
    }
  }

  createOrEditSchool(school: School) {
    if (school.id) {
      this.formValidation.whenValidate()
        .then(event => {
          school.cityId = this.cityFilter;
          school.countyId = this.countyFilter;
          this.schoolApi.patchAttributes(school.id, school)
            .subscribe(() => {
              $('#formAdd').modal('hide');
              this.resetFilter();
              this.notifierService.notify('success', 'Update city successfully');
            }, error1 => {
              this.notifierService.notify('error', error1);
            });
        });
    } else {
      this.formValidation.whenValidate()
        .then(event => {
          school.cityId = this.cityFilter;
          school.countyId = this.countyFilter;
          this.schoolApi.create(school)
            .subscribe(() => {
              $('#formAdd').modal('hide');
              this.notifierService.notify('success', 'Successfully');
              this.resetFilter();
            }, error1 => {
              this.notifierService.notify('error', error1);
            });
        });
    }
  }

  deleteSchool(school: School) {
    this.schoolApi.deleteById(school.id)
      .subscribe(() => {
        this.notifierService.notify('success', 'Delete city successfully');
        this.resetFilter();
      }, error1 => {
        this.notifierService.notify('error', error1);
      });
  }
}
