/* tslint:disable */
import {
  Account,
  City,
  County,
  Competition,
  School
} from '../index';

declare var Object: any;
export interface ExamInterface {
  "title"?: string;
  "thumb"?: string;
  "descriptions"?: string;
  "views"?: number;
  "like"?: number;
  "reports"?: number;
  "url"?: string;
  "projectUrl"?: string;
  "active"?: boolean;
  "presentation"?: string;
  "id"?: number;
  "userId"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  "cityId"?: number;
  "countyId"?: number;
  "competitionId"?: number;
  "schoolId"?: number;
  account?: Account;
  city?: City;
  county?: County;
  competition?: Competition;
  school?: School;
}

export class Exam implements ExamInterface {
  "title": string;
  "thumb": string;
  "descriptions": string;
  "views": number;
  "like": number;
  "reports": number;
  "url": string;
  "projectUrl": string;
  "active": boolean;
  "presentation": string;
  "id": number;
  "userId": number;
  "createdAt": Date;
  "updatedAt": Date;
  "cityId": number;
  "countyId": number;
  "competitionId": number;
  "schoolId": number;
  account: Account;
  city: City;
  county: County;
  competition: Competition;
  school: School;
  constructor(data?: ExamInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Exam`.
   */
  public static getModelName() {
    return "Exam";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Exam for dynamic purposes.
  **/
  public static factory(data: ExamInterface): Exam{
    return new Exam(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Exam',
      plural: 'Exams',
      path: 'Exams',
      idName: 'id',
      properties: {
        "title": {
          name: 'title',
          type: 'string'
        },
        "thumb": {
          name: 'thumb',
          type: 'string'
        },
        "descriptions": {
          name: 'descriptions',
          type: 'string'
        },
        "views": {
          name: 'views',
          type: 'number'
        },
        "like": {
          name: 'like',
          type: 'number'
        },
        "reports": {
          name: 'reports',
          type: 'number'
        },
        "url": {
          name: 'url',
          type: 'string'
        },
        "projectUrl": {
          name: 'projectUrl',
          type: 'string'
        },
        "active": {
          name: 'active',
          type: 'boolean'
        },
        "presentation": {
          name: 'presentation',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "userId": {
          name: 'userId',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "cityId": {
          name: 'cityId',
          type: 'number'
        },
        "countyId": {
          name: 'countyId',
          type: 'number'
        },
        "competitionId": {
          name: 'competitionId',
          type: 'number'
        },
        "schoolId": {
          name: 'schoolId',
          type: 'number'
        },
      },
      relations: {
        account: {
          name: 'account',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'userId',
          keyTo: 'id'
        },
        city: {
          name: 'city',
          type: 'City',
          model: 'City',
          relationType: 'belongsTo',
                  keyFrom: 'cityId',
          keyTo: 'id'
        },
        county: {
          name: 'county',
          type: 'County',
          model: 'County',
          relationType: 'belongsTo',
                  keyFrom: 'countyId',
          keyTo: 'id'
        },
        competition: {
          name: 'competition',
          type: 'Competition',
          model: 'Competition',
          relationType: 'belongsTo',
                  keyFrom: 'competitionId',
          keyTo: 'id'
        },
        school: {
          name: 'school',
          type: 'School',
          model: 'School',
          relationType: 'belongsTo',
                  keyFrom: 'schoolId',
          keyTo: 'id'
        },
      }
    }
  }
}
