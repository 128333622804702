import { Component, OnInit, ViewChild } from '@angular/core';
import { AccountApi, CourseApi, CourseCategoryApi } from '../../../api/services/custom';
import { NotifierService } from 'angular-notifier';
import { HttpClient } from '@angular/common/http';
import { Course, CourseCategory } from '../../../api/models';
import { LoopBackConfig } from '../../../api';

declare const $;

@Component({
    selector: 'app-courses',
    templateUrl: './courses.component.html',
    styleUrls: ['./courses.component.css']
})
export class CoursesComponent implements OnInit {

    isLoading = false;
    assign = Object.assign;
    formValidation: any;
    courses: Course[] = [];
    currentCourse = new Course();
    currentCourseCategory = new CourseCategory();
    uploadImgFile = '';
    uploadProjectFile = '';
    tag = '';
    courseCategories: CourseCategory[] = [];
    sortFilter = null;

    name = 'ng2-ckeditor';
    ckeConfig: any;
    mycontent: string;
    log = '';
    @ViewChild('myckeditor') ckeditor: any;

    constructor(private courseApi: CourseApi,
                private notifierService: NotifierService,
                private userApi: AccountApi,
                private httpClient: HttpClient,
                private courseCategoryApi: CourseCategoryApi) {
    }

    ngOnInit() {
        this.formValidation = $('#editCourse').parsley();
        this.findCourse();
        this.findCourseCategory('NINE');
        this.ckeConfig = {
            allowedContent: false,
            forcePasteAsPlainText: true,
            enterMode: 2,
            toolbarGroups: [
                { name: 'document', groups: ['mode'] },
                { name: 'clipboard', groups: ['clipboard', 'undo'] },
                { name: 'editing', groups: ['find', 'selection', 'spellchecker'] },
                '/',
                { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
                { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi'] },
                { name: 'links' },
                { name: 'insert' },
                '/',
                { name: 'styles' },
                { name: 'colors' }]
        };
    }

    onChange($event: any): void {
    console.log("onChange");
    // this.log += new Date() + "<br />";
  }

    findCourseCategory(kind: string) {
        let filter = null;
        if (kind) {
            filter = {
                where: {
                    kind: kind
                }
            };
        }
        this.courseCategoryApi.find(filter)
            .subscribe((courseCategories: CourseCategory[]) => {
                this.courseCategories = courseCategories;
            }, error1 => {
            });
    }

    getFilter() {
        let filter = null;
        if (!this.sortFilter) {
            filter = {
                'include': [
                    {
                        relation: 'category'
                    }
                ],
                'order': 'row ASC',
                'where': {
                    'accountId': null
                }
            };
        } else {
            switch (this.sortFilter) {
                case 'new':
                    filter = {
                        'include': [
                            {
                                relation: 'category'
                            }
                        ],
                        'order': 'createdAt DESC',
                        'where': {
                            'accountId': null
                        }
                    };
                    break;
                case 'old':
                    filter = {
                        'include': [
                            {
                                relation: 'category'
                            }
                        ],
                        'order': 'createdAt ASC',
                        'where': {
                            'accountId': null
                        }
                    };
                    break;
                case 'row':
                    filter = {
                        'include': [
                            {
                                relation: 'category'
                            }
                        ],
                        'order': 'row ASC',
                        'where': {
                            'accountId': null
                        }
                    };
                    break;
            }
        }
        return filter;
    }

    findCourse() {
        this.isLoading = true;
        this.courseApi.find(this.getFilter())
            .subscribe((courses: Course[]) => {
                this.courses = courses;
                this.isLoading = false;
            }, error1 => {
                this.courses = [];
                this.isLoading = false;
            });
    }

    onThumbChange(file) {
        if (file) {
            this.uploadFile(file, 'courses', 'img');
        }
    }

    onThumbHomeImgChange(file) {
        if (file) {
            this.uploadFile(file, 'courses', 'thumb');
        }
    }

    onProjectChange(file) {
        if (file) {
            this.uploadFile(file, 'documents', 'doc');
        }
    }

    uploadFile(file, container: string, type: string) {
        const formData = new FormData();
        formData.append('file', file);
        this.httpClient
            .post(`${LoopBackConfig.getPath()}/${LoopBackConfig.getApiVersion()}/Containers/` + container + `/upload`, formData)
            .subscribe((result: any) => {
                switch (type) {
                    case 'img':
                        this.uploadImgFile = result.result.files.file[0].name;
                        this.currentCourse.thumbnail = this.uploadImgFile;
                        break;
                    case 'thumb':
                        const imgRes = result.result.files.file[0].name;
                        this.currentCourse.homeImg = imgRes;
                        break;
                    case 'doc':
                        this.uploadProjectFile = result.result.files.file[0].name;
                        this.currentCourse.document = this.uploadProjectFile;
                        break;
                    default:
                        break;
                }

            }, error => {
            });
    }

    createOrEditCourse(course: Course) {
        if (course.id) {
            course.categoryId = course.courseCategoryId;
            this.formValidation.whenValidate()
                .then(event => {
                    this.courseApi.patchAttributes(course.id, course)
                        .subscribe(() => {
                            $('#formAdd').modal('hide');
                            this.findCourse();
                            this.notifierService.notify('success', 'Update course successfully');
                        }, error1 => {
                            this.notifierService.notify('error', error1);
                        });
                });
        } else {
            course.code = Math.random().toString(36).substr(2, 9);
            course.categoryId = course.courseCategoryId;
            this.formValidation.whenValidate()
                .then(event => {
                    this.courseApi.create(course)
                        .subscribe(() => {
                            $('#formAdd').modal('hide');
                            this.notifierService.notify('success', 'Create course successfully');
                            this.findCourse();
                        }, error1 => {
                            this.notifierService.notify('error', error1);
                        });
                });
        }
    }

    deleteCourse(course: Course) {
        this.courseApi.deleteById(course.id)
            .subscribe(() => {
                this.notifierService.notify('success', 'Delete course successfully');
                this.findCourse();
            }, error1 => {
                this.notifierService.notify('error', error1);
            });
    }
}
