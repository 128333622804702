import { Component, OnInit, Pipe, PipeTransform } from '@angular/core';
import { LoopBackConfig } from './api';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { environment } from '../environments/environment';
import { TranslateService } from '@ngx-translate/core';

declare const $: any;
declare const Dropzone: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {
  constructor(public titleService: Title,
              private translateService: TranslateService) {
    LoopBackConfig.setBaseURL(environment.apiUrl);
    LoopBackConfig.setApiVersion(environment.apiVersion);
    //
    // this.translateService.setDefaultLang('fr');
    // this.translateService.use('fr');
  }

  ngOnInit(): void {
  }

}
