import { Component, OnInit } from '@angular/core';
import { Account, City, Competition, County, Exam, School } from '../../../api/models';
import { AccountApi, CityApi, CompetitionApi, CountyApi, CourseApi, ExamApi, SchoolApi } from '../../../api/services/custom';
import { NotifierService } from 'angular-notifier';
import { LoopBackConfig } from '../../../api';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { downloadLink } from '../../../core/constants';

declare const $;

@Component({
  selector: 'app-exam',
  templateUrl: './exam.component.html',
  styleUrls: ['./exam.component.css']
})

export class ExamComponent implements OnInit {
  currentExam = new Exam();
  users: Account[] = [];
  exams: Exam[] = [];
  isLoading = false;
  assign = Object.assign;
  formValidation: any;
  competitions: Competition[] = [];
  cities: City[] = [];
  counties: County[] = [];
  schools: School[] = [];

  uploadImgFile = '';
  uploadProjectFile = '';
  uploadPresentation = '';

  cityFilter = null;
  countyFilter = null;
  schoolFilter = null;
  competitionFilter = null;
  serverUrl: any;

  constructor(private examApi: ExamApi,
              private courseApi: CourseApi,
              private notifierService: NotifierService,
              private userApi: AccountApi,
              private competitionApi: CompetitionApi,
              private httpClient: HttpClient,
              private cityApi: CityApi,
              private countyApi: CountyApi,
              private schoolApi: SchoolApi,
              public sanitizer: DomSanitizer) {
  }

  ngOnInit() {
    this.findExam();
    this.findUser();
    this.findCompetition();
    this.fintCity();
    this.formValidation = $('#add_user').parsley();
  }

  getUrl(url: any) {
    const currentUrl = downloadLink + url;
    return this.sanitizer.bypassSecurityTrustResourceUrl(currentUrl);
  }

  getFilter() {
    if (this.competitionFilter) {
      this.cityFilter = null;
      this.countyFilter = null;
      this.schoolFilter = null;
      return {
        'include': [
          {
            'relation': 'account'
          },
          {
            'relation': 'competition'
          }
        ],
        'where': {
          'competitionId': this.competitionFilter
        },
        'order': 'createdAt DESC',
      };
    }
    if (this.schoolFilter) {
      return {
        'include': [
          {
            'relation': 'account'
          },
          {
            'relation': 'competition'
          }
        ],
        'where': {
          'schoolId': this.schoolFilter
        },
        'order': 'createdAt DESC',
      };
    }
    if (this.countyFilter) {
      return {
        'include': [
          {
            'relation': 'account'
          },
          {
            'relation': 'competition'
          }
        ],
        'where': {
          'countyId': this.countyFilter
        },
        'order': 'createdAt DESC',
      };
    }
    if (this.cityFilter) {
      return {
        'include': [
          {
            'relation': 'account'
          },
          {
            'relation': 'competition'
          }
        ],
        'where': {
          'cityId': this.cityFilter
        },
        'order': 'createdAt DESC',
      };
    }
    return {
      'include': [
        {
          'relation': 'account'
        },
        {
          'relation': 'competition'
        },
      ],
      'order': 'createdAt DESC'
    };
  }

  resetFilter() {
    this.cityFilter = null;
    this.countyFilter = null;
    this.schoolFilter = null;
    this.competitionFilter = null;
    this.counties = [];
    this.schools = [];
    this.findExam();
  }

  findExam() {
    const filter = this.getFilter();
    this.isLoading = true;
    this.examApi.find(filter)
      .subscribe((exams: Exam []) => {
        this.exams = exams;
        this.notifierService.notify('success', 'Load exams successfully');
        this.isLoading = false;

      }, error1 => {
        this.isLoading = false;
        this.exams = [];
        this.notifierService.notify('error', error1);

      });
  }

  getSchoolFilter() {
    if (this.countyFilter) {
      return {
        'where': {
          'countyId': this.countyFilter
        }
      };
    }
    if (this.cityFilter) {
      return {
        'where': {
          'cityId': this.cityFilter
        }
      };
    }
  }

  findSchool() {
    this.schoolApi.find(this.getSchoolFilter())
      .subscribe((school: School[]) => {
        this.schools = school;
      }, error1 => {
      });
  }

  fintCity() {
    this.cityApi.find()
      .subscribe((cities: City[]) => {
        this.cities = cities;
        this.findCounty();
      }, error1 => {
      });
  }

  findCounty() {
    if (this.cityFilter) {
      this.competitionFilter = null;
      const filter = {
        'where': {
          'mtpId': this.cityFilter
        }
      };
      this.countyApi.find(filter)
        .subscribe((counties: County[]) => {
          this.counties = counties;
        }, error1 => {
        });
    }
  }

  findCompetition() {
    this.competitionApi.find()
      .subscribe((competitions: Competition[]) => {
        this.competitions = competitions;
      }, error1 => {
      });
  }

  findUser() {
    this.userApi.find()
      .subscribe((accout: Account[]) => {
        this.users = accout;
      }, error1 => {
      });
  }

  createOrEditExam(exam: Exam) {
    if (exam.id) {
      this.formValidation.whenValidate()
        .then(event => {
          this.examApi.patchAttributes(exam.id, exam)
            .subscribe(() => {
              $('#formAdd').modal('hide');
              this.findExam();
              this.notifierService.notify('success', 'Update exam successfully');
            }, error1 => {
              this.notifierService.notify('error', error1);
            });
        });
    } else {
      exam.views = 0;
      exam.like = 0;
      exam.active = true;
      if (exam.thumb === null || exam.thumb === undefined) {
        exam.thumb = 'default.jpg';
      }
      this.formValidation.whenValidate()
        .then(event => {
          this.examApi.create(exam)
            .subscribe(() => {
              this.notifierService.notify('success', 'Create exam successfully');
              this.findExam();
              $('#formAdd').modal('hide');

            }, error1 => {
              this.notifierService.notify('error', error1);
            });
        });
    }
  }

  deleteExam(exam: Exam) {
    this.examApi.deleteById(exam.id)
      .subscribe(() => {
        this.notifierService.notify('success', 'Delete exam successfully');
        this.findExam();
      }, error1 => {
        this.notifierService.notify('error', error1);
      });
  }

  onThumbChange(file) {
    if (file) {
      this.uploadFile(file, 'exams', 'img');
    }
  }

  onProjectChange(file) {
    if (file) {
      this.uploadFile(file, 'projects', 'pro');
    }
  }

  onPresentation(file) {
    if (file) {
      this.uploadFile(file, 'projects', 'pre');
    }
  }

  uploadFile(file, container: string, type: string) {
    const formData = new FormData();
    formData.append('file', file);
    this.httpClient
      .post(`${LoopBackConfig.getPath()}/${LoopBackConfig.getApiVersion()}/Containers/` + container + `/upload`, formData)
      .subscribe((result: any) => {
        switch (type) {
          case 'img':
            this.uploadImgFile = result.result.files.file[0].name;
            this.currentExam.thumb = this.uploadImgFile;
            break;
          case 'pro':
            this.uploadProjectFile = result.result.files.file[0].name;
            this.currentExam.projectUrl = this.uploadProjectFile;
            break;
          case 'pre':
            this.uploadPresentation = result.result.files.file[0].name;
            this.currentExam.presentation = this.uploadPresentation;
            break;
          default:
            break;
        }

      }, error => {
      });
  }

}
