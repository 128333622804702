import { Component, OnInit } from '@angular/core';
import {Post} from '../../../api/models';
import {PostApi} from '../../../api/services/custom';
import {DomSanitizer} from '@angular/platform-browser';
import {NotifierService} from 'angular-notifier';

@Component({
  selector: 'app-intros',
  templateUrl: './intros.component.html',
  styleUrls: ['./intros.component.css']
})
export class IntrosComponent implements OnInit {
  posts: Post[] = [];
  isLoading = false;

  constructor(private postApi: PostApi,
              public sanitizer: DomSanitizer,
              private notifierService: NotifierService) {
  }

  ngOnInit() {
    this.findPost();
  }

  trustHTML(value: any) {
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }

  findPost() {
    const filter = {
      order: 'createdAt DESC',
      where: {
        cataloguage: 'intros'
      },
      limit: 1
    };
    this.isLoading = true;
    this.postApi.find(filter)
      .subscribe((posts: Post[]) => {
        this.isLoading = false;
        this.posts = posts;
        this.notifierService.notify('success', 'Update posts successfully');
      }, error1 => {
        this.posts = [];
        this.isLoading = false;
        this.notifierService.notify('error', error1);

      });
  }
}

