/* tslint:disable */

declare var Object: any;
export interface EnglishInterface {
  "name"?: string;
  "word"?: string;
  "path"?: string;
  "level"?: string;
  "map"?: string;
  "topic"?: string;
  "status"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
}

export class English implements EnglishInterface {
  "name": string;
  "word": string;
  "path": string;
  "level": string;
  "map": string;
  "topic": string;
  "status": string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  constructor(data?: EnglishInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `English`.
   */
  public static getModelName() {
    return "English";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of English for dynamic purposes.
  **/
  public static factory(data: EnglishInterface): English{
    return new English(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'English',
      plural: 'Englishes',
      path: 'Englishes',
      idName: 'id',
      properties: {
        "name": {
          name: 'name',
          type: 'string'
        },
        "word": {
          name: 'word',
          type: 'string'
        },
        "path": {
          name: 'path',
          type: 'string'
        },
        "level": {
          name: 'level',
          type: 'string'
        },
        "map": {
          name: 'map',
          type: 'string'
        },
        "topic": {
          name: 'topic',
          type: 'string'
        },
        "status": {
          name: 'status',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
      }
    }
  }
}
